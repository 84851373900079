import { MapPinIcon, PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ContextualButton from '@components/buttons/contextual-button'
import EmptyStateButton from '@components/empty-state-button/empty-state-button'
import { type ExperienceChildrenProps } from '@components/experiences/experience-children/experience-children.interfaces'
import ImageEmptyState from '@components/image-empty-state/image-empty-state'
import Modal from '@components/modals/modal'
import { ExperienceChildType } from '@interfaces/api/course/course'
import { type ExperienceChild } from '@interfaces/api/experience/experience-child'
import useApiResource from '@services/api/definition'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const ExperienceChildren = ({ id }: ExperienceChildrenProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'experience-children' })

  const [childrens, setChildrens] = useState<ExperienceChild[]>([])
  const [experienceChild, setExperienceChild] = useState<ExperienceChild>()

  const [open, setOpen] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState(false)

  const collectionDefinition = useApiResource('experience-children')
  const definition = useApiResource('experience-children/{uid}')

  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()
  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple()

  const {
    data: {
      data: experienceChildren
    } = {},
    refetch
  } = useItemChildrenQuery<ExperienceChild>({
    itemId: id,
    path: 'experiences/{uid}/children'
  })

  useEffect(() => {
    if (experienceChildren) {
      setChildrens(experienceChildren)
    }
  }, [experienceChildren])

  const beforeSubmit = (data) => {
    data.parent = `/api/experiences/${id}`

    if (data.experience) {
      data.geoloc = null
    }

    return data
  }

  const onAddExperienceClick = () => {
    setOpen(true)
  }

  const onEdit = async () => {
    await refetch()
    setOpenEdit(false)
  }

  const reload = () => {
    refetch().catch(captureException)
  }

  const onCreate = async () => {
    await refetch()
    setOpen(false)
  }

  const onEditClick = (experience: ExperienceChild) => {
    setExperienceChild(experience)
    setOpenEdit(true)
  }

  const onDeleteClick = (experienceChild: ExperienceChild) => {
    setOpenDeleteModal(true)
    setExperienceChild(experienceChild)
  }

  const onDeleteHandler = () => {
    if (experienceChild) {
      deleteResourceEntry({ id: experienceChild.uid, path: 'experience-children/{uid}' }).then(() => {
        reload()
        setOpenDeleteModal(false)
      }).catch(captureException)
    }
  }

  const onCancelDeleteClick = () => {
    setOpenDeleteModal(false)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index

    if (sourceIndex === destinationIndex) {
      return
    }

    const newPriority = childrens[destinationIndex].priority
    const newExperienceChildren = Array.from(childrens)
    const [removed] = newExperienceChildren.splice(sourceIndex, 1)

    newExperienceChildren.splice(destinationIndex, 0, removed)
    setChildrens(newExperienceChildren)

    patchResourceEntry({
      data: {
        priority: newPriority
      },
      id: result.draggableId,
      path: 'experience-children/{uid}'
    }).then(() => {
      reload()
    }).catch(captureException)
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Sous-Expériences</h3>
            </div>

            <div className='ml-4 mt-4 flex-shrink-0'>
              <ContextualButton onClick={onAddExperienceClick}>
                {translateActions('addExperienceChild')}
              </ContextualButton>
            </div>
          </div>
        </div>

        {childrens.length > 0
          ? (
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable direction='vertical' droppableId='droppable'>
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    className='flow-root'
                    ref={provided.innerRef}
                  >
                    <div className='w-screen sm:w-auto'>
                      <div className={'min-w-min w-full table-wrp block'}>
                        <table className='w-full divide-y'>
                          <thead className='bg-gray-50 text-gray-900 sticky top-0'>
                            <tr>
                              <th className='p-4 text-left'>
                                <div className='flex items-center text-base font-medium'>
                                  Nom
                                </div>
                              </th>

                              <th />
                            </tr>
                          </thead>

                          <tbody className='bg-white min-h-[400px] mb-20'>

                            <>
                              {childrens.map((experienceChild, index) => (
                                <Draggable draggableId={experienceChild.uid} index={index} key={experienceChild.uid}>
                                  {(provided, snapshot) => (
                                    <tr
                                      className='flex items-center bg-white hover:bg-gray-100 cursor-pointer'
                                      onClick={(e) => {
                                        e.stopPropagation()
                                      }}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      key={experienceChild.uid}
                                    >
                                      <td className='p-4'>
                                        <span className='h-8 w-8 rounded-full flex items-center justify-center bg-primary text-white'>
                                          {index + 1}
                                        </span>
                                      </td>

                                      {experienceChild.experience
                                        ? (
                                          <>
                                            <td className='p-4'>
                                              {experienceChild.experience?.cover?.url
                                                ? (
                                                  <img alt='Artwork cover' className='w-10 rounded-md'
                                                    src={experienceChild.experience.cover.url}
                                                  />
                                                )
                                                : <ImageEmptyState classNames='w-10 h-12 rounded-md overflow-hidden bg-slate-400 flex items-center justify-center' iconClassNames='h-4 w-4 text-white' />}
                                            </td>

                                            <td className='p-4 flex-grow'>
                                              {experienceChild.experience.name}
                                            </td>
                                          </>
                                        )
                                        : (
                                          <>
                                            <td className='p-4'>
                                              <div className='px-3 py-4 rounded-md bg-slate-400 flex items-center justify-center'>
                                                <MapPinIcon className='h-4 w-4 text-white' />
                                              </div>
                                            </td>
                                            <td className='p-4 flex-grow'>
                                              Point géoloc
                                            </td>
                                          </>
                                        )}

                                      <td className='p-4 flex space-x-3 ml-auto'>
                                        {experienceChild.type === ExperienceChildType.geoloc && (
                                          <button
                                            className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              onEditClick(experienceChild)
                                            }}
                                          >
                                            <PencilSquareIcon className='w-5 h-5 mx-auto' />
                                          </button>
                                        )}

                                        <button
                                          className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            onDeleteClick(experienceChild)
                                          }}
                                        >
                                          <TrashIcon className='w-5 h-5 mx-auto' />
                                        </button>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          )
          : (
            <div className='py-4 pb-8 px-4 w-full'>
              <EmptyStateButton onClick={onAddExperienceClick} translateKey={'experienceChildren'} />
            </div>
          )}
      </div>

      <Modal center open={open} setOpen={setOpen} size='large' title={translateResource('create')}>
        {collectionDefinition && <ApiResourceCreate beforeSubmit={beforeSubmit} definition={collectionDefinition} fieldsToDisplay={['experience', 'type', 'geoloc']} fieldsToHide={['translatedData']} onEdit={onCreate} />}
      </Modal>

      {experienceChild && (
        <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
          {definition && <ApiResourceEdit definition={definition} fieldsToDisplay={['experience', 'type', 'geoloc']} fieldsToHide={['translatedData']} id={experienceChild.uid} onEdit={onEdit} />}
        </Modal>
      )}

      <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

            <ContextualButton onClick={onCancelDeleteClick}>{translateActions('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ExperienceChildren
