import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Field, Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import { type Breadcrumb } from '@components/breadcrumbs/breadcrumbs-interfaces'
import ButtonLoader from '@components/button-loader/button-loader'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { NotificationType } from '@components/notification/notification.interfaces'
import { type Experience } from '@interfaces/api/experience'
import { type PartnerPricingGrid } from '@interfaces/api/partner-pricing-grid/partner-pricing-grid'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import useApiResourceListQuery from '@services/api/resources/list-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useNotificationStore } from '@services/stores/notification/notification'

const UserPage = () => {
  const { id = '' } = useParams()
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'partner-tariff-grids' })
  const { t } = useTranslation('apiResources')
  const [initialValues, setInitialValues] = useState<{ items: Record<string, number> }>({ items: {} })

  const { displayNotification } = useNotificationStore()

  const {
    data: pricingGrid
  } = useApiResourceItemQuery<PartnerPricingGrid>({ id, path: 'partner-tariff-grids/{uid}', refetchOnMount: 'always' })

  const {
    data: {
      'hydra:member': audioguides = []
    } = {}
  } = useApiResourceListQuery<Experience>({
    definition: {
      name: 'experiences',
      url: ''
    },
    parameters: {
      only_audioguide: true,
      pagination: false
    }
  })

  const { mutateAsync: patchGrid } = usePatchEntry({ id, path: 'partner-tariff-grids/{uid}/items' })

  useEffect(() => {
    if (pricingGrid && !initialValues.items.length) {
      const defaultValues = audioguides.reduce((acc, audioguide) => {
        acc[audioguide.uid] = null

        return acc
      }, {})

      const updatedValues = {
        items: {
          ...defaultValues,
          ...pricingGrid.items.reduce((acc, item) => {
            acc[item.experience.uid] = item.price / 100

            return acc
          }, {})
        }
      }

      setInitialValues(updatedValues)
    }
  }, [pricingGrid, audioguides])

  const onSubmit = (values, { setSubmitting }) => {
    const updatedItems = Object.entries(values.items)
      .filter(([_, value]) => value !== null && value !== '')
      .reduce((acc, [key, value]) => {
        acc[key] = (value as number) * 100

        return acc
      }, {})

    patchGrid({
      items: updatedItems
    }).then(() => {
      setSubmitting(false)
      displayNotification('Grille tarifaire modifiée', 'Grille mise à jour avec succès', NotificationType.success)
    }).catch(captureException)
  }

  const breadcrumbs: Breadcrumb[] = [
    { href: '/partners-pricing', name: translateResource('title') },
    { href: `/partners-pricing/${pricingGrid?.uid}`, name: pricingGrid?.name ?? '' }
  ]

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} - ${pricingGrid?.name ?? ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8'>
          <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>

                <div className='ml-4 mt-4'>
                  <h3 className='text-xl font-semibold leading-6 text-slate-700'>{`${translateResource('title')} - ${pricingGrid?.name ?? ''}`}</h3>
                </div>
              </div>
            </div>
          </div>

          <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
            {({ isSubmitting }) => (
              <Form className='my-4 bg-white rounded-lg p-8'>
                {audioguides.map(audioguide => (
                  <div className='flex flex-row items-center mb-4' key={audioguide.uid}>
                    <label className='block font-medium text-sm w-60' htmlFor={audioguide.uid}>{audioguide.name}</label>

                    <Field
                      className='rounded-l-lg text-gray-700 text-center border border-gray-300 bg-gray-50 appearance-none px-3 py-1.5 placeholder-gray-500 focus:outline-none'
                      min={0}
                      name={`items.${audioguide.uid}`}
                      step={'.01'}
                      type='number'
                    />

                    <div
                      className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'
                    >
                      {'€'}
                    </div>
                  </div>
                ))}

                <div className='sticky bottom-0 right-0 pb-3 pr-4 flex items-end justify-end pt-4 bg-white'>
                  <button
                    className={`flex gap-2 items-center justify-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white ${isSubmitting ? 'cursor-not-allowed bg-slate-400' : 'bg-slate-800 hover:bg-slate-700'}`}
                    disabled={isSubmitting}
                    type='submit'
                  >
                    {t('buttons.update')}

                    {isSubmitting ? <ButtonLoader /> : <ArrowDownTrayIcon className='w-5 h-5' />}
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Guard>
    </Layout>
  )
}

export default UserPage
