/* eslint-disable @typescript-eslint/naming-convention */
import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import type React from 'react'
import { type FormEvent } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'

import { captureException } from '@services/exceptions/capture-exception'

interface Props {
  onPlaceSelect: (place: google.maps.places.PlaceResult | null) => void
}

// This is a custom built autocomplete component using the "Autocomplete Service" for predictions
// and the "Places Service" for place details
const PlaceAutocompleteClassic: React.FC<Props> = ({ onPlaceSelect }) => {
  const map = useMap()
  const places = useMapsLibrary('places')

  const [sessionToken, setSessionToken] =
    useState<google.maps.places.AutocompleteSessionToken | null>(null)

  const [autocompleteService, setAutocompleteService] =
    useState<google.maps.places.AutocompleteService | null>(null)

  const [placesService, setPlacesService] =
    useState<google.maps.places.PlacesService | null>(null)

  const [predictionResults, setPredictionResults] = useState<
  google.maps.places.AutocompletePrediction[]
  >([])

  const [inputValue, setInputValue] = useState<string>('')
  const [selectedIndex, setSelectedIndex] = useState<number>(-1)
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!places || !map) {
      return
    }

    setAutocompleteService(new places.AutocompleteService())
    setPlacesService(new places.PlacesService(map))
    setSessionToken(new places.AutocompleteSessionToken())

    return () => {
      setAutocompleteService(null)
    }
  }, [map, places])

  const fetchPredictions = useCallback(
    (inputValue: string) => {
      if (!autocompleteService || !inputValue) {
        setPredictionResults([])

        return
      }

      const request: google.maps.places.AutocompletionRequest = {
        input: inputValue,
        sessionToken: sessionToken as google.maps.places.AutocompleteSessionToken
      }
      autocompleteService
        .getPlacePredictions(request, (predictions, status) => {
          if (status === google.maps.places.PlacesServiceStatus.OK) {
            setPredictionResults(predictions ?? [])
          } else {
            setPredictionResults([])
          }
        })
        .catch(captureException)
    },
    [autocompleteService, sessionToken]
  )

  const onInputChange = useCallback(
    (event: FormEvent<HTMLInputElement>) => {
      const value = (event.target as HTMLInputElement)?.value

      setInputValue(value)
      fetchPredictions(value)
      setSelectedIndex(-1)
    },
    [fetchPredictions]
  )

  const handleSuggestionClick = useCallback(
    (placeId: string) => {
      if (!places) {
        return
      }

      const detailRequestOptions: google.maps.places.PlaceDetailsRequest = {
        fields: ['geometry', 'name', 'formatted_address'],
        placeId,
        sessionToken: sessionToken as google.maps.places.AutocompleteSessionToken
      }

      const detailsRequestCallback = (
        placeDetails: google.maps.places.PlaceResult | null,
        status: google.maps.places.PlacesServiceStatus
      ) => {
        if (status === google.maps.places.PlacesServiceStatus.OK && placeDetails) {
          onPlaceSelect(placeDetails)
          setPredictionResults([])
          setInputValue(placeDetails.formatted_address ?? '')
          setSessionToken(new places.AutocompleteSessionToken())
          if (placeDetails.geometry?.viewport) {
            map?.fitBounds(placeDetails.geometry.viewport)
          }
        }
      }

      placesService?.getDetails(detailRequestOptions, detailsRequestCallback)
    },
    [onPlaceSelect, places, placesService, sessionToken, map]
  )

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      setSelectedIndex((prevIndex) =>
        prevIndex < predictionResults.length - 1 ? prevIndex + 1 : prevIndex
      )
    } else if (event.key === 'ArrowUp') {
      event.preventDefault()
      setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : prevIndex))
    } else if (event.key === 'Enter') {
      event.preventDefault()
      if (selectedIndex >= 0 && selectedIndex < predictionResults.length) {
        handleSuggestionClick(predictionResults[selectedIndex].place_id)
      } else if (predictionResults.length === 1) {
        handleSuggestionClick(predictionResults[0].place_id)
      }
    }
  }

  const handleListItemKeyDown = (event: React.KeyboardEvent<HTMLLIElement>, placeId: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleSuggestionClick(placeId)
    }
  }

  return (
    <div className='w-72'>
      <input
        className='appearance-none block w-60 mt-2 px-3 rounded-md bg-white py-1.5 pl-3 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm sm:leading-6'
        onInput={(event: FormEvent<HTMLInputElement>) => {
          onInputChange(event)
        }}
        onKeyDown={handleKeyDown}
        placeholder='Rechercher'
        ref={inputRef}
        value={inputValue}
      />

      {predictionResults.length > 0 && (
        <ul className='rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 mt-1'>
          {predictionResults.map(({ description, place_id }, index) => {
            return (
              <li
                className={`p-2 cursor-pointer hover:bg-gray-300 text-xs ${
                  index === selectedIndex ? 'bg-gray-300' : ''
                }`}
                key={place_id}
                onClick={() => {
                  handleSuggestionClick(place_id)
                }}
                onKeyDown={(event) => {
                  handleListItemKeyDown(event, place_id)
                }}
                role='button'
                tabIndex={0}
              >
                {description}
              </li>
            )
          })}
        </ul>
      )}
    </div>
  )
}

export default PlaceAutocompleteClassic
