import { ArrowPathIcon, PencilSquareIcon, StarIcon, TrashIcon, XMarkIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ButtonLoader from '@components/button-loader/button-loader'
import ContextualButton from '@components/buttons/contextual-button'
import EmptyStateButton from '@components/empty-state-button/empty-state-button'
import { type ExperienceProductsProps } from '@components/experiences/experience-products/experience-products.interfaces'
import GlobalSearch from '@components/global-search/global-search'
import Modal from '@components/modals/modal'
import SkeletonLoaderTable from '@components/skeleton-loader/skeleton-loader-table'
import { type ApiPlatformEntity } from '@interfaces/api'
import { type ActivityProduct } from '@interfaces/api/activity/activity-product'
import { type Product } from '@interfaces/api/product/product'
import useApiResource from '@services/api/definition'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const ExperienceProducts = ({ breadcrumbs, id }: ExperienceProductsProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'products' })
  const navigate = useNavigate()

  const [open, setOpen] = useState(false)
  const [openLoadingModal, setOpenLoadingModal] = useState(false)
  const [loadingError, setLoadingError] = useState('')
  const [syncItem, setSyncItem] = useState('')
  const [products, setProducts] = useState<ActivityProduct[]>([])

  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [product, setProduct] = useState<Product>()
  const [activityProduct, setActivityProduct] = useState<ActivityProduct>()
  const definition = useApiResource('products/{uid}')

  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'activity-products' })
  const { mutateAsync: createProduct } = useCreateEntry({ path: 'products' })
  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()
  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple()

  const {
    data: {
      data: activityProducts
    } = {},
    isFetching,
    refetch
  } = useItemChildrenQuery<ActivityProduct>({
    itemId: id,
    path: 'activities/{uid}/products'
  })

  useEffect(() => {
    if (activityProducts) {
      setProducts(activityProducts)
    }
  }, [activityProducts])

  const onAddProductClick = () => {
    setOpen(true)
  }

  const reload = () => {
    refetch().catch(captureException)
  }

  const onProductSelected = (product: Product) => {
    createResourceEntry({
      activity: `/api/activities/${id}`,
      main: products.length <= 0,
      product: `/api/products/${product.uid}`
    }).then(() => {
      reload()
      setOpen(false)
    }).catch(captureException)
  }

  const onDeleteClick = (product: ActivityProduct) => {
    setOpenDeleteModal(true)
    setActivityProduct(product)
  }

  const onDeleteHandler = () => {
    if (activityProduct) {
      deleteResourceEntry({ id: activityProduct.uid, path: 'activity-products/{uid}' }).then(() => {
        reload()
        setOpenDeleteModal(false)
      }).catch(captureException)
    }
  }

  const onCancelDeleteClick = () => {
    setOpenDeleteModal(false)
  }

  const onEdit = async () => {
    await refetch()
    setOpenEdit(false)
  }

  const onEditClick = (product: Product) => {
    setProduct(product)
    setOpenEdit(true)
  }

  const onEntryClick = (entry: ApiPlatformEntity) => {
    navigate(`/products/${entry.uid}`, { state: { breadcrumbs } })
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index
    const newPriority = products[result.destination.index].priority

    if (sourceIndex === destinationIndex) {
      return
    }

    const newProducts = Array.from(products)
    const [removed] = newProducts.splice(sourceIndex, 1)

    newProducts.splice(destinationIndex, 0, removed)
    setProducts(newProducts)

    patchResourceEntry({
      data: {
        priority: newPriority
      },
      id: result.draggableId,
      path: 'activity-products/{uid}'
    }).then(() => {
      reload()
    }).catch(captureException)
  }

  const syncProduct = (item) => {
    setOpenLoadingModal(true)
    setLoadingError('')
    setSyncItem(item)
    createProduct({
      rezdyProductCode: item
    }).then((data) => {
      onProductSelected(data as Product)
      setOpenLoadingModal(false)
    }).catch((err) => {
      captureException(err)
      setLoadingError(err.data.detail)
    })
  }

  const onMainClick = (activityProduct) => {
    if (activityProduct.main) {
      return
    }
    patchResourceEntry({
      data: {
        main: true
      },
      id: activityProduct.uid,
      path: 'activity-products/{uid}'
    }).then(() => {
      reload()
    }).catch(captureException)
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10 mb-40'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Produits</h3>
            </div>

            <div className='ml-4 mt-4 flex-shrink-0'>
              <ContextualButton onClick={onAddProductClick}>
                {translateActions('addProduct')}
              </ContextualButton>
            </div>
          </div>
        </div>

        {products && products.length > 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable direction='vertical' droppableId='droppable'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  className='flow-root'
                  ref={provided.innerRef}
                >
                  <div className='w-screen sm:w-auto'>
                    <div className={'min-w-min w-full table-wrp block'}>
                      <table className='w-full divide-y'>
                        <thead className='bg-gray-50 text-gray-900 sticky top-0'>
                          <tr>
                            <th className='p-4 text-left'>
                              <div className='flex items-center text-base font-medium'>
                                Nom
                              </div>
                            </th>

                            <th className='p-4 text-left'>
                              <div className='flex items-center text-base font-medium'>
                                Ref rezdy
                              </div>
                            </th>

                            <th />
                          </tr>
                        </thead>

                        <tbody className='bg-white min-h-[400px]'>
                          {isFetching
                            ? (
                              <SkeletonLoaderTable headers={[]} />
                            )
                            : (
                              <>
                                {products.map((activityProduct, index) => (
                                  <Draggable draggableId={activityProduct.uid} index={index} key={activityProduct.uid}>
                                    {(provided, snapshot) => (
                                      <tr
                                        className='bg-white hover:bg-gray-100 cursor-pointer'
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          onEntryClick(activityProduct.product)
                                        }}
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        key={activityProduct.uid}
                                      >
                                        <td className='p-4'>
                                          {activityProduct.product.name}
                                        </td>

                                        <td className='p-4'>
                                          {activityProduct.product.rezdyProductCode}
                                        </td>

                                        <td className='p-4 flex space-x-3'>
                                          <button
                                            className={classNames({
                                              'bg-gray-100 text-gray-700 fill-gray-700 group-hover:hover:fill-white group-hover:hover:bg-gray-800 group-hover:hover:text-white group-hover:bg-white hover:bg-white ': !activityProduct.main,
                                              'bg-primary text-white fill-primary group-hover:hover:fill-gray-700 group-hover:hover:bg-gray-800 group-hover:hover:text-white group-hover:bg-primary': activityProduct.main,
                                              'flex items-center gap-2 py-1 px-2 text-sm rounded-md cursor-pointer hover:border-gray-900 border border-gray-100 group-hover:border-gray-900': true
                                            })}
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              onMainClick(activityProduct)
                                            }}
                                          >
                                            <StarIcon className='w-5 h-5 mx-auto' />
                                          </button>

                                          <button
                                            className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
                                            onClick={(e) => {
                                              e.stopPropagation()
                                              onEditClick(activityProduct.product)
                                            }}
                                          >
                                            <PencilSquareIcon className='w-5 h-5 mx-auto' />
                                          </button>

                                          <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={(e) => {
                                            e.stopPropagation()
                                            onDeleteClick(activityProduct)
                                          }}
                                          >
                                            <TrashIcon className='w-5 h-5 mx-auto' />
                                          </button>
                                        </td>
                                      </tr>
                                    )}
                                  </Draggable>
                                ))}
                                {provided.placeholder}
                              </>
                            )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )}

        {products && products.length <= 0 && (
          <div className='py-4 pb-8 px-4 w-full'>
            <EmptyStateButton onClick={onAddProductClick} translateKey={'activityProducts'} />
          </div>
        )}
      </div>

      <Modal center open={openLoadingModal} setOpen={setOpenLoadingModal} size='normal'>
        <div className='p-4'>

          {!loadingError && (
            <div>
              <div className='mx-auto flex justify-center items-center'>
                <div className='h-10 w-10'>
                  <ButtonLoader dark />
                </div>
              </div>

              <div className='mt-3 text-center sm:mt-5'>
                <h3 className='text-base font-semibold leading-6 text-gray-900'>
                  Synchronisation depuis Rezdy
                </h3>
              </div>
            </div>
          )}

          {loadingError && (
            <div className='flex flex-col items-center'>
              <div>
                <div className='mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100'>
                  <XMarkIcon aria-hidden='true' className='h-6 w-6 text-red-600' />
                </div>

                <div className='mt-3 text-center sm:mt-5'>
                  <h3 className='text-base font-semibold leading-6 text-gray-900'>
                    Erreur lors de la synchronisation
                  </h3>

                  <div className='mt-2'>
                    <p className='text-sm text-gray-500'>
                      {`Code d'erreur :  ${loadingError}`}
                    </p>
                  </div>
                </div>
              </div>

              <div className='mt-5 sm:mt-6'>
                <ContextualButton
                  icon={ArrowPathIcon}
                  onClick={() => {
                    syncProduct(syncItem)
                  }}
                >
                  Relancer la synchronisation
                </ContextualButton>
              </div>
            </div>
          )}
        </div>
      </Modal>

      {product && (
        <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
          {definition && <ApiResourceEdit definition={definition} fieldsToHide={['priority', 'provideCodes']} id={product.uid} onEdit={onEdit} />}
        </Modal>
      )}

      <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

            <ContextualButton onClick={onCancelDeleteClick}>{translateActions('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>

      <GlobalSearch emptyStateButtonLabel={'Synchroniser depuis rezdy'} emptyStateOnClick={syncProduct} indexes={['product']} isOpened={open} onSelectCallback={onProductSelected} setOpened={setOpen} />
    </>
  )
}

export default ExperienceProducts
