import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Fragment } from 'react'
import { useTranslation } from 'react-i18next'

import { type DropdownButtonProps } from '@components/buttons/dropdown-button/dropdown-button.interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const DropdownButton = ({ onCreateClick, options, ressource }: DropdownButtonProps) => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: ressource })

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='w-full justify-center gap-x-1.5 flex gap-2 items-center rounded-lg px-3 py-2 text-xs font-semibold tracking-wide bg-slate-800 text-white fill-white hover:bg-gray-600 hover:text-white'>
          {translateResource('create')}

          <ChevronDownIcon aria-hidden='true' className='-mr-1 size-4 text-white' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>
            {options.map((type, index) => (
              <Menu.Item key={index}>
                <button
                  className='w-full text-left text-gray-700 block px-4 py-2 text-sm hover:bg-gray-100 hover:text-gray-900 hover:cursor-pointer'
                  onClick={() => {
                    onCreateClick(type).catch(captureException)
                  }}
                >
                  {translateResource(`types.${type}`)}
                </button>
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default DropdownButton
