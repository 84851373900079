import React from 'react'

import ApiResource from '@components/api-resource'
import { type UsersOrdersProps } from '@components/users/users-orders/users-orders.interfaces'

const UsersOrders = ({ breadcrumbs, id }: UsersOrdersProps) => {
  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10 mb-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Orders</h3>
            </div>
          </div>
        </div>

        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['number', 'customerEmail', 'user', 'state', 'checkoutState', 'paymentState', 'shippingState', 'totalPrice', 'createdAt']}
          customFilters={['state', 'shippingState']}
          customParams={[{ user: id }]}
          hasMargin={false}
          name={'orders'}
        />
      </div>
    </>
  )
}

export default UsersOrders
