import { useQueryClient } from '@tanstack/react-query'
import { pluralize } from 'inflection'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import DropdownButton from '@components/buttons/dropdown-button/dropdown-button'
import CityHead from '@components/cities/city-head/city-head'
import CityPages from '@components/cities/city-pages/city-pages'
import ExperiencesTable from '@components/experiences-table'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import { type ApiPlatformEntity } from '@interfaces/api'
import { type CityEntry } from '@interfaces/api/city/city-entry'
import { type Experience, ExperienceType } from '@interfaces/api/experience'
import useApiResource from '@services/api/definition'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import { captureException } from '@services/exceptions/capture-exception'

const CityPage = () => {
  const { id = '', type = ExperienceType.activity } = useParams()
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [experience, setExperience] = useState<Experience>()
  const [experienceType, setExperienceType] = useState('attraction')
  const navigate = useNavigate()

  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'cities' })
  const { t: translateSubResource } = useTranslation('apiResources', { keyPrefix: `experiences.${experienceType}` })

  const { data } = useApiResourceItemQuery<CityEntry>({ id, path: 'cities/{uid}', refetchOnMount: 'always' })
  const queryClient = useQueryClient()
  const definition = useApiResource(`${pluralize(experienceType)}/{uid}`)
  const collectionDefinition = useApiResource(pluralize(experienceType))

  const breadcrumbs = [
    { href: '/cities', name: translateResource('title') },
    { href: `/cities/${data?.uid}`, name: data?.name }
  ]

  useEffect(() => {
    if (data) {
      setExperience({
        '@id': '',
        '@type': '',
        city: data['@id'],
        name: '',
        type: ExperienceType.artwork,
        uid: ''
      })
    }
  }, [data])

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['experiences'] }).catch(captureException)
  }

  const onEntryClick = (entry: ApiPlatformEntity, type: ExperienceType) => {
    const experience = entry as Experience
    navigate(`/experiences/${type}/${experience.uid}`, { state: { label: data?.name, link: `/cities/${data?.uid}` } })
  }

  const onEdit = (uid: string) => {
    onReload()
    setOpenEdit(false)
    setOpen(false)
    navigate(`/experiences/${experienceType}/${uid}`, { state: { label: data?.name, link: `/cities/${data?.uid}` } })
  }

  const beforeEdit = (data) => {
    if (data.attractionType === '') {
      data.attractionType = null
    }

    return data
  }

  const onCreateClick = async (type: string) => {
    setExperienceType(type)
    setOpen(true)
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} - ${data?.name}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>

          {data && (
            <>
              <CityHead city={data} />

              <div className='border-b border-gray-200 bg-white rounded-lg shadow my-10'>
                <CityPages breadcrumbs={breadcrumbs} city={data} id={id} />
              </div>

              <div className='border-b border-gray-200 bg-white rounded-lg shadow'>
                <div className='px-4 py-4'>
                  <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
                    <div className='ml-4 mt-4'>
                      <h3 className='text-xl font-semibold leading-6 text-slate-700'>Expériences</h3>
                    </div>

                    <div className='ml-4 mt-4 flex-shrink-0'>
                      <DropdownButton onCreateClick={onCreateClick} options={Object.values(ExperienceType)} ressource='experiences' />
                    </div>
                  </div>
                </div>

                <ExperiencesTable
                  customParams={[{ city: data.uid }]}
                  hasMargin={false}
                  hideFilters
                  onClick={onEntryClick}
                  path={`/cities/${data?.uid}`}
                  type={type ? type as ExperienceType : ExperienceType.artwork}
                />
              </div>

              <Modal center open={open} setOpen={setOpen} size='large' title={translateSubResource('create')}>
                {collectionDefinition && (
                  <ApiResourceCreate
                    beforeSubmit={beforeEdit}
                    data={experience}
                    definition={collectionDefinition}
                    onEdit={onEdit}
                  />
                )}
              </Modal>

              <>
                {experience && (
                  <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateSubResource('edit')}>
                    {definition && <ApiResourceEdit definition={definition} id={experience.uid} onEdit={onEdit} />}
                  </Modal>
                )}
              </>
            </>
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default CityPage
