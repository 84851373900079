import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { ErrorMessage, Field, useField } from 'formik'
import React, { Fragment, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { type FormSelectFieldProps } from '@components/form-fields/form-select-field/form-select-field.interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const FormSelectField = React.memo(({ defaultValue, groupedField = false, label, name, options, placeholder = '', required = false }: FormSelectFieldProps) => {
  const { t } = useTranslation()
  const [field, , { setValue }] = useField({ name })

  const displayOption = useCallback((key) => {
    const optionObj = options.find(item => item.key === key)

    return optionObj ?? options[0]
  }, [options])

  useEffect(() => {
    // if default value exists dans no value in input, we set default
    if (defaultValue && !field.value && options.length > 0) {
      const optionObj = options.find(item => item.key === defaultValue)
      if (optionObj) {
        setValue(optionObj.key).catch(captureException)
      }
    }
    // If no default value and no value in input, we set the first option
    if (!defaultValue && !field.value && options.length > 0) {
      setValue(options[0].key).catch(captureException)
    }
  }, [defaultValue, field.value, setValue])

  return (
    <div className={classNames('flex flex-col flex-1', { 'gap-2': label })}>
      <Listbox onChange={setValue} value={field.value}>
        {({ open }) => (
          <>
            {label && (
              <Listbox.Label className='block text-sm font-medium text-gray-900'>{t(label)}</Listbox.Label>
            )}
            <div className='relative'>
              <Listbox.Button className={classNames(
                'relative w-full flex items-center cursor-default border border-gray-300 py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm focus:outline-none sm:text-sm sm:leading-6',
                {
                  'bg-gray-50 rounded-md': !groupedField,
                  'bg-gray-100 border-l-0 rounded-r-md ': groupedField
                }
              )}
              >
                <span className='block truncate'>{displayOption(field.value).name}</span>

                {displayOption(field.value).image && (
                  <img alt='Option' className='w-6 h-6 ml-4 object-contain p-1 bg-slate-100 rounded-md' src={displayOption(field.value).image} />
                )}

                <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                  <ChevronUpDownIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
                </span>
              </Listbox.Button>

              <Transition
                as={Fragment}
                leave='transition ease-in duration-100'
                leaveFrom='opacity-100'
                leaveTo='opacity-0'
                show={open}
              >
                <Listbox.Options className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
                  {options.map((option) => (
                    <Listbox.Option
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-slate-900 text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      key={option.key}
                      value={option.key}
                    >
                      {({ active, selected }) => (
                        <span className='flex items-center justify-between'>
                          <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                            {option.name}
                          </span>

                          {option.image && (
                            <img alt='Option' className='w-6 h-6 object-contain p-1 bg-slate-100 rounded-md' src={option.image} />
                          )}

                          {!option.image && selected
                            ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon aria-hidden='true' className='h-5 w-5' />
                              </span>
                            )
                            : null}
                        </span>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>

      <Field
        as='select'
        className='hidden'
        name={name}
        required={required}
      />

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
})

export default FormSelectField
