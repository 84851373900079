import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ContextualButton from '@components/buttons/contextual-button'
import EmptyState from '@components/empty-state/empty-state'
import Modal from '@components/modals/modal'
import SectionEntry from '@components/pages/section-entry/section-entry'
import { type SectionListProps } from '@components/pages/section-list/section-list.interfaces'
import { type Section, type SectionInput, SectionType } from '@interfaces/api/page/section'
import useApiResource from '@services/api/definition'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'
import { getIdList } from '@services/tools/api-resources/get-id-list'

const SectionList = ({ pageId }: SectionListProps) => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'sections' })
  const definition = useApiResource('sections')
  // const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'sections' })
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [section, setSection] = useState<SectionInput>()
  const collectionDefinition = useApiResource('sections/{uid}')
  const [localModules, setLocalModules] = useState<Section[]>([])
  const { mutateAsync: reorderEntry } = usePatchEntrySimple()

  const defaultCustomData = {
    artist: false,
    category: false,
    description: false,
    duration: false,
    location: false,
    price: false,
    title: false
  }

  const {
    data: {
      data: modules
    } = {},
    isFetching
  } = useItemChildrenQuery<Section>({
    itemId: pageId,
    path: 'pages/{uid}/sections'
  })

  useEffect(() => {
    if (modules) {
      setLocalModules(modules)
    }
  }, [modules])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const moduleIds = localModules ? getIdList(localModules) : []

  const openAddModal = () => {
    if (localModules) {
      setSection({
        '@id': '',
        '@type': '',
        customData: defaultCustomData,
        page: `/api/pages/${pageId}`,
        priority: localModules.length + 1,
        title: '',
        type: SectionType.bestseller,
        uid: ''
      })
      setOpen(true)
    }
  }
  const onModuleAdd = async (data) => {
    setOpen(false)
    setLocalModules([...localModules, data])
  }

  const onModuleReorder = (section: Section, direction: number) => {
    const index = localModules.findIndex(module => module.uid === section.uid)
    if (index !== -1) {
      const newModules = [...localModules]

      // Determine the target index based on direction
      const targetIndex = index + direction

      // Ensure the target index is within bounds
      if (targetIndex >= 0 && targetIndex < newModules.length) {
        // Get the priority of the target module
        const targetModule = newModules[targetIndex]
        const newPriority = targetModule.priority

        // Update the priority of the current module
        reorderEntry({
          data: {
            priority: newPriority
          },
          id: section.uid,
          path: 'sections/{uid}'
        }).then(() => {
          // Reorder the modules array
          const [reorderedModule] = newModules.splice(index, 1)
          newModules.splice(targetIndex, 0, reorderedModule)
          setLocalModules(newModules)
        }).catch(captureException)
      }
    }
  }

  const onModuleDelete = (uid: string) => {
    setLocalModules(localModules.filter(module => module.uid !== uid))
  }

  const onModuleEdit = (section) => {
    setSection(section)
    setOpenEdit(true)
  }

  const handleEdit = async (data) => {
    setOpenEdit(false)
    setLocalModules(prevModules =>
      prevModules.map(module =>
        module.uid === data.uid ? data : module
      )
    )
  }

  return (
    <div>
      {localModules && localModules.length > 0 && !isFetching
        ? (
          <div className='mb-2 flex flex-col gap-2'>
            {localModules.map((module, index) => (
              <SectionEntry
                idList={moduleIds}
                key={module.uid}
                onEdit={onModuleEdit}
                onSectionDelete={onModuleDelete}
                onSectionReorder={onModuleReorder}
                pageId={pageId}
                position={index}
                section={module}
                totalItems={localModules.length}
              />
            ))}
          </div>
        )
        : (
          <EmptyState>
            <div className='flex flex-col'>
              <div className='text-gray-900 text-sm'>{translateResource('noSection')}</div>

              <div className='text-gray-500 text-xs'>{translateResource('selectSection')}</div>
            </div>
          </EmptyState>
        )}

      <div className='flex justify-end'>
        <ContextualButton icon={PlusCircleIcon} onClick={openAddModal}>
          {translateResource('addSection')}
        </ContextualButton>
      </div>

      <Modal center open={open} setOpen={setOpen} size='large' title={translateResource('create')}>
        {definition && <ApiResourceCreate afterSubmit={onModuleAdd} customAttributes={['title', 'description', 'price', 'artist', 'duration', 'category', 'location', 'showVideoModal']} data={section} definition={definition} disableNotification fieldsToDisplay={['title', 'type', 'isLocked']} />}
      </Modal>

      {section && (
        <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
          {collectionDefinition && <ApiResourceEdit afterSubmit={handleEdit} customAttributes={['title', 'description', 'price', 'artist', 'duration', 'category', 'location', 'showVideoModal']} definition={collectionDefinition} fieldsToDisplay={['title', 'type', 'isLocked']} id={section.uid} />}
        </Modal>
      )}
    </div>
  )
}

export default SectionList
