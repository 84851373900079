import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type BooleanFieldsInterfaces
} from '@components/api-resource/api-resource-form/form-fields/boolean-fields/boolean-fields.interfaces'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import FormToggleField from '@components/form-fields/form-toggle-field'
import { isPropertyType } from '@services/tools/api-resources/property-types'

const BooleanFields = ({
  definition,
  fieldsToDisplay,
  fieldsToHide,
  requestFields
}: BooleanFieldsInterfaces) => {
  const { t } = useTranslation('apiResources')
  const fields: ReactElement[] = []

  requestFields.forEach((field, index) => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (fieldIsHidden(field, fieldsToDisplay, fieldsToHide)) {
      return
    }

    if ('type' in field.properties) {
      if (isPropertyType(field, 'boolean')) {
        fields.push(
          <div className='flex' key={field.name}>
            <FormToggleField label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} />
          </div>)
      }
    }
  })

  return (
    <>
      {fields}
    </>
  )
}

export default BooleanFields
