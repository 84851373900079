import { useEffect, useState } from 'react'

import { captureException } from '@services/exceptions/capture-exception'

type SetValue<T> = T | ((val: T) => T)

function useLocalStorage<T> (key: string, initialValue: T): [T, (value: SetValue<T>) => void] {
  // State to store our value
  const [storedKey, setStoredKey] = useState(key)
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)

      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      return initialValue
    }
  })

  useEffect(() => {
    if (storedKey !== key) {
      try {
        // Get from local storage by key
        const item = window.localStorage.getItem(key)
        setStoredValue(item ? JSON.parse(item) : initialValue)
        setStoredKey(key)
      } catch (error) {
        setStoredValue(initialValue)
      }
    }
  }, [key, storedKey, initialValue])

  // useEffect to update local storage when the state changes
  useEffect(() => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore =
        typeof storedValue === 'function'
          ? (storedValue as (val: T) => T)(storedValue)
          : storedValue
      // Save state
      window.localStorage.setItem(key, JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      captureException(error as Error)
    }
  }, [key, storedValue])

  return [storedValue, setStoredValue]
}

export default useLocalStorage
