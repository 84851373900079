import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import { type Breadcrumb } from '@components/breadcrumbs/breadcrumbs-interfaces'
import EntryVideos from '@components/entry-videos/entry-videos'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { type Artist } from '@interfaces/api/artist'
import useApiResource from '@services/api/definition'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import { captureException } from '@services/exceptions/capture-exception'

const ArtistPage = () => {
  const { id = '' } = useParams()
  const location = useLocation()

  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'artists' })

  const { data: artist } = useApiResourceItemQuery<Artist>({ id, path: 'artists/{uid}', refetchOnMount: 'always' })
  const definition = useApiResource('artists/{uid}')
  const { mutateAsync: createVideoEntry } = useCreateEntry({ path: 'artist-videos' })

  const breadcrumbs: Breadcrumb[] = [
    { href: location.state?.link ?? '/experiences', name: location.state?.label ?? translateResource('title') },
    { href: '', name: artist?.name ?? '' }
  ]

  const onVideoSelect = (video, language, reload, setOpen) => {
    createVideoEntry({
      artist: `/api/artists/${id}`,
      lang: language,
      video: `/api/videos/${video.uid}`
    }).then(() => {
      reload()
      setOpen(false)
    }).catch(captureException)
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} ${artist?.name ? `- ${artist.name}` : ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8'>
          <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>

                <div className='ml-4 mt-4'>
                  <h3 className='text-xl font-semibold leading-6 text-slate-700'>{translateResource('title')}</h3>
                </div>
              </div>
            </div>

            {artist && (
              <>
                {definition && <ApiResourceEdit definition={definition} excludedFields={['city']} id={id} />}
              </>
            )}
          </div>

          {artist && (
            <EntryVideos entityName={'artist'} id={id} onVideoSelect={onVideoSelect} />
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default ArtistPage
