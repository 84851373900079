import { type ApiPlatformEntity } from '@interfaces/api'
import { type Course } from '@interfaces/api/course/course'
import { type Artwork } from '@interfaces/api/experience'

export interface CourseNavigationStep extends ApiPlatformEntity {
  artwork?: Artwork
  course: Course
  icon?: NavigationStepDirections
  priority: number
  title: string
  type: CourseStepType
  uid: string
}

export enum CourseStepType {
  ARTWORK = 'artwork',
  ICON = 'icon',
  IMAGE = 'image'
}

export enum NavigationStepDirections {
  cross = 'cross',
  goBack = 'goBack',
  goStraight = 'goStraight',
  openDoor = 'openDoor',
  passThroughRoundShape = 'passThroughRoundShape',
  passThroughSquareShape = 'passThroughSquareShape',
  slightTurnLeft = 'slightTurnLeft',
  slightTurnRight = 'slightTurnRight',
  stairsDown = 'stairsDown',
  stairsUp = 'stairsUp',
  startingPoint = 'startingPoint',
  turnLeft = 'turnLeft',
  turnRight = 'turnRight',
  uTurn = 'uTurn'
}

export enum DistanceUnit {
  centimer = 'cm',
  kilometer = 'km',
  meter = 'm'
}
