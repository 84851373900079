import { useEffect } from 'react'

import {
  type BookingPriceOptionsFieldsProps
} from '@components/bookings/booking-price-options-fields/booking-price-options-fields.interfaces'
import FormNumberField from '@components/form-fields/form-number-field'

const BookingPriceOptionsFields = ({ product, setFieldValue }: BookingPriceOptionsFieldsProps) => {
  useEffect(() => {
    const priceOptionsInitialValues = {}
    product.priceOptions.forEach(option => {
      priceOptionsInitialValues[option.name] = 0
    })
    setFieldValue('selectedPriceOptions', priceOptionsInitialValues)
  }, [product])

  return (
    <div>
      <h5 className='text-md font-semibold leading-6 text-slate-700 mb-4'>Price options</h5>

      <div className='grid grid-cols-2 gap-10'>
        {product?.priceOptions.map((option, index) => (
          <FormNumberField
            key={option.uid}
            label={option.name}
            name={`selectedPriceOptions['${option.name}']`}
            zeroAllowed
          />
        ))
        }
      </div>
    </div>
  )
}

export default BookingPriceOptionsFields
