import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type NumberFieldsInterfaces
} from '@components/api-resource/api-resource-form/form-fields/number-fields/number-fields.interfaces'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import FormNumberField from '@components/form-fields/form-number-field'
import { isPropertyType } from '@services/tools/api-resources/property-types'

const NumberFields = ({
  definition,
  fieldsToDisplay,
  fieldsToHide,
  requestFields
}: NumberFieldsInterfaces) => {
  const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  // Créez un objet pour associer les noms de champs aux composants correspondants
  const fieldComponents = {}

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
      if (fieldIsHidden(field, fieldsToDisplay, fieldsToHide) || field.name === 'duration' || field.name === 'distance') {
        return
      }

      if (field.name === 'price') {
        const unitcomponent = (
          <div className='border border-gray-300 py-1.5 px-3 text-left text-gray-500 bg-gray-100 border-l-0 rounded-r-md'>{'€'}</div>
        )

        fieldComponents[field.name] = <FormNumberField key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} required={field.required ?? false} step='.01' unitComponent={unitcomponent} />
      } else if (isPropertyType(field, 'integer')) {
        fieldComponents[field.name] = <FormNumberField key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} required={field.required ?? false} />
      }
    }
  })

  // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
  if (fieldsToDisplay) {
    fieldsToDisplay.forEach((fieldName) => {
      if (fieldComponents[fieldName]) {
        fields.push(fieldComponents[fieldName])
      }
    })
  } else {
    Object.keys(fieldComponents).forEach((fieldName) => {
      fields.push(fieldComponents[fieldName])
    })
  }

  return (
    <>
      {fields}
    </>
  )
}

export default NumberFields
