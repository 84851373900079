import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import ButtonLoader from '@components/button-loader/button-loader'
import FormNumberField from '@components/form-fields/form-number-field'
import FormSearchField from '@components/form-fields/form-search-field'
import FormTextField from '@components/form-fields/form-text-field'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { NotificationType } from '@components/notification/notification.interfaces'
import useCreateEntry from '@services/api/resources/create-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useNotificationStore } from '@services/stores/notification/notification'

const CodePageCreate = () => {
  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'codes' })
  const { displayNotification } = useNotificationStore()
  const navigate = useNavigate()

  const { t: translateResource } = useTranslation('apiResources')
  const { t: translateCodes } = useTranslation('apiResources', { keyPrefix: 'codes' })
  const breadcrumbs = [
    { href: '/codes', name: translateCodes('title') },
    { href: '', name: 'Création' }
  ]

  const initialValues = {
    experience: {},
    label: '',
    quantity: 1
  }

  const onSubmit = (values) => {
    createResourceEntry({
      experience: values.experience,
      label: values.label,
      quantity: values.quantity
    }).then(() => {
      displayNotification(translateCodes('message.create.success.title'), translateCodes('message.create.success.description'), NotificationType.success)
      navigate('/codes')
    }).catch(captureException)
  }

  return (
    <Layout description={translateCodes('description')} title={`${translateCodes('title')} - Création`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>

          <div className='border-b border-gray-200 bg-white rounded-lg shadow my-10'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
                <div className='ml-4 mt-4'>
                  <h3 className='text-xl font-semibold leading-6 text-slate-700'>Création de codes</h3>
                </div>
              </div>
            </div>

            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ isSubmitting }) => {
                return (
                  <Form className='flex flex-col gap-4 py-3 px-4 w-full max-h-full'>
                    <FormNumberField label={translateResource('labels.quantity')} name='quantity' required />

                    <FormTextField label={translateResource('labels.label')} name='label' />

                    <div className='grid grid-cols-2 gap-x-10'>
                      <div className=' mb-10'>
                        <FormSearchField
                          emptyStateLabel={translateResource(['emptyState.product}', 'emptyState.default'])}
                          indexName={'attraction'}
                          key={'experience'}
                          label={translateResource('labels.experience')}
                          name={'experience'}
                          required
                        />
                      </div>
                    </div>

                    <div className='flex justify-end'>
                      <button
                        className={`flex gap-2 items-center justify-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white ${isSubmitting ? 'cursor-not-allowed bg-slate-400' : 'bg-slate-800 hover:bg-slate-700'}`}
                        disabled={isSubmitting}
                        type='submit'
                      >
                        {translateResource('buttons.create')}

                        {isSubmitting ? <ButtonLoader /> : <PlusCircleIcon className='w-5 h-5' />}
                      </button>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </Guard>
    </Layout>
  )
}

export default CodePageCreate
