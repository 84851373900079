import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { type ReactElement } from 'react'

import ApiResourceListEntryActions from '@components/api-resource/api-resource-list-entry-actions'
import Date from '@components/api-resource/resource-entry-fields/date'
import {
  type ExperienceTableRowProps
} from '@components/experiences-table/experience-table-row/experience-table-row.interfaces'
import ImageEmptyState from '@components/image-empty-state/image-empty-state'
import { type ApiPlatformEntity } from '@interfaces/api'
import type { Image } from '@interfaces/api/image'
import { isPropertyType } from '@services/tools/api-resources/property-types'

interface VideoMetadata {
  videoCount?: {
    en?: number
    es?: number
    fr?: number
  }
}

const CheckIconBlock = () => (
  <div className='inline-block w-6 h-6 bg-green-100 rounded p-1.5'>
    <CheckIcon className='w-3 h-3 text-green-700' />
  </div>
)

const CrossIconBlock = () => (
  <div className='inline-block w-6 h-6 bg-gray-100 rounded p-1.5'>
    <XMarkIcon className='w-3 h-3 text-gray-700' />
  </div>
)

const ExperienceTableRow = <T extends ApiPlatformEntity>({
  definition,
  entry,
  fieldsToDisplay,
  identifierAttribute = 'uid',
  onClick,
  onReload,
  withCloneOption = false, withCopyOption, withDeleteOption = false, withEditOption
}: ExperienceTableRowProps<T>) => {
  const fields: ReactElement[] = []
  const onClickHandler = () => {
    if (onClick) {
      onClick(entry)
    }
  }

  fieldsToDisplay.forEach((field, index) => {
    if (entry[field.name] !== undefined) {
      if ('type' in field.properties) {
        if (isPropertyType(field, 'string')) {
          if (field.properties.format === 'date-time' && entry[field.name]) {
            fields.push(<Date datetimeEnabled value={entry[field.name]} />)
          } else if (field.name === 'name') {
            fields.push(
              <div className='flex gap-4' key={index}>
                <>
                  {'cover' in entry &&
                    (
                      <>
                        {(entry.cover as Image)?.url
                          ? (
                            <div className='size-16 rounded-lg overflow-hidden bg-gray-500'>
                              <img alt='preview' className='w-full h-full object-cover' src={(entry.cover as Image).url} />
                            </div>
                          )
                          : <ImageEmptyState />}
                      </>
                    )}
                </>

                <div className='flex flex-col justify-center'>
                  <div className='font-normal text-gray-700'>{entry[field.name]}</div>
                </div>
              </div>
            )
          } else if (field.name === 'externalUrl' || field.name === 'activity') {
            fields.push(
              <div key={index}>
                {entry[field.name]
                  ? (
                    <CheckIconBlock />
                  )
                  : (
                    <CrossIconBlock />
                  )
                }
              </div>
            )
          } else {
            fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
          }
        } else if (isPropertyType(field, 'boolean')) {
          fields.push(
            <div key={index}>
              {entry[field.name]
                ? (
                  <CheckIconBlock />
                )
                : (
                  <CrossIconBlock />
                )
              }
            </div>
          )
          // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
        } else if (isPropertyType(field, 'integer') || isPropertyType(field, 'number')) {
          fields.push(<div key={index}>{(entry[field.name] / 100).toFixed(2)}</div>)
        } else {
          fields.push(<div key={index}>{entry[field.name]}</div>)
        }
      } else {
        if (field.name === 'user') {
          fields.push(<div key={index}>{entry[field.name] ? entry[field.name].email : ''}</div>)
        } else if (field.name === 'activity') {
          fields.push(
            <div key={index}>
              {entry[field.name]
                ? (
                  <CheckIconBlock />
                )
                : (
                  <CrossIconBlock />
                )
              }
            </div>
          )
        } else {
          fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
        }
      }
    }

    if (field.name === 'actions') {
      fields.push(
        <ApiResourceListEntryActions
          definition={definition}
          entry={entry}
          identifierAttribute={identifierAttribute}
          onReload={onReload}
          withCloneOption={withCloneOption}
          withCopyOption={withCopyOption}
          withDeleteOption={withDeleteOption}
          withEditOption={withEditOption}
        />
      )
    } else if (field.name === 'videoFr' && 'metadata' in entry) {
      const metadata = entry.metadata as VideoMetadata
      fields.push(<div key={index}>{metadata?.videoCount?.fr ?? '-'}</div>)
    } else if (field.name === 'videoEn' && 'metadata' in entry) {
      const metadata = entry.metadata as VideoMetadata
      fields.push(<div key={index}>{metadata?.videoCount?.en ?? '-'}</div>)
    } else if (field.name === 'videoEs' && 'metadata' in entry) {
      const metadata = entry.metadata as VideoMetadata
      fields.push(<div key={index}>{metadata?.videoCount?.es ?? '-'}</div>)
    }
  })

  return (
    <tr
      className='bg-white hover:bg-gray-100 cursor-pointer group'
      onClick={onClickHandler}
    >
      {fields.map((field, index) => (
        <td
          className='px-3 py-1.5 text-sm'
          key={index}
        >
          {field}
        </td>
      ))}
    </tr>
  )
}

export default ExperienceTableRow
