import { ArrowTopRightOnSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import React from 'react'

import { type BookingFileListProps } from '@components/bookings/booking-file-list/booking-file-list.interfaces'
import IconButton from '@components/buttons/icon-button'
import { type BookingFile } from '@interfaces/api/booking/booking'

const BookingFileList = ({ booking, removeFile }: BookingFileListProps) => {
  return (
    <>
      {booking?.files?.map((file: BookingFile) => (
        <div className='flex items-center space-x-2' key={file.uid}>
          <span className='inline-flex items-center py-0.5 rounded-md text-xs font-medium text-gray-600'>
            {file.originalName}
          </span>

          <IconButton
            icon={ArrowTopRightOnSquareIcon}
            onClick={() => window.open(file.url, '_blank', 'noopener,noreferrer')}
          />

          {removeFile && (
            <IconButton
              icon={TrashIcon}
              onClick={async () => {
                await removeFile(file.uid)
              }}
            />
          )}
        </div>
      ))}
      {booking?.files && booking?.files?.length <= 0 && (
        <div className='text-xs'>
          Aucun fichier
        </div>
      )}
    </>
  )
}

export default BookingFileList
