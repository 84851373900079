import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Date from '@components/api-resource/resource-entry-fields/date/date'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import { type Breadcrumb } from '@components/breadcrumbs/breadcrumbs-interfaces'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import UsersBookings from '@components/users/users-bookings/users-bookings'
import UsersOrders from '@components/users/users-orders/users-orders'
import { type User } from '@interfaces/api/user'
import useApiResourceItemQuery from '@services/api/resources/item-query'

const UserPage = () => {
  const { id = '' } = useParams()
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'users' })

  const {
    data: user
  } = useApiResourceItemQuery<User>({ id, path: 'users/{id}', refetchOnMount: 'always' })

  const breadcrumbs: Breadcrumb[] = [
    { href: '/users', name: translateResource('title') },
    { href: `/users/${user?.uid}`, name: user?.email ?? '' }
  ]

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} ${user?.email ? `- ${user.email}` : ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8'>
          <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>

                <div className='ml-4 mt-4'>
                  <h3 className='text-xl font-semibold leading-6 text-slate-700'>{`${translateResource('title')} ${user?.email ? `- ${user.email}` : ''}`}</h3>
                </div>
              </div>
            </div>

            <div>
              <div>
                Création du compte :
                {user?.createdAt && <Date datetimeEnabled value={user?.createdAt} />}
              </div>

              <div>
                Dernière connexion :
                {user?.lastLoginAt && <Date datetimeEnabled value={user?.lastLoginAt} />}
              </div>

              <div>
                Roles :
                {user?.roles.map((role, index) => (
                  <div>{role}</div>
                ))}
              </div>

              <div>
                Tracking id :
                {user?.trackingId}
              </div>

              <div>
                Langue :
                {user?.lang}
              </div>

              <div>
                Compte supprimé :
                {user?.deleted ? 'Oui' : 'Non'}
              </div>

              {user?.deletedRequestedAt && (
                <div>
                  Date demande suppression de compte :
                  {user?.deletedRequestedAt && <Date datetimeEnabled value={user?.deletedRequestedAt} />}
                </div>
              )}

              <div>
                Compte désactivé :
                {user?.disabled ? 'Oui' : 'Non'}
              </div>

              <div>
                Compte vérifié :
                {user?.isVerified ? 'Oui' : 'Non'}
              </div>

              {user?.disabled && (
                <div>
                  Raison désactivation :
                  {user?.disabledReason}
                </div>
              )}
            </div>
          </div>

          <UsersBookings breadcrumbs={breadcrumbs} id={id} />

          <UsersOrders breadcrumbs={breadcrumbs} id={id} />
        </div>
      </Guard>
    </Layout>
  )
}

export default UserPage
