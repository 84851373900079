import moment from 'moment/moment'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import { type Breadcrumb } from '@components/breadcrumbs/breadcrumbs-interfaces'
import TooltipButton from '@components/buttons/tooltip-button/tooltip-button'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import UsersBookings from '@components/users/users-bookings/users-bookings'
import UsersOrders from '@components/users/users-orders/users-orders'
import { type User } from '@interfaces/api/user'
import useApiResourceItemQuery from '@services/api/resources/item-query'

const UserPage = () => {
  const { id = '' } = useParams()
  const { t } = useTranslation('apiResources')
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'users' })

  const {
    data: user
  } = useApiResourceItemQuery<User>({ id, path: 'users/{id}', refetchOnMount: 'always' })

  const breadcrumbs: Breadcrumb[] = [
    { href: '/users', name: translateResource('title') },
    { href: `/users/${user?.uid}`, name: user?.email ?? '' }
  ]

  const onCopyUserEmail = async () => {
    await navigator.clipboard.writeText(user?.email ?? '')
  }

  const onCopyTrackingIdClick = async () => {
    await navigator.clipboard.writeText(user?.trackingId ?? '')
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} ${user?.email ? `- ${user.email}` : ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        {user
          ? (
            <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8'>

              <div className='flex justify-between mb-4'>

                <div className='space-y-1'>
                  <div className='flex items-center space-x-2'>
                    <span className='font-semibold text-slate-700'>{`${user.email}`}</span>

                    {user.lang && (
                      <ReactCountryFlag
                        className='rounded-md'
                        countryCode={user.lang === 'en' ? 'gb' : user.lang}
                        svg
                      />
                    )}

                    {user?.roles.map((role, index) => (
                      <div
                        className='inline-flex items-center justify-center space-x-1 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset text-gray-600 bg-gray-50 ring-gray-500/10 ring-1 ring-inset'
                      >
                        {t(`roles.${role}`)}
                      </div>
                    ))}
                  </div>

                  <div className='text-sm font-light text-gray-500 space-x-1'>
                    <span>Client depuis le :</span>

                    <time dateTime={user.createdAt}>
                      {`${moment(user.createdAt).format('LL')}`}
                    </time>

                  </div>

                  {user.lastLoginAt && (
                    <div className='text-sm font-light text-gray-500 space-x-1'>
                      <span>Dernière connexion :</span>

                      <time dateTime={user.lastLoginAt}>
                        {`${moment(user.lastLoginAt).format('LL')} à ${moment(user.lastLoginAt).format('LT')}`}
                      </time>

                    </div>
                  )}
                </div>

                <div className='flex items-center justify-center space-x-2' />
              </div>

              <div className='grid grid-cols-4 gap-4'>

                <div className='col-span-3 space-y-4'>

                  <UsersBookings breadcrumbs={breadcrumbs} id={id} />

                  <UsersOrders breadcrumbs={breadcrumbs} id={id} />

                </div>

                <div>
                  <div className='bg-white rounded-lg border border-gray-200 shadow-sm p-4 text-sm space-y-2'>
                    {user && (
                      <div className='space-y-2'>
                        <div className='space-y-2'>
                          <div className='font-medium text-gray-900'>Contact</div>

                          <div className='space-y-1'>
                            <div className='flex items-center'>
                              <a className='' href={`/users/${user.uid}`}>{user.email}</a>

                              <TooltipButton handleCopyClick={onCopyUserEmail} simple size='size-4' text="Copier l'email" />
                            </div>

                            {(user.firstName || user.lastName) && (
                              <div className='flex space-x-1'>
                                {`${user.firstName} ${user.lastName}`}
                              </div>
                            )}
                          </div>

                        </div>

                        <div className='space-y-2'>

                          <div className='font-medium text-gray-900'>Compte</div>

                          <div className='space-y-1'>
                            <div className='flex items-center space-x-1'>
                              <span className='w-1/3'>Email vérifié :</span>

                              {user.isVerified
                                ? (
                                  <span
                                    className='inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'
                                  >
                                    Oui
                                  </span>
                                )
                                : (
                                  <span
                                    className='inline-flex items-center rounded-md bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'
                                  >
                                    Non
                                  </span>
                                )}
                            </div>

                            <div className='flex items-center space-x-1'>
                              <span className='w-1/3'>Supprimé :</span>

                              {user.deleted
                                ? (
                                  <span
                                    className='inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'
                                  >
                                    Oui
                                  </span>
                                )
                                : (
                                  <span
                                    className='inline-flex items-center rounded-md bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'
                                  >
                                    Non
                                  </span>
                                )}
                            </div>

                            <div className='flex items-center space-x-1'>
                              <span className='w-1/3'>Désactivé :</span>

                              {user.disabled
                                ? (
                                  <span
                                    className='inline-flex items-center rounded-md bg-green-50 px-1.5 py-0.5 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20'
                                  >
                                    Oui
                                  </span>
                                )
                                : (
                                  <span
                                    className='inline-flex items-center rounded-md bg-red-50 px-1.5 py-0.5 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10'
                                  >
                                    Non
                                  </span>
                                )}
                            </div>

                            {user.trackingId && (
                              <div className='flex items-center space-x-1'>
                                <span className='w-2/5'>Tracking ID :</span>

                                <span className='w-3/5 text-xs whitespace-nowrap overflow-hidden text-ellipsis'>{user?.trackingId}</span>

                                <TooltipButton handleCopyClick={onCopyTrackingIdClick} simple size='size-4' text={'Copier'} />
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )
          : <></>}
      </Guard>
    </Layout>
  )
}

export default UserPage
