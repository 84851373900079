import { Combobox } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { ErrorMessage, Field, useField } from 'formik'
import { useEffect, useMemo, useState } from 'react'

import { type FormAutocompleteFieldProps } from '@components/form-fields/form-autocomplete-field/form-autocomplete-field-interfaces'
import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api'
import SearchMap from '@services/api/definition/search-map'
import useApiResourceListQuery from '@services/api/resources/list-query'
import { captureException } from '@services/exceptions/capture-exception'
import { formatApiListResponse } from '@services/tools/api-resources/format-list-response'

const FormAutocompleteField = <T extends ApiPlatformEntity>({ emptyStateLabel, label, name, required = false, value }: FormAutocompleteFieldProps) => {
  const [, , helpers] = useField({ name, value })
  const { setValue } = helpers
  const [query, setQuery] = useState('')
  const { path = '', searchField = '' } = SearchMap[name] ?? {}
  const [isOpen, setIsOpen] = useState(false)

  const definition = {
    name: path,
    url: ''
  }

  const {
    data = [] as unknown as ApiPlatformListResponse<T>
  } = useApiResourceListQuery<T>({
    definition,
    parameters: {
      pagination: false
    }
  })

  const items = useMemo(() => {
    let formattedData = formatApiListResponse(data)?.data
    formattedData = formattedData?.filter((item) => {
      return item[searchField].toLowerCase().includes(query.toLowerCase())
    })

    return formattedData
  }, [data, query])

  const [selectedItem, setSelectedItem] = useState(value ?? (required ? items[0] : null))

  useEffect(() => {
    if (selectedItem && selectedItem['@id']) {
      setValue(selectedItem['@id']).catch(captureException)
    } else {
      setValue('').catch(captureException)
    }
  }, [selectedItem])

  useEffect(() => {
    if (value?.['@id']) {
      setValue(value['@id']).catch(captureException)
    }
  }, [])

  // Use effect to handle data update when navigating
  useEffect(() => {
    if (selectedItem && value?.['@id'] && value?.['@id'] !== selectedItem['@id']) {
      setSelectedItem(value)
      setValue(value['@id']).catch(captureException)
    }
    if (value?.['@id'] && !selectedItem) {
      setSelectedItem(value)
    }
    if (!value && selectedItem) {
      setSelectedItem(null)
    }
  }, [value])

  const handleInputClick = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className='flex flex-col'>
      <Field
        className=''
        name={name}
        required={required}
        type='hidden'
      />

      <Combobox as='div' nullable onChange={setSelectedItem} value={selectedItem}>
        <Combobox.Label className='block font-medium text-gray-700 text-sm'>{label}</Combobox.Label>

        <div className='relative mt-2'>
          <Combobox.Button className='w-full'>
            <Combobox.Input
              className='appearance-none w-full rounded-md bg-gray-50 border border-gray-300 py-1.5 pl-3 pr-10 text-gray-900 shadow-sm sm:text-sm sm:leading-6 focus:outline-none'
              displayValue={(item) => item?.[searchField]}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
              onClick={handleInputClick}
            />

            <span className='absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none'>
              <ChevronUpDownIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
            </span>
          </Combobox.Button>

          {items.length > 0 && (
            <Combobox.Options className='absolute z-10 w-full mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg focus:outline-none sm:text-sm'>
              {!required && (
                <Combobox.Option
                  className={({ active }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-slate-900 text-white' : 'text-gray-900'
                    )
                  }
                  key={'null-item'}
                  value={''}
                >
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{emptyStateLabel ?? '- Sélectionner -'}</span>
                    </>
                  )}
                </Combobox.Option>
              )}

              {items.map((item) => (
                <Combobox.Option
                  className={({ active, selected }) =>
                    classNames(
                      'relative cursor-default select-none py-2 pl-3 pr-9',
                      active ? 'bg-slate-900 text-white' : 'text-gray-900',
                      selected ? 'bg-primary text-white' : 'text-gray-900'
                    )
                  }
                  key={item.uid}
                  value={item}
                >
                  {({ active, selected }) => (
                    <>
                      <span className={classNames('block truncate', selected && 'font-semibold')}>{item?.[searchField]}</span>

                      {selected && (
                        <span
                          className={classNames(
                            'absolute inset-y-0 right-0 flex items-center pr-4',
                            active ? 'bg-slate-900 text-white' : 'text-primary',
                            selected ? 'bg-primary text-white' : 'text-primary'
                          )}
                        >
                          <CheckIcon aria-hidden='true' className='h-5 w-5' />
                        </span>
                      )}
                    </>
                  )}
                </Combobox.Option>
              ))}
            </Combobox.Options>
          )}
        </div>
      </Combobox>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormAutocompleteField
