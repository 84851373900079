import {
  ComputerDesktopIcon, DevicePhoneMobileIcon,
  LockClosedIcon,
  LockOpenIcon,
  PencilSquareIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResourceOrder from '@components/api-resource/api-resource-order'
import ModalDeleteEntry from '@components/modals/modal-delete-entry/modal-delete-entry'
import RowList from '@components/pages/row-list'
import { type SectionEntryProps } from '@components/pages/section-entry/section-entry.interfaces'
import { type Section, SectionContext, SectionType } from '@interfaces/api/page/section'
import useApiResource from '@services/api/definition'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const SectionEntry = ({ onEdit, onSectionDelete, onSectionReorder, pageId, position, section: currentSection, totalItems }: SectionEntryProps) => {
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const definition = useApiResource('sections/{uid}')
  const [visible, setVisible] = useState<boolean>(true)
  const { t } = useTranslation('apiResources')
  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()
  const [section, setSection] = useState<Section>(currentSection)

  useEffect(() => {
    setSection(currentSection)
  }, [currentSection])

  const onDeleteHandler = () => {
    onSectionDelete(section.uid)
  }

  const onReorderHandler = (direction: number) => {
    onSectionReorder(section, direction)
  }

  const onToggleClick = () => {
    setVisible(!visible)
  }

  const handleDeleteClick = (e) => {
    e.stopPropagation()
    setOpenDeleteModal(true)
  }

  const handleEditClick = (e) => {
    e.stopPropagation()
    onEdit(section)
  }

  const onLockClick = (e) => {
    e.stopPropagation()
    patchResourceEntry({
      data: {
        isLocked: !section.isLocked
      },
      id: section.uid,
      path: 'sections/{uid}'
    }).then(() => {
      setSection({
        ...section,
        isLocked: !section.isLocked
      })
    }).catch(captureException)
  }

  const onMobileToggle = (e) => {
    e.stopPropagation()

    const bothEnabled = SectionContext.BOTH === section.context
    const mobileEnabled = SectionContext.MOBILE === section.context
    const newContext = bothEnabled ? SectionContext.WEB : (mobileEnabled ? SectionContext.MOBILE : SectionContext.BOTH)

    patchResourceEntry({
      data: {
        context: newContext
      },
      id: section.uid,
      path: 'sections/{uid}'
    }).then(() => {
      setSection({
        ...section,
        context: newContext
      })
    }).catch(captureException)
  }

  const onWebToggle = (e) => {
    e.stopPropagation()

    const bothEnabled = SectionContext.BOTH === section.context
    const webEnabled = SectionContext.WEB === section.context
    const newContext = bothEnabled ? SectionContext.MOBILE : (webEnabled ? SectionContext.WEB : SectionContext.BOTH)

    patchResourceEntry({
      data: {
        context: newContext
      },
      id: section.uid,
      path: 'sections/{uid}'
    }).then(() => {
      setSection({
        ...section,
        context: newContext
      })
    }).catch(captureException)
  }

  return (
    <div
      className='p-1 bg-slate-200 rounded-lg'
    >
      <button className='flex items-center w-full group py-2 px-4 gap-2 text-gray-900 hover:cursor-pointer'
        onClick={onToggleClick}
      >
        <span
          className='font-medium text-gray-700'
        >
          {`${section.title ? section.title + ' -' : ''} ${t(`sections.types.${SectionType[section.type]}`)}`}
        </span>

        <button className='bg-white rounded-md p-1 hover:border-gray-900 hover:bg-gray-800' onClick={onLockClick}>
          {section.isLocked
            ? (
              <LockClosedIcon className='h-4 w-4 text-primary' />
            )
            : (
              <LockOpenIcon className='h-4 w-4 text-green-400' />
            )}
        </button>

        <button className='bg-white rounded-md p-1 hover:border-gray-900 hover:bg-gray-800' onClick={onWebToggle}>
          {[SectionContext.BOTH, SectionContext.WEB].includes(section.context)
            ? (
              <ComputerDesktopIcon className='h-4 w-4 text-green-400' />
            )
            : (
              <ComputerDesktopIcon className='h-4 w-4 text-gray-400' />
            )}
        </button>

        <button className='bg-white rounded-md p-1 hover:border-gray-900 hover:bg-gray-800' onClick={onMobileToggle}>
          {[SectionContext.BOTH, SectionContext.MOBILE].includes(section.context)
            ? (
              <DevicePhoneMobileIcon className='h-4 w-4 text-green-400' />
            )
            : (
              <DevicePhoneMobileIcon className='h-4 w-4 text-gray-400' />
            )}
        </button>

        <div className='ml-2'>
          {definition && (
            <ApiResourceOrder
              id={section['@id']}
              onReorder={onReorderHandler}
              position={position}
              resourcePath={`sections/${section.uid}`}
              totalItems={totalItems}
            />
          )}
        </div>

        <div className='ml-auto flex gap-1'>
          <button
            className='flex items-center bg-white rounded-md border hover:fill-white hover:text-white p-1 border-gray-200 hover:border-gray-900 hover:bg-gray-800'
            onClick={handleEditClick}
          >
            <PencilSquareIcon className='w-3.5 h-3.5' />
          </button>

          <button
            className='flex items-center bg-white rounded-md border hover:fill-white hover:text-white p-1 border-gray-200 hover:border-gray-900 hover:bg-gray-800'
            onClick={handleDeleteClick}
          >
            <TrashIcon className='w-3.5 h-3.5' />
          </button>

          {definition && (
            <ModalDeleteEntry
              definition={definition}
              entryId={section.uid}
              onDelete={onDeleteHandler}
              open={openDeleteModal}
              setOpen={setOpenDeleteModal}
            />
          )}
        </div>
      </button>

      {visible && (
        <RowList pageId={pageId} section={section} />
      )}
    </div>
  )
}

export default SectionEntry
