import React from 'react'

import { type ApiResourceDeleteProps } from '@components/api-resource/api-resource-delete/index'
import useDeleteEntry from '@services/api/resources/delete-entry-query'
import { captureException } from '@services/exceptions/capture-exception'

const ApiResourceDelete = ({ component: Component, definition, id, onDelete }: ApiResourceDeleteProps) => {
  const resourceParameters = { id, path: definition.url }
  const { mutateAsync: deleteResourceEntry } = useDeleteEntry(resourceParameters)

  const onDeleteHandler = () => {
    deleteResourceEntry(undefined).then(() => {
      onDelete()
    }).catch(captureException)
  }

  return (
    <Component onClick={onDeleteHandler} />
  )
}

export default ApiResourceDelete
