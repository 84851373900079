import { type FormikValues, useFormikContext } from 'formik'
import { useEffect } from 'react'

import {
  type ApiResourceFormObserverProps
} from '@components/api-resource/api-resource-form/api-resource-form-observer.interfaces'
import { useFormStore } from '@services/stores/forms/forms'

const ApiResourceFormObserver = <T extends FormikValues>({ definitionName }: ApiResourceFormObserverProps) => {
  const { values } = useFormikContext<T>()
  const updateForm = useFormStore((state) => state.updateForm)

  useEffect(() => {
    updateForm(definitionName, values)
  }, [values, definitionName, updateForm])

  return null
}

export default ApiResourceFormObserver
