import {
  ArrowTopRightOnSquareIcon,
  CheckIcon,
  LockClosedIcon,
  PencilSquareIcon
} from '@heroicons/react/24/outline'
import { Tooltip } from 'flowbite-react'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import 'moment/locale/fr'

import Date from '@components/api-resource/resource-entry-fields/date'
import BookingFileList from '@components/bookings/booking-file-list/booking-file-list'
import BookingFileUpload from '@components/bookings/booking-file-upload/booking-file-upload'
import BookingStatusBadge from '@components/bookings/booking-status-badge/booking-status-badge'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import { type Breadcrumb } from '@components/breadcrumbs/breadcrumbs-interfaces'
import ContextualButton from '@components/buttons/contextual-button'
import TooltipButton from '@components/buttons/tooltip-button/tooltip-button'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import { NotificationType } from '@components/notification/notification.interfaces'
import {
  type Booking,
  BookingCheckoutState,
  BookingCreationMethod,
  BookingShippingState,
  BookingState
} from '@interfaces/api/booking/booking'
import { useMe } from '@services/api/auth/use-me'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useNotificationStore } from '@services/stores/notification/notification'
import countries from '@services/translations/fr/countries.json'

const BookingEntry = () => {
  moment.locale('fr')

  const { id = '' } = useParams()
  const location = useLocation()
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [openActivateModal, setOpenActivateModal] = useState<boolean>(false)
  const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false)
  const [isLoadingActivate, setIsLoadingActivate] = useState<boolean>(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)

  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'bookings' })
  const {
    data: booking,
    refetch
  } = useApiResourceItemQuery<Booking>({ id, path: 'bookings/{uid}', refetchOnMount: 'always' })

  const { mutateAsync: patchBookingCancel } = usePatchEntry({ id, path: 'bookings/{uid}/force-cancel' })
  const { mutateAsync: patchBookingActivate } = usePatchEntry({ id, path: 'manual-bookings/{uid}/activate' })
  const { mutateAsync: shipBooking } = usePatchEntry({ id, path: 'bookings/{uid}/ship' })

  const navigate = useNavigate()

  const { displayNotification } = useNotificationStore()

  const { data: user } = useMe()

  const isSuperAdmin = () => {
    if (user) {
      return user.roles.includes('ROLE_SUPER_ADMIN')
    }

    return false
  }

  const allowedToCancel = isSuperAdmin()

  const getBookingId = () => {
    if (!booking) {
      return id
    }

    if (booking.orderNumber) {
      return `#${booking.orderNumber}`
    } else if (booking.customerEmail) {
      return booking.customerEmail.toLowerCase()
    } else {
      return booking.uid
    }
  }

  useEffect(() => {
    if (location.state?.breadcrumbs) {
      setBreadcrumbs([
        ...location.state?.breadcrumbs,
        { href: `/bookings/${booking?.uid}`, name: `Booking ${getBookingId()}` }
      ])
    } else {
      setBreadcrumbs([
        { href: `/bookings/${booking?.uid}`, name: `Booking ${getBookingId()}` }
      ])
    }
  }, [booking])

  const onDeleteClick = () => {
    setOpenDeleteModal(true)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
  }

  const onDeleteHandler = async () => {
    setIsLoadingDelete(true)
    await patchBookingCancel({}).then(() => {
      refetch().catch(captureException)
      setOpenDeleteModal(false)
      setIsLoadingDelete(false)
      displayNotification(translateResource('message.cancel.success.title'), translateResource('message.cancel.success.description'), NotificationType.success)
    }).catch(captureException)
  }

  const onEditClick = () => {
    navigate(`/bookings/edit/${id}`)
  }

  const onActivateClick = () => {
    setOpenActivateModal(true)
  }
  const onBookingShip = () => {
    setOpenConfirmModal(true)
  }

  const onShipConfirm = async () => {
    await shipBooking({}).then(() => {
      refetch().catch(captureException)
      setOpenConfirmModal(false)
    }).catch(captureException)
  }

  const onShipCancel = () => {
    setOpenConfirmModal(false)
  }

  const onCopyUserEmail = async () => {
    await navigator.clipboard.writeText(booking?.user?.email ?? '')
  }

  const onActivateHandler = async () => {
    setIsLoadingActivate(true)
    await patchBookingActivate({}).then(() => {
      refetch().catch(captureException)
      setOpenActivateModal(false)
      setIsLoadingActivate(false)
      displayNotification(translateResource('message.activate.success.title'), translateResource('message.activate.success.description'), NotificationType.success)
    }).catch(captureException)
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} - ${getBookingId()}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        {booking
          ? (
            <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8'>

              <div className='flex justify-between mb-4'>

                <div className='space-y-1'>
                  <div className='flex items-center space-x-2'>
                    <span className='font-semibold text-slate-700'>{getBookingId()}</span>

                    <BookingStatusBadge status={booking.paymentState} type='paymentState' />

                    <BookingStatusBadge status={booking.shippingState} type='shippingState' />

                    <BookingStatusBadge status={booking.checkoutState} type='checkoutState' />
                  </div>

                  <div className='text-sm font-light text-gray-500'>
                    {booking.createdAt && (
                      <time dateTime={booking.createdAt}>
                        {`${moment(booking.createdAt).format('LL')} à ${moment(booking.createdAt).format('LT')}`}
                      </time>
                    )}

                    {booking.creationMethod && (
                      <span className='space-x-1 mx-1'>
                        <span>{'depuis'}</span>

                        <span className='font-semibold'>
                          {translateResource(`creationMethodFull.${booking.creationMethod}`)}
                        </span>

                        {booking.reseller && (
                          <span>{`(${booking.reseller})`}</span>
                        )}
                      </span>
                    )}
                  </div>
                </div>

                <div className='flex items-center justify-center space-x-2'>
                  {booking.state !== BookingState.cancelled && booking.state !== BookingState.cart && (
                    allowedToCancel
                      ? (
                        <ContextualButton disabled={!allowedToCancel} onClick={onDeleteClick} style='warning'>
                          Annuler le booking
                        </ContextualButton>
                      )
                      : (
                        <Tooltip content='Seul un super admin peut annuler un booking'>
                          <ContextualButton disabled={!allowedToCancel} onClick={onDeleteClick} style='warning'>
                            Annuler le booking
                            {!allowedToCancel && <LockClosedIcon className='h-4 w-4' />}
                          </ContextualButton>
                        </Tooltip>
                      )
                  )}

                  {booking.creationMethod === BookingCreationMethod.MANUAL && booking.checkoutState !== BookingCheckoutState.completed && (
                    <>
                      <ContextualButton icon={PencilSquareIcon} onClick={onEditClick}>
                        Modifier le booking
                      </ContextualButton>

                      <ContextualButton icon={CheckIcon} onClick={onActivateClick}>
                        Activer le booking
                      </ContextualButton>
                    </>
                  )}

                  {booking.shippingState === BookingShippingState.awaiting_manual_shipment && (
                    <ContextualButton onClick={onBookingShip}>
                      {translateActions('shipBooking')}
                    </ContextualButton>
                  )}
                </div>
              </div>

              <div className='grid grid-cols-3 gap-4'>

                <div className='col-span-2 space-y-4'>

                  <div className='bg-white rounded-lg border border-gray-200 shadow-sm p-4'>
                    <div className='mb-4'>
                      <div className='text-xs font-medium text-gray-500'>Date de visite</div>

                      <Date className='text-xs font-medium text-gray-600 inline-flex' datetimeEnabled={!booking.aggregatedData.allDay} value={booking.startTimeLocal} />
                    </div>

                    <div className='border border-gray-200 rounded-lg'>
                      {booking.items.map((item) => (
                        <div className='text-sm flex items-center justify-between' key={item.product.uid}>
                          <div className='py-6 px-4'>

                            <div className='flex items-center'>
                              {item?.product?.customCover?.url
                                ? (
                                  <img
                                    alt={'Product'}
                                    className='mr-6 size-10 rounded object-cover object-center'
                                    src={item.product.customCover.url}
                                  />
                                )
                                : (
                                  item?.product?.cover?.url
                                    ? (
                                      <img
                                        alt={'Product'}
                                        className='mr-6 size-10 rounded object-cover object-center'
                                        src={item.product.cover.url}
                                      />
                                    )
                                    : (
                                      <div className='mr-6 h-16 w-16 rounded object-cover object-center' />
                                    )
                                )
                              }

                              <a className='font-medium text-gray-900 flex items-center space-x-2'
                                href={`/products/${item.product.uid}`} target={'_blank'}
                              >
                                <span>{item.product.name}</span>

                                <ArrowTopRightOnSquareIcon className='w-4 h-4' />
                              </a>
                            </div>
                          </div>

                          <div>
                            {item.priceOptions.map((option, index) => {
                              const total = (option.price / 100) * option.quantity

                              return (
                                <div className='space-x-1' key={index}>
                                  <span className='font-bold'>{option.name}</span>

                                  <span className='font-bold'>{option.quantity}</span>

                                  <span>x</span>

                                  <span>{(option.price / 100).toFixed(2)}</span>

                                  <span>=</span>

                                  <span>{total.toFixed(2)}</span>
                                </div>
                              )
                            })}
                          </div>

                          <div className='py-6 pr-8'>
                            {(item.subTotal / 100).toFixed(2)}

                            {' '}

                            {item.product.currency}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className='bg-white rounded-lg border border-gray-200 shadow-sm p-4'>
                    <div className='flex items-center space-x-2 mb-2'>
                      <span className='text-sm font-semibold'>Vouchers</span>
                    </div>

                    {booking.shippingState === BookingShippingState.awaiting_manual_shipment && (
                      <BookingFileUpload booking={booking} reload={refetch} />
                    )}

                    {booking.shippingState === BookingShippingState.shipped && (
                      <BookingFileList booking={booking} />
                    )}
                  </div>
                </div>

                <div>
                  <div className='bg-white rounded-lg border border-gray-200 shadow-sm p-4 text-sm space-y-2'>
                    {booking?.user && (
                      <div>
                        <div className='font-medium text-gray-900'>Utilisateur</div>

                        <div className='flex items-center'>
                          <a className='text-primary' href={`/users/${booking.user.uid}`}>{booking.user.email}</a>

                          <TooltipButton handleCopyClick={onCopyUserEmail} simple size='size-4' />
                        </div>
                      </div>
                    )}

                    <div>
                      <div className='font-medium text-gray-900'>Contact information</div>

                      <div className='font-light'>
                        <div>{`${booking.customerFirstName} ${booking.customerLastName}`}</div>

                        <div className='flex items-center'>
                          {booking.customerEmail}

                          <TooltipButton handleCopyClick={onCopyUserEmail} simple size='size-4' />
                        </div>

                        <div>{countries[booking.customerCountry as keyof typeof countries]}</div>

                        <div>{booking.customerPhone}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
          : <></>}

        <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('cancelBooking')}>
          <div className='flex flex-col items-center p-8'>
            <div className='text-center'>{translateActions('cancelConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton isLoading={isLoadingDelete} onClick={onDeleteHandler} style='warning'>{translateActions('confirm')}</ContextualButton>

              <ContextualButton disabled={isLoadingDelete} onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
            </div>
          </div>
        </Modal>

        <Modal center open={openActivateModal} setOpen={setOpenActivateModal} title={translateActions('activateBooking')}>
          <div className='flex flex-col items-center p-8'>
            <div className='text-center whitespace-pre-line'>{translateActions('activateConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton isLoading={isLoadingActivate} onClick={onActivateHandler} style='warning'>{translateActions('confirm')}</ContextualButton>

              <ContextualButton disabled={isLoadingActivate} onClick={() => {
                setOpenActivateModal(false)
              }}
              >
                {translateActions('cancel')}
              </ContextualButton>
            </div>
          </div>
        </Modal>

        <Modal center open={openConfirmModal} setOpen={setOpenConfirmModal} title={translateActions('shipBooking')}>
          <div className='flex flex-col items-center p-8'>
            <div className='text-center'>{translateActions('shipConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton onClick={onShipConfirm} style='success'>{translateActions('confirm')}</ContextualButton>

              <ContextualButton onClick={onShipCancel}>{translateActions('cancel')}</ContextualButton>
            </div>
          </div>
        </Modal>
      </Guard>
    </Layout>
  )
}

export default BookingEntry
