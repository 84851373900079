/* eslint-disable typescript-sort-keys/string-enum */
export interface Language {
  code: LanguageCodes
  countryCode: string
  id: number
  name: string
}

export enum LanguageCodes {
  en = 'en',
  fr = 'fr',
  es = 'es',
  de = 'de',
  zh = 'zh',
  hi = 'hi',
  it = 'it',
  nl = 'nl',
  pt = 'pt'
}

export const languages: Language[] = [
  { code: LanguageCodes.en, countryCode: 'GB', id: 2, name: 'Anglais' },
  { code: LanguageCodes.fr, countryCode: 'FR', id: 1, name: 'Français' },
  { code: LanguageCodes.es, countryCode: 'ES', id: 3, name: 'Espagnol' },
  { code: LanguageCodes.zh, countryCode: 'CN', id: 4, name: 'Chinois' },
  { code: LanguageCodes.de, countryCode: 'DE', id: 5, name: 'Allemand' },
  { code: LanguageCodes.hi, countryCode: 'IN', id: 6, name: 'Hindi' },
  { code: LanguageCodes.it, countryCode: 'IT', id: 7, name: 'Italien' },
  { code: LanguageCodes.nl, countryCode: 'NL', id: 8, name: 'Néerlandais' },
  { code: LanguageCodes.pt, countryCode: 'PT', id: 9, name: 'Portugais' }
]
