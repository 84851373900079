import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import { type Breadcrumb } from '@components/breadcrumbs/breadcrumbs-interfaces'
import ContextualButton from '@components/buttons/contextual-button'
import FormNumberField from '@components/form-fields/form-number-field'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { NotificationType } from '@components/notification/notification.interfaces'
import ProductCodesUpload from '@components/products/product-codes-upload/product-codes-upload'
import ProductExperiences from '@components/products/product-experiences/product-experiences'
import ProductPriceOptions from '@components/products/product-price-options/product-price-options'
import { type Product } from '@interfaces/api/product/product'
import useApiResource from '@services/api/definition'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'
import { useFormStore } from '@services/stores/forms/forms'
import { useNotificationStore } from '@services/stores/notification/notification'

const ProductPage = () => {
  const { id = '' } = useParams()
  const location = useLocation()
  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'products' })
  const { t } = useTranslation('apiResources')

  const [isLoadingSync, setIsLoadingSync] = useState(false)
  const [isDeletingCache, setIsDeletingCache] = useState(false)
  const { displayNotification } = useNotificationStore()
  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()
  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple()
  const formValues = useFormStore((state) => state.forms.products)

  const {
    data: product,
    refetch
  } = useApiResourceItemQuery<Product>({ id, path: 'products/{uid}', refetchOnMount: 'always' })
  const definition = useApiResource('products/{uid}')

  useEffect(() => {
    if (location.state?.breadcrumbs) {
      setBreadcrumbs([
        ...location.state?.breadcrumbs,
        { href: `/products/${product?.uid}`, name: product?.name ?? '' }
      ])
    } else {
      setBreadcrumbs([
        { href: '/products', name: translateResource('title') },
        { href: `/products/${product?.uid}`, name: product?.name ?? '' }
      ])
    }
  }, [product])

  const onManualSyncClick = () => {
    if (product) {
      setIsLoadingSync(true)
      patchResourceEntry({
        data: {},
        id: product.uid,
        path: 'products/{uid}/synchronize'
      }).then(() => {
        setIsLoadingSync(false)
        displayNotification('Synchronisation effectuée', 'Le produit est maintenant à jour avec Rezdy', NotificationType.success)
      }).catch(captureException)
    }
  }

  const onCacheDeleteClick = () => {
    if (product) {
      setIsDeletingCache(true)
      deleteResourceEntry({
        id: product.uid,
        path: 'products/{uid}/sessions-caches'
      }).then(() => {
        setIsDeletingCache(false)
        displayNotification('Cache supprimé', 'Le cache des disponibilité a bien été réinitialisé.', NotificationType.success)
      }).catch(captureException)
    }
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} ${product?.name ? `- ${product.name}` : ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8'>
          <div className='flex justify-end space-x-2'>

            <ContextualButton isLoading={isLoadingSync} onClick={onManualSyncClick}>
              {translateActions('manualSync')}
            </ContextualButton>

            <ContextualButton isLoading={isDeletingCache} onClick={onCacheDeleteClick}>
              {translateActions('deleteCache')}
            </ContextualButton>

          </div>

          <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-6'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>

                <div className='ml-4 mt-4'>
                  <h3
                    className='text-xl font-semibold leading-6 text-slate-700'
                  >
                    {`${translateResource('title')} ${product?.name ? `- ${product.name}` : ''}`}
                  </h3>

                  <p className='text-xs'>
                    {`Rezdy code : ${product?.rezdyProductCode}`}
                  </p>
                </div>
              </div>
            </div>

            {product && (
              <>
                {definition && (
                  <ApiResourceEdit definition={definition} fieldsToHide={['cancellationPolicyDays']} id={id}>
                    <FormNumberField key='cancellationPolicyDays'
                      label={`${t('labels.cancellationPolicyDays')} (Rezdy : ${product.defaultCancellationPolicyDays} jours)`}
                      min={product.defaultCancellationPolicyDays} name='cancellationPolicyDays'
                      required={false}
                    />
                  </ApiResourceEdit>
                )}
              </>
            )}
          </div>

          {product && (
            <ProductPriceOptions product={product} reload={refetch} />
          )}

          <ProductExperiences id={id} />

          {product && formValues?.provideCodes && (
            <ProductCodesUpload product={product} />
          )}

        </div>
      </Guard>
    </Layout>
  )
}

export default ProductPage
