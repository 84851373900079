import { type ApiResourceProps } from '@components/api-resource'
import ApiResourceList from '@components/api-resource/api-resource-list'
import useApiResource from '@root/services/api/definition'

const ApiResource = ({ createButton, createdAt, customFieldsToDisplay, customFilters, customParams, entryComponent, hasMargin, hideFilters = false, identifierAttribute = 'uid', name, onClick, uid, withCloneOption = false, withCopyOption, withCreateOption, withDeleteOption = false, withEditOption }: ApiResourceProps) => {
  const resource = useApiResource(name)
  const onClickHandler = (entry) => {
    if (onClick) {
      onClick(entry)
    }
  }

  return (
    <>
      {resource && (
        <ApiResourceList
          createButton={createButton}
          createdAt={createdAt}
          customFieldsToDisplay={customFieldsToDisplay}
          customFilters={customFilters}
          customParams={customParams}
          definition={resource}
          entryComponent={entryComponent}
          hasMargin={hasMargin}
          hideFilters={hideFilters}
          identifierAttribute={identifierAttribute}
          onEntryClick={onClickHandler}
          uid={uid}
          withCloneOption={withCloneOption}
          withCopyOption={withCopyOption}
          withCreateOption={withCreateOption}
          withDeleteOption={withDeleteOption}
          withEditOption={withEditOption}
        />
      )}
    </>
  )
}

export default ApiResource
