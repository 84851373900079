import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api'
import { type Page } from '@interfaces/api/page/page'

export enum SectionContext {
  BOTH = 'both',
  MOBILE = 'mobile',
  WEB = 'web'
}
export interface Section extends ApiPlatformEntity {
  context: SectionContext
  isLocked: boolean
  page: Page
  priority: number
  title: string
  type: SectionType
  uid: string
}

export interface SectionInput extends ApiPlatformEntity {
  customData?: unknown | null
  page: string
  priority?: number
  title: string
  type: SectionType
}
export enum SectionType {
  bestseller = 'bestseller',
  bestsellerInner = 'bestsellerInner',
  bestsellerTile = 'bestsellerTile',
  elementWithoutTextHorizontalList = 'elementWithoutTextHorizontalList',
  elementWithoutTextVerticalList = 'elementWithoutTextVerticalList',
  elementWithPrice = 'elementWithPrice',
  elementWithPriceHorizontalList = 'elementWithPriceHorizontalList',
  elementWithPriceVerticalList = 'elementWithPriceVerticalList',
  essential = 'essential',
  essentialLandscape = 'essentialLandscape',
  essentialStretched = 'essentialStretched',
  grid = 'grid',
  gridVideo = 'gridVideo',
  horizontalList = 'horizontalList',
  horizontalListSmall = 'horizontalListSmall',
  singleItem = 'singleItem',
  slider = 'slider',
  sliderInner = 'sliderInner',
  unmissableTile = 'unmissableTile',
  verticalThumbnailList = 'verticalThumbnailList',
  verticalThumbnailTileList = 'verticalThumbnailTileList'
}

export const SectionLimited: SectionType[] = [SectionType.bestsellerTile, SectionType.bestseller, SectionType.singleItem, SectionType.essential]

export type SectionsResponse = ApiPlatformListResponse<Section>
