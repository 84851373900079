import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api'
import { type City } from '@interfaces/api/city/city'
import { type Page } from '@interfaces/api/page'

export interface CityPage extends ApiPlatformEntity {
  city: City
  page: Page
  priority: number
  slug: string
  type: CityPageType
  uid: string
}

export enum CityPageType {
  ACTIVITIES = 'activities',
  SECTIONS = 'sections',
  TABS = 'tabs',
  TOURS = 'tours'
}

export type CityPagesResponse = ApiPlatformListResponse<CityPage>
