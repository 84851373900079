import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import BookingFileList from '@components/bookings/booking-file-list/booking-file-list'
import { type BookingFileUploadProps } from '@components/bookings/booking-file-upload/booking-file-upload.interfaces'
import ContextualButton from '@components/buttons/contextual-button'
import FormFileField from '@components/form-fields/form-file-field/form-file-field'
import { type Booking } from '@interfaces/api/booking/booking'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { usePostFileQuery } from '@services/api/resources/post-entry-file'
import { captureException } from '@services/exceptions/capture-exception'

const BookingFileUpload = ({ booking: bookingItem, reload }: BookingFileUploadProps) => {
  const { t } = useTranslation('apiResources')
  const { mutateAsync: postFile } = usePostFileQuery('files')
  const { mutateAsync: patchBookingFiles } = usePatchEntry({ id: bookingItem.uid, path: 'bookings/{uid}/files' })
  const [booking, setBooking] = useState<Booking>(bookingItem)
  const [isUploading, setIsUploading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    setBooking(bookingItem)
  }, [bookingItem])

  const onSubmit = async (values, { resetForm, setSubmitting }) => {
    setIsUploading(true)

    const fileIds = await Promise.all(values.files.map(async (file) => {
      const formData = new FormData()
      formData.append('file', file)
      try {
        return await postFile(formData)
      } catch (error) {
        captureException(error as Error)

        return null
      }
    })).catch(error => {
      captureException(error)

      return []
    })

    const validFileIds = fileIds.filter(id => id != null)
    if (validFileIds.length > 0) {
      const updatedFileIds = booking.files ? [...booking.files.map(file => file['@id']), ...validFileIds] : [...validFileIds]
      await patchBookingFiles({ files: updatedFileIds }).then(reload).catch(captureException)
    }

    setIsUploading(false)
    setSubmitting(false)
    setIsSuccess(true)

    setTimeout(() => {
      setIsSuccess(false)
      resetForm()
    }, 2000)
  }

  const removeFile = async (fileUid: string) => {
    if (booking.files) {
      await patchBookingFiles({
        files: booking.files.filter(file => file.uid !== fileUid).map(file => file['@id'])
      }).then(() => {
        setBooking({
          ...booking,
          files: booking.files?.filter(file => file.uid !== fileUid)
        })
      })
    }
  }

  return (
    <div>
      <div className='py-3 px-4 w-full max-h-full'>
        <div className='mb-10 space-y-2'>
          <div className='space-y-2'>
            <BookingFileList booking={booking} removeFile={removeFile} />
          </div>
        </div>

        <Formik
          enableReinitialize={true}
          initialValues={{
            files: []
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values }) => {
            return (
              <Form className='space-y-6'>

                <FormFileField
                  accept={'.pdf'}
                  isSuccess={isSuccess}
                  key='files'
                  multiple
                  name={'files'}
                  required={true}
                  translateKey='PDF'
                />

                <div className='flex justify-end'>
                  <ContextualButton disabled={isSubmitting || isUploading || values.files === null} icon={PlusCircleIcon} isLoading={isSubmitting || isUploading} type='submit'>
                    {t('buttons.upload')}
                  </ContextualButton>
                </div>
              </Form>
            )
          }}
        </Formik>

      </div>
    </div>
  )
}

export default BookingFileUpload
