import { useMap, useMapsLibrary } from '@vis.gl/react-google-maps'
import React, { useEffect, useState } from 'react'

import { captureException } from '@services/exceptions/capture-exception'

interface MapAddressProps {
  markerPosition: {
    lat: number
    lng: number
  }
}
const MapAddress = ({ markerPosition }: MapAddressProps) => {
  const [geocoder, setGeocoder] = useState(null)
  const [address, setAddress] = useState('')

  const geocodingLibrary = useMapsLibrary('geocoding')
  const map = useMap()

  useEffect(() => {
    if (!geocodingLibrary || !map) {
      return
    }

    // @ts-expect-error no type
    setGeocoder(new geocodingLibrary.Geocoder())
  }, [geocodingLibrary, map])

  useEffect(() => {
    updateAddress()
  }, [markerPosition, geocoder])

  const updateAddress = () => {
    if (!geocoder) {
      return
    }

    // @ts-expect-error no geocode typescript
    geocoder.geocode({ location: { lat: markerPosition.lat, lng: markerPosition.lng } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          map?.fitBounds(results[0].geometry.viewport)
          setAddress(results[0].formatted_address)
        }
      } else {
        console.error('Geocoder failed due to: ' + status)
      }
    }).catch(captureException)
  }

  return (
    <div className='text-right mb-2 text-xs'>{address}</div>
  )
}

export default MapAddress
