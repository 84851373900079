import {
  ArtistEntry,
  Artists, BookingCreate, BookingEdit, BookingEntry,
  Bookings, Categories, Changelog,
  Cities,
  CityCreate,
  CityEdit, CityEntry, CodeBulkCreate, CodeBulkEntry, CodeBulks,
  ExperienceEntry, Experiences, Home,
  Login, PageEntry, PlanEntry, ProductEntry, Products, UserEntry,
  Users, Videos
} from '@pages'
import * as Sentry from '@sentry/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import React from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { useLocation } from 'react-router'
import { Navigate, Route, Routes } from 'react-router-dom'

// eslint-disable-next-line no-restricted-imports
import packageInfo from '../package.json'
import { CodeCreate, Codes } from '@pages/codes'
import { Orders } from '@pages/orders'
import { PartnerEntry, Partners } from '@pages/partners'
import { PartnersPricingGridEntry, PartnersPricingGrids } from '@pages/partners-pricing-grid'
import { useMe } from '@services/api/auth/use-me'
import { useAuthToken } from '@services/hooks/auth-token'

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllText: false
      })
    ],
    release: `citycrush-admin@${packageInfo.version}`,

    // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0,

    // Session Replay
    replaysSessionSampleRate: 0.1,

    //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ['localhost', /^https:\/\/api.citycrushtravel\.com/, /^https:\/\/api-admin.staging.citycrushtravel\.com/],

    // Performance Monitoring
    tracesSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

const DEFAULT_STALE_TIME = 5 * 60 * 1000

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      staleTime: DEFAULT_STALE_TIME
    }
  }
})

const Protected = ({ children }: { children: JSX.Element }) => {
  const location = useLocation()
  const { value: authToken } = useAuthToken()
  useMe()

  if (!authToken) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they log in, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate replace state={{ from: location }} to='/login' />
  }

  return children
}

function App () {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route element={<Login />} path='/login' />

          <Route element={<Protected><Home /></Protected>} path='/' />

          <Route element={<Protected><Cities /></Protected>} path='/cities' />

          <Route element={<Protected><Changelog /></Protected>} path='/changelog' />

          <Route element={<Protected><CodeBulks /></Protected>} path='/code-groups' />

          <Route element={<Protected><CodeBulkCreate /></Protected>} path='/code-groups/create' />

          <Route element={<Protected><CodeBulkEntry /></Protected>} path='/code-groups/:id' />

          <Route element={<Protected><Codes /></Protected>} path='/codes' />

          <Route element={<Protected><CodeCreate /></Protected>} path='/codes/create' />

          <Route element={<Protected><Cities /></Protected>} path='/cities' />

          <Route element={<Protected><CityCreate /></Protected>} path='/cities/create' />

          <Route element={<Protected><CityEntry /></Protected>} path='/cities/:id/:type?' />

          <Route element={<Protected><CityEdit /></Protected>} path='/cities/:id/edit' />

          <Route element={<Protected><PageEntry /></Protected>} path='/pages/:id/:slug' />

          <Route element={<Protected><PageEntry /></Protected>} path='/pages/:id/:experienceId/:slug' />

          <Route element={<Protected><Experiences /></Protected>} path='/experiences/:type?' />

          <Route element={<Protected><ExperienceEntry /></Protected>} path='/experiences/:type/:id' />

          <Route element={<Protected><PlanEntry /></Protected>} path='/experiences/:type/:id/plan/:planId' />

          <Route element={<Protected><Partners /></Protected>} path='/partners' />

          <Route element={<Protected><PartnerEntry /></Protected>} path='/partners/:id' />

          <Route element={<Protected><PartnersPricingGrids /></Protected>} path='/partners-pricing' />

          <Route element={<Protected><PartnersPricingGridEntry /></Protected>} path='/partners-pricing/:id' />

          <Route element={<Protected><Users /></Protected>} path='/users' />

          <Route element={<Protected><UserEntry /></Protected>} path='/users/:id' />

          <Route element={<Protected><Products /></Protected>} path='/products' />

          <Route element={<Protected><ProductEntry /></Protected>} path='/products/:id' />

          <Route element={<Protected><Orders /></Protected>} path='/orders' />

          <Route element={<Protected><Bookings /></Protected>} path='/bookings' />

          <Route element={<Protected><BookingCreate /></Protected>} path='/booking/create' />

          <Route element={<Protected><BookingEntry /></Protected>} path='/bookings/:id' />

          <Route element={<Protected><BookingEdit /></Protected>} path='/bookings/edit/:id' />

          <Route element={<Protected><Artists /></Protected>} path='/artists' />

          <Route element={<Protected><ArtistEntry /></Protected>} path='/artists/:id' />

          <Route element={<Protected><Categories /></Protected>} path='/categories' />

          <Route element={<Protected><Videos /></Protected>} path='/videos' />

          <Route element={<Protected><Videos /></Protected>} path='/videos/:id' />
        </Routes>
      </QueryClientProvider>
    </HelmetProvider>
  )
}

export default App
