import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import { type Breadcrumb } from '@components/breadcrumbs/breadcrumbs-interfaces'
import ContextualButton from '@components/buttons/contextual-button'
import GlobalSearch from '@components/global-search/global-search'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import Table from '@components/table'
import TableEntry from '@components/table/table-entry/table-entry'
import { type ExperiencePlan, type PlanPoint, type PlanPointPostData } from '@interfaces/api/experience/experience-plan'
import { PlanFile } from '@pages/plans/plan-file'
import { ReactComponent as HeartIcon } from '@root/heart.svg'
import useCreateEntrySimple from '@services/api/resources/create-entry-query-simple'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const PlanEntryPage = () => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'plan-points' })
  const { planId = '' } = useParams()
  const location = useLocation()
  const { data: plan } = useApiResourceItemQuery<ExperiencePlan>({ id: planId, path: 'plans/{uid}', refetchOnMount: 'always' })
  const { mutateAsync: updatePoint } = usePatchEntrySimple()
  const { mutateAsync: deletePoint } = useDeleteEntrySimple()
  const { mutateAsync: createPoint } = useCreateEntrySimple({ path: 'plan-points' })

  const [breadcrumbs, setBreadcrumbs] = useState<Breadcrumb[]>([])
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [pointData, setPointData] = useState<PlanPointPostData>()
  const [point, setPoint] = useState<PlanPoint>()
  const [points, setPoints] = useState<PlanPoint[]>([])
  const [draggingPoint, setDraggingPoint] = useState(null)
  const imageRef = useRef<HTMLImageElement>(null)
  const [hoveredPoint, setHoveredPoint] = useState(null)
  const {
    data: {
      data: pointsData
    } = {},
    refetch
  } = useItemChildrenQuery<PlanPoint>({
    itemId: planId,
    path: 'plans/{uid}/points'
  })

  useEffect(() => {
    if (pointsData) {
      setPoints(pointsData)
    }
  }, [pointsData])

  useEffect(() => {
    if (location.state?.breadcrumbs && plan) {
      setBreadcrumbs([
        ...location.state?.breadcrumbs,
        { href: `/experiences/attraction/${plan?.experience.uid}/plans/`, name: `Plan ${plan.name}` }
      ])
    }
  }, [plan])

  const handleMouseEnter = (pointUid) => {
    setHoveredPoint(pointUid)
  }
  const handleMouseLeave = () => {
    setHoveredPoint(null)
  }

  const handleImageClick = async (e) => {
    if (!plan) {
      return
    }

    const rect = e.target.getBoundingClientRect()
    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    setPointData({
      experience: '',
      plan: plan['@id'],
      priority: points.length + 1,
      title: '',
      x: x.toString(),
      y: y.toString()
    })
    setOpen(true)
  }

  const onPointEdit = (planPoint) => {
    setPoint(planPoint)
    setOpenEdit(true)
  }

  const onPointDelete = (planPoint) => {
    setOpenDeleteModal(true)
    setPoint(planPoint)
  }

  const submitPointEdit = async (hit) => {
    const newPointData = Object.assign({}, point, {
      experience: `/api/experiences/${hit.uid}`,
      title: hit.name
    })

    await updatePoint({ data: newPointData, id: newPointData.uid, path: 'plan-points/{uid}' }).then(async () => await refetch())
    setOpenEdit(false)
  }

  const onExperienceSelect = async (hit) => {
    const newPointData = Object.assign({}, pointData, {
      experience: `/api/experiences/${hit.uid}`,
      title: hit.name,
      translatedData: []
    })

    setPointData(newPointData)
    await createPoint({ data: newPointData }).then(async () => await refetch())
    setOpen(false)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
  }

  const onDeleteHandler = () => {
    if (point) {
      deletePoint({ id: point.uid, path: 'plan-points/{uid}' }).then(async () => await refetch()).catch(captureException)
    }
    setOpenDeleteModal(false)
  }

  const handleDragEnd = async () => {
    if (draggingPoint !== null) {
      const updatedPoint = points[draggingPoint]
      setDraggingPoint(null)

      const newPointData = Object.assign({}, updatedPoint, {
        experience: updatedPoint.experience['@id']
      })

      await updatePoint({ data: newPointData, id: updatedPoint.uid, path: 'plan-points/{uid}' })
    }
  }

  const handleDragStart = (e, index) => {
    e.dataTransfer.setData('text/plain', '')
    e.dataTransfer.effectAllowed = 'move'
    setDraggingPoint(index)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  const handleDrag = (e) => {
    if (draggingPoint !== null && e.clientX !== 0 && e.clientY !== 0 && imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect()

      // Calculate the new position
      let x = e.clientX - rect.left
      let y = e.clientY - rect.top

      // Clamp x and y to the rectangle boundaries
      x = Math.max(0, Math.min(x, rect.width))
      y = Math.max(0, Math.min(y, rect.height))

      // Update the points state with the adjusted position
      setPoints((prevPoints) =>
        prevPoints.map((point, index) =>
          index === draggingPoint ? { ...point, x: x.toString(), y: y.toString() } : point
        )
      )
    }
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>

          {plan?.file && (
            <PlanFile planFile={plan.file} />
          )}

          {plan?.image?.metadata && (
            <>
              <div className='lg:flex lg:items-center lg:justify-between gap-6'>

                <div className='min-w-0 flex-1'>
                  <h2 className='text-2xl font-bold leading-7 text-slate-700 sm:truncate sm:text-3xl sm:tracking-tight'>
                    {plan.name}
                  </h2>
                </div>
              </div>

              <div className='flex my-10 space-x-4'>
                <div className='relative' onDragOver={handleDragOver} style={{ height: plan.image.metadata.viewBox.height, width: plan.image.metadata.viewBox.width }}>
                  <img
                    alt='Plan'
                    className='cursor-pointer bg-white border-b border-gray-200 shadow'
                    onClick={handleImageClick}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' || e.key === 'Space') {
                        handleImageClick(e).catch(captureException)
                      }
                    }} ref={imageRef} src={plan.image.url}
                    style={{ height: plan.image.metadata.viewBox.height, width: plan.image.metadata.viewBox.width }}
                  />

                  {points?.map((point, index) => (
                    <button
                      draggable
                      key={index}
                      onClick={() => {
                        onPointEdit(point)
                      }}
                      onDrag={handleDrag}
                      onDragEnd={handleDragEnd}
                      onDragStart={(e) => {
                        handleDragStart(e, index)
                      }}

                      onMouseEnter={() => {
                        handleMouseEnter(point.uid)
                      }}
                      onMouseLeave={handleMouseLeave}
                      style={{
                        cursor: 'pointer',
                        left: `${point.x}px`,
                        position: 'absolute',
                        top: `${point.y}px`,
                        transform: 'translate(-50%, -100%)'
                      }}
                      type='button'
                    >
                      <HeartIcon
                        className={`${hoveredPoint === point.uid ? 'text-blue-500' : 'text-purple-500'}`}
                        height={28}
                        width={32}
                      />
                    </button>
                  ))}
                </div>

                <div className='flex-1 border-b border-gray-200 bg-white rounded-lg shadow'>
                  <div className='px-4 py-5 sm:px-6'>
                    <div className='-ml-4 -mt-4 flex flex-col flex-wrap sm:flex-nowrap'>
                      <div className='ml-4 mt-4'>
                        <h3 className='text-xl font-semibold leading-6 text-slate-700'>Points</h3>
                      </div>

                      <div className='ml-4 mt-4 flex-shrink-0'>

                        <Table
                          headers={[]}
                          isLoading={false}
                          noScroll
                          pageIndex={0}
                          setPageIndex={() => {}}
                          totalItems={3}
                        >
                          {
                            points?.map((planPoint, index) => (
                              <TableEntry key={index}>
                                <td className={`p-4 flex gap-3 items-center justify-between cursor-pointer ${hoveredPoint === planPoint.uid ? 'bg-blue-500 text-white' : 'bg-white'}`}
                                  onMouseEnter={() => {
                                    handleMouseEnter(planPoint.uid)
                                  }}
                                  onMouseLeave={handleMouseLeave}
                                >
                                  {planPoint.experience.name}

                                  <div className='flex space-x-2'>
                                    <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={() => {
                                      onPointEdit(planPoint)
                                    }}
                                    >
                                      <PencilSquareIcon className='w-5 h-5 mx-auto' />
                                    </button>

                                    <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={() => {
                                      onPointDelete(planPoint)
                                    }}
                                    >
                                      <TrashIcon className='w-5 h-5 mx-auto' />
                                    </button>
                                  </div>
                                </td>
                              </TableEntry>
                            ))
                          }
                        </Table>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              <GlobalSearch indexes={['attraction', 'activity', 'artwork', 'course']} isOpened={open} onSelectCallback={onExperienceSelect} setOpened={setOpen} />
              <GlobalSearch indexes={['attraction', 'activity', 'artwork', 'course']} isOpened={openEdit} onSelectCallback={submitPointEdit} setOpened={setOpenEdit} />

              <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
                <div className='flex flex-col items-center p-8'>
                  <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

                  <div className='flex mt-6 gap-8'>
                    <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

                    <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
                  </div>
                </div>
              </Modal>
            </>
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default PlanEntryPage
