import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Fragment, useState } from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'

import { type Language, type LanguageCodes, languages } from '@services/languages'

interface LangSelectorProps {
  onSelect: (lang: LanguageCodes) => void
}
const LangSelector = ({ onSelect }: LangSelectorProps) => {
  const [selected, setSelected] = useState<Language>(languages[0])
  const { t } = useTranslation('apiResources')

  const onChange = (lang: Language) => {
    setSelected(lang)
    onSelect(lang.code)
  }

  return (
    <div>
      <div className='sm:hidden'>

        <Listbox onChange={onChange} value={selected}>
          {({ open }) => (
            <>
              <div className='relative mt-2'>
                <Listbox.Button
                  className='relative w-full flex flex-row items-center cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6'
                >
                  <ReactCountryFlag
                    countryCode={selected.countryCode}
                  />

                  <span className={'font-normal block truncate ml-2'}>
                    {t(`languages.${selected.code}`)}
                  </span>

                  <span className='pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2'>
                    <ChevronUpDownIcon aria-hidden='true' className='h-5 w-5 text-gray-400' />
                  </span>
                </Listbox.Button>

                <Transition
                  as={Fragment}
                  leave='transition ease-in duration-100'
                  leaveFrom='opacity-100'
                  leaveTo='opacity-0'
                  show={open}
                >
                  <Listbox.Options
                    className='absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'
                  >
                    {languages.map((tab) => (
                      <Listbox.Option
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9 flex flex-row items-center'
                          )
                        }
                        key={tab.code}
                        value={tab}
                      >
                        {({ active, selected }) => (
                          <>
                            <ReactCountryFlag
                              countryCode={tab.countryCode}
                            />

                            <span
                              className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate ml-2')}
                            >
                              {t(`languages.${tab.code}`)}
                            </span>

                            {selected
                              ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon aria-hidden='true' className='h-5 w-5' />
                                </span>
                              )
                              : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </>
          )}
        </Listbox>
      </div>

      <div className='hidden sm:flex'>
        <nav aria-label='Tabs' className='isolate divide-x divide-gray-200 rounded-lg shadow'>
          {languages.map((tab, tabIdx) => {
            const current = tab === selected

            return (
              <button
                aria-current={current ? 'page' : undefined}
                className={`group relative min-w-0 overflow-hidden bg-white py-2 px-4 space-x-2 text-center text-sm font-medium hover:bg-gray-50 focus:z-10 ${current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700'}${tabIdx === 0 ? ' rounded-l-lg' : ''}${tabIdx === languages.length - 1 ? ' rounded-r-lg' : ''}`}
                key={tab.code}
                onClick={() => {
                  onChange(tab)
                }}
                type='button'
              >
                <ReactCountryFlag
                  countryCode={tab.countryCode}
                  style={{
                    borderRadius: '4px',
                    height: '15px',
                    objectFit: 'cover',
                    width: '20px'
                  }}
                  svg
                />

                <span>{t(`languages.${tab.code}`)}</span>

                <span
                  aria-hidden='true'
                  className={`absolute inset-x-0 bottom-0 h-0.5 ${selected === tab ? 'bg-primary' : 'bg-transparent'}`}
                />
              </button>
            )
          })}
        </nav>
      </div>
    </div>
  )
}

export default LangSelector
