import { type ApiPlatformEntity } from '@interfaces/api'
import { type Image } from '@interfaces/api/image'

export enum VideoStatus {
  CANCELLED = 'CANCELLED',
  CREATED = 'CREATED',
  FAILED = 'FAILED',
  PUBLISHED = 'PUBLISHED',
  TO_IMPORT = 'TO_IMPORT',
  TRANSCODING = 'TRANSCODING'
}

export interface VideoFormat extends ApiPlatformEntity {
  fps: number
  label: string
  path: string
  size: number
  url: string
}

export interface Video extends ApiPlatformEntity {
  duration: number
  formats: VideoFormat[] | null
  image: Image
  label: string
  originalName: string
  status: VideoStatus
  tag: string
  uid: string
}

export interface TusFile {
  data: File
  mime: string
  name: string
  originalName: string
  uid: string
}

export interface FileUpload {
  '@id': string
  completed: boolean
  data?: File
  expired: boolean
  internalId: number
  mime: string
  name: string
  originalName: string
  size: number
  uid: string
  uploadOffset: number
}
