import {
  useMutation,
  type UseMutationResult
} from '@tanstack/react-query'

import { type ApiError } from '@interfaces/api/api'
import useAxios from '@services/api/axios'

export const usePostFileQuery = (path, id?): UseMutationResult<string, ApiError, FormData> => {
  const { post } = useAxios()

  return useMutation({
    mutationFn: async (file: FormData) => {
      const url = id ? `api/${path.replace('{uid}', id)}` : `api/${path}`

      return await post<FormData, never>(url, file).then((body) => body['@id'])
    }
  })
}
