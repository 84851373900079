import { ListBulletIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type Category } from '@interfaces/api/category/category'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const CategoriesList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'categories' })

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [category, setCategory] = useState<Category>()

  const queryClient = useQueryClient()
  const definition = useApiResource('categories/{uid}')
  const collectionDefinition = useApiResource('categories')

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['categories'] }).catch(captureException)
  }

  const onCreateClick = () => {
    setOpen(true)
  }

  const onEditClick = (entry) => {
    setCategory(entry)
    setOpenEdit(true)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
    setOpen(false)
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={ListBulletIcon} title={translateResource('title')} />

        <ApiResource customFieldsToDisplay={['name']} name={'categories'} onClick={onEditClick} withCreateOption={onCreateClick} withDeleteOption withEditOption={onEditClick} />

        <Modal center open={open} setOpen={setOpen} size='large' title={translateResource('create')}>
          {collectionDefinition && <ApiResourceCreate definition={collectionDefinition} onEdit={onEdit} />}
        </Modal>

        <>
          {category && (
            <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
              {definition && <ApiResourceEdit definition={definition} id={category.uid} onEdit={onEdit} />}
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default CategoriesList
