import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import { type ExperiencesTableTabsProps } from '@components/experiences-table/experiences-table-tabs.interfaces'
import { ExperienceType } from '@interfaces/api/experience'

const ExperiencesTableTabs = ({ currentTab, hasMargin = false, path }: ExperiencesTableTabsProps) => {
  const { t } = useTranslation('apiResources', { keyPrefix: 'experiences' })
  const navigate = useNavigate()

  const tabs = [
    ExperienceType.artwork,
    ExperienceType.course,
    ExperienceType.attraction,
    ExperienceType.activity
  ]

  const onTabSelect = (tab) => {
    navigate(`${path}/${tab}`)
  }

  return (
    <nav aria-label='Tabs' className={`flex space-x-4 mb-4 ${hasMargin ? 'mx-8' : 'mx-2'}`}>
      {tabs.map((tab) => (
        <button
          className={classNames(
            tab === currentTab ? 'bg-gray-900 text-white' : 'text-gray-500 hover:text-gray-700',
            'rounded-md px-3 py-2 text-sm font-medium'
          )}
          key={tab}
          onClick={() => {
            onTabSelect(tab)
          }}
          type={'button'}
        >
          {t(`types.${tab}`)}
        </button>
      ))}
    </nav>
  )
}

export default ExperiencesTableTabs
