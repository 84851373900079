import { type TagProps } from '@components/tag/tag-interfaces'

const Tag = ({ classNames, text }: TagProps) => {
  return (
    <div className='flex justify-start'>
      <div className='flex items-center gap-2 border rounded-md py-1 px-2 bg-white'>
        <div className={`rounded-full h-2 w-2 ${classNames}`} />

        <div className='text-xs text-gray-700'>{text}</div>
      </div>
    </div>
  )
}

export default Tag
