import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type MapFieldsInterfaces
} from '@components/api-resource/api-resource-form/form-fields/map-fields/map-fields.interfaces'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import FormMapField from '@components/form-fields/form-map-field'
import { ExperienceChildType } from '@interfaces/api/course/course'

const MapFields = ({
  definition,
  fieldsToDisplay,
  fieldsToHide,
  formValues,
  requestFields
}: MapFieldsInterfaces) => {
  const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  // Créez un objet pour associer les noms de champs aux composants correspondants
  const fieldComponents = {}

  requestFields.forEach((field, index) => {
    // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
    if (fieldIsHidden(field, fieldsToDisplay, fieldsToHide) ||
      formValues.type === ExperienceChildType.experience) {
      return
    }

    if (field.name === 'geoloc') {
      fieldComponents[field.name] = <FormMapField key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} required={field.required ?? false} />
    }
  })

  // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
  if (fieldsToDisplay) {
    fieldsToDisplay.forEach((fieldName) => {
      if (fieldComponents[fieldName]) {
        fields.push(fieldComponents[fieldName])
      }
    })
  } else {
    Object.keys(fieldComponents).forEach((fieldName) => {
      fields.push(fieldComponents[fieldName])
    })
  }

  return (
    <>
      {fields}
    </>
  )
}

export default MapFields
