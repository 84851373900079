import { type ApiPlatformEntity } from '@interfaces/api'
import { type ExperienceFull } from '@interfaces/api/experience'

export interface Course extends ApiPlatformEntity {
  courseType: CourseType
  hasTabs: boolean
  uid: string
}

export interface CourseTab extends ApiPlatformEntity {
  course: Course
  description: string
  name: string
  priority: number
  title: string
}

export interface CourseTabStep extends ApiPlatformEntity {
  courseTab: CourseTab
  experience: ExperienceFull
  priority: number
}

export enum CourseType {
  map = 'map',
  steps = 'steps',
  virtual = 'virtual'
}

export enum ExperienceChildType {
  experience = 'experience',
  geoloc = 'geoloc'
}
