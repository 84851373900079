import classNames from 'classnames'
import { ErrorMessage, Field, useField } from 'formik'
import { useEffect } from 'react'

import { type FormNumberFieldProps } from '@components/form-fields/form-number-field/index'
import { captureException } from '@services/exceptions/capture-exception'

const FormNumberField = ({ label, min = 0, name, required = false, step = 'any', unitComponent, zeroAllowed = false }: FormNumberFieldProps) => {
  const [input, , helpers] = useField({ name })
  const { setValue } = helpers
  const { value } = input

  useEffect(() => {
    if (value === 0 && !zeroAllowed) {
      setValue(1).catch(captureException)
    }
  }, [value])

  return (
    <div className='flex flex-col text-gray-900 gap-2'>
      <label className='block font-medium text-sm' htmlFor={name}>{label}</label>

      <div className={classNames('flex relative', { 'items-center': unitComponent })}>
        <Field
          className={classNames(
            'text-gray-700 text-center border border-gray-300 bg-gray-50 appearance-none px-3 py-1.5 placeholder-gray-500 focus:outline-none',
            {
              'rounded-lg': !unitComponent,
              'w-1/2 rounded-l-lg': unitComponent
            }
          )} min={min}
          name={name}
          required={required}
          step={step}
          type='number'
        />

        {unitComponent && (
          unitComponent
        )}
      </div>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormNumberField
