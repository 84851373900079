import { TrashIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import ContextualButton from '@components/buttons/contextual-button'
import { type DeleteButtonWithLabelProps } from '@components/buttons/delete-button-with-label/index'

const DeleteButtonWithLabel = ({ onClick }: DeleteButtonWithLabelProps) => {
  const { t } = useTranslation('apiResources')

  return (
    <ContextualButton icon={TrashIcon} onClick={onClick} style='warning'>
      {t('actions.delete')}
    </ContextualButton>
  )
}

export default DeleteButtonWithLabel
