import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'

import ContextualButton from '@components/buttons/contextual-button'
import IconButton from '@components/buttons/icon-button'
import { type FormKeyArrayFieldProps } from '@components/form-fields/form-key-array-field/form-key-array-field.interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const FormKeyArrayField = ({ label, name, options, placeholder, value }: FormKeyArrayFieldProps) => {
  const { setFieldValue } = useFormikContext()

  const initialValue = Object.entries(value ?? {}).map(([key, val]) => ({ agent: key, value: val }))
  const [items, setItems] = useState(initialValue)

  useEffect(() => {
    const newValue = items.reduce((acc, curr) => ({ ...acc, [curr.agent]: curr.value }), {})
    setFieldValue(name, newValue).catch(captureException)
  }, [items, name, setFieldValue])

  const handleAdd = () => {
    if (items.length < options.length) {
      setItems([...items, { agent: 'getyourguide', value: 0 }])
    }
  }

  const handleRemove = (index) => {
    setItems(items.filter((_, idx) => idx !== index))
  }

  const handleChangeAgent = (e, index) => {
    const newList = items.map((item, idx) => idx === index ? { ...item, agent: e.target.value } : item)
    setItems(newList)
  }

  const handleChangeValue = (e, index) => {
    const newList = items.map((item, idx) => idx === index ? { ...item, value: parseInt(e.target.value) } : item)
    setItems(newList)
  }

  return (
    <div className='flex flex-col w-full items-start'>
      <label className='block text-sm font-medium text-gray-700 mb-2'>{label}</label>

      {items.map((item, index) => (
        <div className='flex items-center mb-2' key={index}>
          <select
            className='appearance-none block w-full min-w-1/2 px-3 py-2 border border-gray-300 bg-gray-50 rounded-lg placeholder-gray-300 focus:outline-none mr-2'
            onChange={e => {
              handleChangeAgent(e, index)
            }}
            value={item.agent}
          >
            {options.map(option => (
              <option key={option.key} value={option.key}>{option.label}</option>
            ))}
          </select>

          <input
            className='appearance-none block w-full px-3 py-2 border border-gray-300 bg-gray-50 rounded-lg placeholder-gray-300 focus:outline-none mr-2'
            onChange={e => {
              handleChangeValue(e, index)
            }}
            placeholder={placeholder}
            type='number'
            value={item.value}
          />

          <IconButton
            icon={TrashIcon}
            onClick={() => {
              handleRemove(index)
            }}
            size={4}
            style={'warning'}
          />
        </div>
      ))}

      <ContextualButton
        icon={PlusCircleIcon}
        onClick={handleAdd}
        type='button'
      >
        Ajouter
      </ContextualButton>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormKeyArrayField
