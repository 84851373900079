import { type FormikValues } from 'formik'
import { create } from 'zustand'

interface FormStore {
  forms: Record<string, FormikValues>
  updateForm: <T extends FormikValues>(formName: string, values: T) => void
}

export const useFormStore = create<FormStore>((set) => ({
  forms: {},
  updateForm: (formName, values) => {
    set((state) => ({
      forms: { ...state.forms, [formName]: values }
    }))
  }
}))
