import { CurrencyEuroIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type PartnerPricingGrid } from '@interfaces/api/partner-pricing-grid/partner-pricing-grid'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const PartnersPricingGridsList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'partner-tariff-grids' })

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]
  const navigate = useNavigate()

  const [openEdit, setOpenEdit] = useState(false)
  const [partnerPricingGrid, setPartnerPricingGrid] = useState<PartnerPricingGrid>()
  const [open, setOpen] = useState(false)

  const definition = useApiResource('partner-tariff-grids/{uid}')
  const collectionDefinition = useApiResource('partner-tariff-grids')

  const onEntryClick = (entry) => {
    navigate(`/partners-pricing/${entry.uid}`)
  }

  const queryClient = useQueryClient()

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['partner-tariff-grids'] }).catch(captureException)
  }

  const onEditClick = (pricingGrid) => {
    setPartnerPricingGrid(pricingGrid)
    setOpenEdit(true)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
    setOpen(false)
  }

  const onCreateClick = () => {
    setOpen(true)
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={CurrencyEuroIcon} title={translateResource('title')} />

        <ApiResource createdAt={'desc'} name={'partner-tariff-grids'} onClick={onEntryClick} withCreateOption={onCreateClick} withEditOption={onEditClick} />

        <>
          {partnerPricingGrid && definition && (
            <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
              <ApiResourceEdit definition={definition} id={partnerPricingGrid.uid} onEdit={onEdit} />
            </Modal>
          )}
        </>

        <Modal center open={open} setOpen={setOpen} size='large' title={translateResource('create')}>
          {collectionDefinition && <ApiResourceCreate definition={collectionDefinition} onEdit={onEdit} />}
        </Modal>
      </Guard>
    </Layout>
  )
}

export default PartnersPricingGridsList
