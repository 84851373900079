import { ErrorMessage, Field } from 'formik'

import { type FormTextAreaFieldProps } from '@components/form-fields/form-textarea-field/form-textarea-field-interfaces'

const FormTextAreaField = ({ label, name, required = false }: FormTextAreaFieldProps) => {
  return (
    <div className='flex flex-col'>
      <label className='block font-medium text-gray-900 text-sm' htmlFor={name}>
        {label}

        {required && <span className='ml-2 text-sm font-normal'>*</span>}
      </label>

      <Field
        as='textarea'
        className='appearance-none block w-full mt-2 px-3 h-40 py-2.5 border border-gray-300 bg-gray-50 rounded-lg placeholder-gray-500 focus:outline-none'
        name={name}
        required={required}
        type='textarea'
      />

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormTextAreaField
