import { type ApiPlatformEntity } from '@interfaces/api'
import { type Experience, type ExperienceFull } from '@interfaces/api/experience'
import { type File } from '@interfaces/api/file'
import { type Image } from '@interfaces/api/image'

export interface ExperiencePlan extends ApiPlatformEntity {
  experience: ExperienceFull
  file?: File
  image?: Image
  name: string
  priority: number
  type: AttractionPlanType
  uid: string
}

export interface PlanPoint extends ApiPlatformEntity {
  experience: Experience
  plan: ExperiencePlan
  priority: number
  title: string
  x: string
  y: string
}

export interface PlanPointPostData {
  experience: string
  plan: string
  priority: number
  title: string
  x: string
  y: string
}

export enum AttractionPlanType {
  FILE = 'file',
  IMAGE = 'image',
  MAP = 'map'
}
