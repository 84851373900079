import { RadioGroup } from '@headlessui/react'
import { ErrorMessage, useField } from 'formik'
import { useTranslation } from 'react-i18next'

import { type FormRadioFieldProps } from '@components/form-fields/form-radio-field/form-radio-field.interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const FormikRadioGroup = ({ label, name, options, required = false }: FormRadioFieldProps) => {
  const { t } = useTranslation()
  const [{ value }, , { setValue }] = useField(name)

  const handleChange = (value) => {
    try {
      setValue(value).catch(captureException)
    } catch (error) {
      captureException(error as Error)
    }
  }

  return (
    <div className='flex flex-col'>
      <label className='block font-medium text-gray-700'>{t(label)}</label>

      <RadioGroup className='mt-2 flex overflow-x-scroll space-x-2 scrollbar-hide' onChange={handleChange} value={value}>
        {options.map((option) => (
          <RadioGroup.Option
            className={({ active, checked }) =>
              `${active ? '' : ''}
               ${checked ? 'border-2 border-primary' : 'bg-white'}
               relative flex cursor-pointer rounded-lg border border-gray-300 p-4 shadow-sm focus:outline-none`
            }
            key={option.key}
            value={option.key}
          >
            {({ checked }) => (
              <>
                <div className='flex flex-1'>
                  <div className='flex flex-col'>
                    <RadioGroup.Label as='span' className='block text-sm font-medium mb-3'>
                      {option.name}
                    </RadioGroup.Label>

                    {option.image && (
                      <RadioGroup.Description as='div' className='text-sm text-gray-500 w-[200px]'>
                        <img alt={option.name} src={option.image} width={200} />
                      </RadioGroup.Description>
                    )}
                  </div>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </RadioGroup>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormikRadioGroup
