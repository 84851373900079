import { create } from 'zustand'

interface ScheduleInputStore {
  inputIsInvalid: boolean
  setInputIsInvalid: (isInvalid: boolean) => void
}

export const useScheduleInputStore = create<ScheduleInputStore>((set) => ({
  inputIsInvalid: false,
  setInputIsInvalid: (inputIsInvalid) => {
    set({ inputIsInvalid })
  }
}))
