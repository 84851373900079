import { TrashIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ContextualButton from '@components/buttons/contextual-button'
import EmptyStateButton from '@components/empty-state-button/empty-state-button'
import GlobalSearch from '@components/global-search/global-search'
import { type ProductExperiencesProps } from '@components/products/product-experiences/product-experiences.interfaces'
import { type ExperienceFull, getExperienceIcon } from '@interfaces/api/experience'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

const ProductExperiences = ({ id }: ProductExperiencesProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const [open, setOpen] = useState(false)
  const [experiences, setExperiences] = useState<ExperienceFull[]>([])
  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()

  const {
    data: {
      data: productExperiences
    } = {},
    refetch
  } = useItemChildrenQuery<ExperienceFull>({
    itemId: id,
    path: 'products/{uid}/experiences'
  })

  useEffect(() => {
    if (productExperiences) {
      setExperiences(productExperiences)
    }
  }, [productExperiences])

  const onAddExperienceClick = () => {
    setOpen(true)
  }

  const onExperienceSelected = (hit) => {
    const experienceIds = experiences.map(exp => exp['@id'])
    experienceIds.push(`/api/experiences/${hit.uid}`)

    patchResourceEntry({
      data: {
        experiences: experienceIds
      },
      id,
      path: 'products/{uid}/experiences'
    }).then(() => {
      refetch().catch(captureException)
      setOpen(false)
    }).catch(captureException)
  }

  const deleteExperience = (experience: ExperienceFull) => {
    const updatedExperiences = experiences.filter(exp => exp.uid !== experience.uid)
    const experienceIds = updatedExperiences.map(exp => exp['@id'])

    patchResourceEntry({
      data: {
        experiences: experienceIds
      },
      id,
      path: 'products/{uid}/experiences'
    }).then(() => {
      setExperiences(updatedExperiences)
      setOpen(false)
    }).catch(captureException)
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Expériences débloquées à l'achat</h3>
            </div>

            <div className='ml-4 mt-4 flex-shrink-0'>
              <ContextualButton onClick={onAddExperienceClick}>
                {translateActions('addExperienceChild')}
              </ContextualButton>
            </div>
          </div>
        </div>

        {experiences && experiences.length > 0
          ? (
            <div className='w-screen sm:w-auto'>
              <div className={'min-w-min w-full table-wrp block'}>
                <table className='w-full divide-y'>
                  <thead className='bg-gray-50 text-gray-900 sticky top-0'>
                    <tr>
                      <th className='p-4 text-left'>
                        <div className='flex items-center text-base font-medium'>
                          Nom
                        </div>
                      </th>

                      <th />
                    </tr>
                  </thead>

                  <tbody>
                    {experiences.map((experience, index) => {
                      const Icon = getExperienceIcon(experience.type)

                      return (
                        <tr
                          className='flex bg-white hover:bg-gray-100 cursor-pointer'
                          key={experience.uid}
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          <td className='p-4 flex-grow'>
                            <span className='flex space-x-2'>
                              <Icon
                                aria-hidden='true'
                                className={'h-6 w-6 flex-none'}
                              />

                              <span>{experience.name}</span>
                            </span>
                          </td>

                          <td className='p-4'>
                            <button
                              className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
                              onClick={(e) => {
                                e.stopPropagation()
                                deleteExperience(experience)
                              }}
                            >
                              <TrashIcon className='w-5 h-5 mx-auto' />
                            </button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )
          : (
            <div className='py-4 pb-8 px-4 w-full'>
              <EmptyStateButton onClick={onAddExperienceClick} translateKey={'experienceChildren'} />
            </div>
          )}
      </div>

      <GlobalSearch indexes={['attraction']} isOpened={open} onSelectCallback={onExperienceSelected} setOpened={setOpen} />
    </>
  )
}

export default ProductExperiences
