import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import axios from 'axios'

import { getSearchToken } from '@services/api/axios'
import { useAuthToken, useMeiliToken } from '@services/hooks/auth-token'

interface MeiliSearchMultiSearchParameters {
  enabled: boolean
  queries: Array<{
    indexUid: string
    q: string
  }>
}

const useMeiliSearchMultiQuery = ({ enabled = false, queries }: MeiliSearchMultiSearchParameters): UseQueryResult<never> => {
  const { get: getMeiliToken, getUrl, set: setMeiliToken } = useMeiliToken()
  const { get: getToken } = useAuthToken()
  const queryKey = ['meilisearch', 'multi-search', queries]

  const queryFn = async () => {
    try {
      const response = await axios.post(`${getUrl()}/multi-search`, { queries }, {
        headers: {
          Authorization: `Bearer ${getMeiliToken()}`,
          'Content-Type': 'application/json'
        }
      })

      return response.data.results
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 403) {
        const { endpointUrl, searchToken } = await getSearchToken(`Bearer ${getToken()}`)
        if (searchToken && endpointUrl) {
          setMeiliToken(searchToken, endpointUrl)

          const response = await axios.post(`${endpointUrl}/multi-search`, { queries }, {
            headers: {
              Authorization: `Bearer ${searchToken}`,
              'Content-Type': 'application/json'
            }
          })

          return response.data.results
        } else {
          throw new Error('Impossible d\'obtenir un nouveau token ou URL.')
        }
      }

      throw error
    }
  }

  const staleTime = 5 * 60 * 1000

  return useQuery({
    enabled,
    queryFn,
    queryKey,
    staleTime
  })
}

export default useMeiliSearchMultiQuery
