import { type UseMutateAsyncFunction } from '@tanstack/react-query'

import { type ApiResourceCreateProps } from '@components/api-resource/api-resource-create/index'
import ApiResourceForm, { ApiResourceFormPropsMethod } from '@components/api-resource/api-resource-form'
import { type ApiPlatformEntity } from '@interfaces/api'
import useCreateEntry from '@services/api/resources/create-entry-query'

const ApiResourceCreate = <T extends ApiPlatformEntity>({ afterSubmit, beforeSubmit, children, customAttributes, data, definition, disableNotification = false, extraFields, fieldsToDisplay, fieldsToHide, onEdit, submit }: ApiResourceCreateProps<T>) => {
  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: definition.url })

  const onSubmit: UseMutateAsyncFunction<unknown, Error, unknown, unknown> = async (data) => {
    if (beforeSubmit) {
      data = await beforeSubmit(data)
    }
    let result: unknown = []
    if (submit) {
      result = await submit(data)
    } else {
      result = await createResourceEntry(data)
    }

    // @ts-expect-error result is generic but not typed correctly, if you have time, please fix this.
    onEdit?.(result?.uid)

    if (afterSubmit) {
      await afterSubmit(result, data)
    }

    return result
  }

  return (
    <ApiResourceForm
      children={children}
      createMode
      customAttributes={customAttributes}
      data={data}
      definition={definition}
      disableNotification={disableNotification}
      excludedFields={Object.keys(data ?? {}).filter(key => !!data?.[key])}
      fieldsToDisplay={fieldsToDisplay}
      fieldsToHide={fieldsToHide}
      method={ApiResourceFormPropsMethod.POST}
      requestFields={definition.methods.post.getRequestFields()}
      submitEntry={onSubmit}
    />
  )
}

export default ApiResourceCreate
