import React from 'react'

import { type IconButtonProps } from '@components/buttons/icon-button/index'

const IconButton = ({ classNames, disabled = false, icon: Icon, noBorder = false, onClick, size = 3.5, style }: IconButtonProps) => {
  let theme = 'hover:border-gray-900 hover:bg-gray-800'
  switch (style) {
    case 'warning' : theme = 'hover:border-red-500 hover:bg-red-500'; break
    case 'positive' : theme = 'hover:border-green-900 hover:bg-green-700'; break
    default: break
  }
  const disabledStyles = disabled ? 'opacity-30' : ''
  const styles = `flex items-center rounded-md hover:fill-white hover:text-white p-1 ${noBorder ? 'border border-gray-200' : ''} ${theme} ${disabledStyles} ${classNames}`

  const onButtonClick = (e) => {
    e.stopPropagation()
    onClick()
  }

  return (
    <button
      className={styles}
      disabled={disabled}
      onClick={onButtonClick}
    >
      <Icon className={`flex-none w-${size} h-${size}`} />
    </button>
  )
}

export default IconButton
