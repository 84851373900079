import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  type CustomAttributesFieldsInterfaces
} from '@components/api-resource/api-resource-form/form-fields/custom-attributes-fields'
import FormToggleField from '@components/form-fields/form-toggle-field'

const CustomAttributesFields = ({
  customAttributes
}: CustomAttributesFieldsInterfaces) => {
  const { t } = useTranslation('apiResources')

  // If there are no custom attributes, return null to render nothing
  if (!customAttributes) {
    return null
  }

  // Map over customAttributes to create FormToggleField components
  return (
    <>
      {customAttributes.map((attribute) => {
        const label = attribute.includes('show')
          ? t(`labels.customData.${attribute}`)
          : `${t('labels.customData.boolean')} ${t(`labels.${attribute}`)}`

        return (
          <FormToggleField
            key={`customData.${attribute}`}
            label={label}
            name={`customData.${attribute}`}
          />
        )
      })}
    </>
  )
}

export default CustomAttributesFields
