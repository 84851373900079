import { ErrorMessage, Field, useField } from 'formik'
import { useEffect, useState } from 'react'
import TimezoneSelect from 'react-timezone-select'

import { type FormTimezoneFieldProps } from '@components/form-fields/form-timezone-field/form-timezone-field-interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const FormTimezoneField = ({ label, name, required = false }: FormTimezoneFieldProps) => {
  const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)

  const [field, , helpers] = useField({ name })
  const { setValue } = helpers

  const onChange = (timezone) => {
    setSelectedTimezone(timezone.value)
    setValue(timezone.value).catch(captureException)
  }

  useEffect(() => {
    if (!field.value) {
      setValue(selectedTimezone).catch(captureException)
    } else {
      setSelectedTimezone(field.value)
    }
  }, [field.value])

  return (
    <div className='flex flex-col'>
      <label className='block font-medium text-gray-700 mb-2' htmlFor={name}>{label}</label>

      <Field
        name={name}
        required={required}
        type='hidden'
      />

      <TimezoneSelect
        onChange={onChange}
        value={selectedTimezone}
      />

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormTimezoneField
