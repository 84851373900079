import { type SkeletonLoaderProps } from '@components/skeleton-loader/skeleton-loader-interfaces'

const SkeletonLoader = ({ type }: SkeletonLoaderProps) => (
  <>
    {type === 'form' && (
      <div className='flex flex-col gap-4'>
        {Array.from({ length: 4 }).map((_, index) => (
          <div className='max-w-sm animate-pulse' key={index} role='status'>
            <div className='w-[200px] h-[20px] bg-gray-200 rounded-lg dark:bg-gray-300 mb-4' />

            <div className='w-[300px] h-[40px] bg-gray-200 rounded-lg dark:bg-gray-300' />
          </div>
        ))}
      </div>
    )}

    {type === 'section-row' && (
      <div className='flex gap-1'>
        {Array.from({ length: 6 }).map((_, index) => (
          <div className='max-w-sm gap-1 px-2 py-10 animate-pulse' key={index} role='status'>
            <div className='w-[200px] h-[145px] bg-gray-200 rounded-lg dark:bg-gray-300' />
          </div>
        ))}
      </div>
    )}

    {type === 'card' && (
      <div className='flex gap-1'>
        {Array.from({ length: 6 }).map((_, index) => (
          <div className='max-w-sm animate-pulse' key={index} role='status'>
            <div className='w-[250px] h-[145px] bg-gray-200 rounded-lg dark:bg-gray-300' />
          </div>
        ))}
      </div>
    )}

    {type === 'content' && (
      <div className='max-w-sm flex flex-row items-center justify-center h-full gap-3 animate-pulse py-2' role='status'>
        <div className='w-[52px] h-[52px] bg-gray-200 dark:bg-gray-300 rounded-full' />

        <div className='flex flex-col gap-1'>
          <div className='h-4 bg-gray-200  dark:bg-gray-300 rounded-md w-36 ' />

          <div className='w-24 h-4 bg-gray-200  dark:bg-gray-300 rounded-md ' />
        </div>
      </div>
    )}

    {type === 'meal' && (
      <div className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-1 items-start animate-pulse'>
        <div className='bg-gray-200 h-40 dark:bg-gray-300 rounded-lg' />

        <div className='bg-gray-200 h-40 dark:bg-gray-300 rounded-lg' />

        <div className='bg-gray-200 h-40 dark:bg-gray-300 rounded-lg' />

        <div className='bg-gray-200 h-40 dark:bg-gray-300 rounded-lg' />
      </div>
    )}
  </>

)

export default SkeletonLoader
