import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type TranslatedFieldsInterfaces } from '@components/api-resource/api-resource-form/form-fields/translated-fields'
import FormArrayField from '@components/form-fields/form-array-field/form-array-field'
import FormTextField from '@components/form-fields/form-text-field'
import FormTextAreaField from '@components/form-fields/form-textarea-field'
import LangSwitcher from '@components/lang-switcher/lang-switcher'
import { LanguageCodes } from '@services/languages'

const TranslatedFields = ({ initialValues, translatedDataFieldAttributes }: TranslatedFieldsInterfaces) => {
  const { t } = useTranslation('apiResources')

  const [
    translatedDataLanguage,
    setTranslatedDataLanguage
  ] = useState<LanguageCodes>(LanguageCodes.en)

  if (!translatedDataFieldAttributes.length) {
    return null
  }

  return (
    <div className='flex flex-col gap-3 border-t border-gray-900/10 mt-4 pt-4'>
      <h2 className='text-base font-semibold leading-7 text-gray-900'>{t('labels.translatedData')}</h2>

      <LangSwitcher
        setTranslatedDataLanguage={setTranslatedDataLanguage}
        translatedDataLanguage={translatedDataLanguage}
      />

      <div className='flex flex-col gap-3 w-full'>
        {translatedDataFieldAttributes.map((translatedDataFieldAttribute) => {
          const field = translatedDataFieldAttribute[translatedDataLanguage]

          if (field.format === 'textarea') {
            return (
              <FormTextAreaField
                key={`${field.fieldName}.${translatedDataLanguage}.${field.name}`}
                label={field.label}
                name={`${field.fieldName}.${translatedDataLanguage}.${field.name}`}
              />
            )
          }

          if (field.name === 'includedList' || field.name === 'notIncludedList') {
            const value = initialValues[field.fieldName]?.[translatedDataLanguage]?.[field.name]
              ? initialValues[field.fieldName][translatedDataLanguage][field.name]
              : []

            return (
              <FormArrayField
                key={`${field.fieldName}.${translatedDataLanguage}.${field.name}`}
                label={field.label}
                name={`${field.fieldName}.${translatedDataLanguage}.${field.name}`}
                value={value}
              />
            )
          }

          return (
            <FormTextField
              key={`${field.fieldName}.${translatedDataLanguage}.${field.name}`}
              label={field.label}
              name={`${field.fieldName}.${translatedDataLanguage}.${field.name}`}
            />
          )
        })}
      </div>
    </div>
  )
}

export default TranslatedFields
