import { pluralize } from 'inflection'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import { type Breadcrumb } from '@components/breadcrumbs/breadcrumbs-interfaces'
import ContextualButton from '@components/buttons/contextual-button'
import EntryVideos from '@components/entry-videos/entry-videos'
import ExperienceChildren from '@components/experiences/experience-children/experience-children'
import ExperienceCourseSteps from '@components/experiences/experience-course-steps/experience-course-steps'
import ExperienceCourseTabs from '@components/experiences/experience-course-tabs/experience-course-tabs'
import ExperiencePages from '@components/experiences/experience-pages/experience-pages'
import ExperiencePlans from '@components/experiences/experience-plans/experience-plans'
import ExperienceProducts from '@components/experiences/experience-products/experience-products'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import { NotificationType } from '@components/notification/notification.interfaces'
import { type Course, CourseType } from '@interfaces/api/course/course'
import { type Experience, ExperienceType } from '@interfaces/api/experience'
import useApiResource from '@services/api/definition'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useDeleteEntry from '@services/api/resources/delete-entry-query'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useFormStore } from '@services/stores/forms/forms'
import { useNotificationStore } from '@services/stores/notification/notification'

const ExperiencePage = () => {
  const { id = '', type } = useParams()
  const location = useLocation()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const navigate = useNavigate()

  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'experiences' })
  const { t: translateCities } = useTranslation('apiResources', { keyPrefix: 'cities' })
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })

  const { data: experience } = useApiResourceItemQuery<Experience>({ id, path: 'experiences/{uid}', refetchOnMount: 'always' })
  const { data: subExperience } = useApiResourceItemQuery<Experience | Course>({ id, path: `${pluralize(type as string)}/{uid}`, refetchOnMount: 'always' })
  const definition = useApiResource(`${pluralize(type as string)}/{uid}`)
  const { mutateAsync: createVideoEntry } = useCreateEntry({ path: 'experience-videos' })
  const { mutateAsync: deleteExperience } = useDeleteEntry({ id, path: `${pluralize(type as string)}/{uid}` })

  const { displayNotification } = useNotificationStore()

  const breadcrumbs: Breadcrumb[] = []

  const formValues = useFormStore((state) => state.forms[pluralize(type as string)])

  const editFromCity = !!location.state?.link

  if (editFromCity) {
    breadcrumbs.push({ href: '/cities', name: translateCities('title') })
  }

  breadcrumbs.push(
    { href: location.state?.link ?? '/experiences', name: location.state?.label ?? translateResource('title') },
    { href: `/experiences/${experience?.type}/${experience?.uid}`, name: experience?.name ?? '' }
  )

  const beforeEdit = (data) => {
    if (data.attractionType === '') {
      data.attractionType = null
    }

    return data
  }

  const onVideoSelect = (video, language, reload, setOpen) => {
    createVideoEntry({
      experience: `/api/experiences/${id}`,
      lang: language,
      video: `/api/videos/${video.uid}`
    }).then(() => {
      reload()
      setOpen(false)
    }).catch(captureException)
  }

  const renderCourse = (course: Course) => {
    if (course.hasTabs || (formValues?.hasTabs)) {
      return <ExperienceCourseTabs course={course} />
    }
    if (formValues && (formValues.courseType === CourseType.map || formValues.courseType === CourseType.virtual)) {
      return <ExperienceChildren id={course.uid} />
    } else if (formValues && formValues.courseType === CourseType.steps) {
      return <ExperienceCourseSteps course={course} />
    }
  }

  const onDeleteClick = () => {
    setOpenDeleteModal(true)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
  }

  const onDeleteHandler = () => {
    deleteExperience({}).then(() => {
      setOpenDeleteModal(false)
      displayNotification(translateResource('message.delete.success.title'), translateResource('message.delete.success.description'), NotificationType.success)
      navigate(location.state?.link ?? '/experiences')
    }).catch(captureException)
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} ${experience?.name ? `- ${experience.name}` : ''}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8'>
          <div className='flex justify-end'>

            <ContextualButton onClick={onDeleteClick} style='warning'>
              Supprimer
            </ContextualButton>

          </div>

          <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-6'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>

                <div className='ml-4 mt-4'>
                  <h3 className='text-xl font-semibold leading-6 text-slate-700'>{translateResource(`types.${type}`)}</h3>
                </div>
              </div>
            </div>

            {experience && (
              <>
                {definition && <ApiResourceEdit beforeEdit={beforeEdit} definition={definition} excludedFields={editFromCity ? ['city'] : []} id={id} />}
              </>
            )}
          </div>

          {experience && (
            <EntryVideos entityName={'experience'} id={id} onVideoSelect={onVideoSelect} />
          )}

          <div className='grid grid-cols-2 gap-10'>
            {experience && experience.type === ExperienceType.attraction && (
              <ExperiencePages breadcrumbs={breadcrumbs} experience={experience} id={id} />
            )}

            {experience && experience.type === ExperienceType.attraction && (
              <ExperiencePlans breadcrumbs={breadcrumbs} experience={experience} id={id} />
            )}
          </div>

          {experience && experience.type === ExperienceType.course && subExperience && renderCourse(subExperience as Course)}

          {experience && experience.type === ExperienceType.activity && (
            <ExperienceProducts breadcrumbs={breadcrumbs} id={id} />
          )}
        </div>

        <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
          <div className='flex flex-col items-center p-8'>
            <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

            <div className='flex mt-6 gap-8'>
              <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

              <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
            </div>
          </div>
        </Modal>
      </Guard>
    </Layout>
  )
}

export default ExperiencePage
