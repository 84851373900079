import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import React from 'react'

import { type ApiResourceOrderProps } from '@components/api-resource/api-resource-order/index'
import IconButton from '@components/buttons/icon-button'

const ApiResourceOrder = ({ onReorder, position, totalItems }: ApiResourceOrderProps) => {
  const canGoUp = position > 0
  const canGoDown = position <= totalItems - 2

  return (
    <div className='flex gap-1'>
      <IconButton
        disabled={!canGoDown}
        icon={ChevronDownIcon}
        onClick={() => {
          onReorder(1)
        }}
      />

      <IconButton
        disabled={!canGoUp}
        icon={ChevronUpIcon}
        onClick={() => {
          onReorder(-1)
        }}
      />
    </div>
  )
}

export default ApiResourceOrder
