import { UsersIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import ApiResource from '@components/api-resource'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import Modal from '@components/modals/modal'
import SectionTitle from '@components/section-title/section-title'
import { type Partner } from '@interfaces/api/partner/partner'
import useApiResource from '@services/api/definition'
import { captureException } from '@services/exceptions/capture-exception'

const PartnersList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'partners' })

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const [openEdit, setOpenEdit] = useState(false)
  const definition = useApiResource('partners/{uid}')
  const [partner, setPartner] = useState<Partner>()

  const queryClient = useQueryClient()

  const onReload = () => {
    queryClient.refetchQueries({ queryKey: ['partners'] }).catch(captureException)
  }

  const onEditClick = (partner) => {
    setPartner(partner)
    setOpenEdit(true)
  }

  const onEdit = () => {
    onReload()
    setOpenEdit(false)
  }

  const beforeEdit = (entry) => {
    if (entry.disabledReason === '') {
      entry.disabledReason = null
    }

    return entry
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={UsersIcon} title={translateResource('title')} />

        <ApiResource createdAt={'desc'} customFieldsToDisplay={['companyName', 'email', 'validated', 'createdAt', 'lastLoginAt']} name={'partners'} onClick={onEditClick} withEditOption={onEditClick} />

        <>
          {partner && definition && (
            <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
              <ApiResourceEdit beforeEdit={beforeEdit} definition={definition} id={partner.uid} onEdit={onEdit} />
            </Modal>
          )}
        </>
      </Guard>
    </Layout>
  )
}

export default PartnersList
