import { PlayIcon, TrashIcon } from '@heroicons/react/24/outline'

import ImageEmptyState from '@components/image-empty-state/image-empty-state'
import { type VideoThumbnailProps } from '@components/video/video-thumbnail/video-thumbnail.interfaces'

const VideoThumbnail = ({ isActive = false, onClick, onDeleteClick, video }: VideoThumbnailProps) => {
  const onVideoClick = () => {
    onClick(video)
  }

  const onVideoTrash = (e) => {
    e.stopPropagation()
    onDeleteClick()
  }

  return (
    <div
      className={`group rounded-lg shadow-lg p-4 w-[300px] ${isActive ? 'bg-slate-300' : 'bg-white'}`}
      onClick={onVideoClick}
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          onVideoClick()
        }
      }}
      role='button'
      tabIndex={0}
    >
      <div className='relative'>
        {video.image
          ? (
            <>
              <img
                alt={video.originalName}
                className='rounded-t-lg'
                src={video.image.url}
              />
              <div
                className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white text-black p-2 rounded-full transition duration-300'
              >
                <PlayIcon className='h-6 w-6' />
              </div>
            </>
          )
          : <ImageEmptyState />}

        <button
          className='absolute top-2 right-2 bg-red-500 text-white p-2 rounded-full cursor-pointer opacity-0 group-hover:opacity-100'
          onClick={onVideoTrash}
        >
          <TrashIcon className='h-4 w-4' />
        </button>
      </div>

      <div className='py-4'>
        <div className='text-xs text-left break-words'>{video.label}</div>
      </div>
    </div>
  )
}

export default VideoThumbnail
