import { type ApiPlatformEntity, type ApiPlatformListResponse } from '@interfaces/api'
import { type ExperienceFull } from '@interfaces/api/experience'
import { type Page } from '@interfaces/api/page'

export interface ExperiencePage extends ApiPlatformEntity {
  experience: ExperienceFull
  page: Page
  type: AttractionPageType
  uid: string
}

export enum AttractionPageType {
  ARTISTS = 'artists',
  ARTWORKS = 'artworks',
  DEFAULT = 'default',
  DETAIL_FREE = 'detail_free',
  DISCOVER = 'discover',
  VISIT = 'visit'
}

export type ExperiencePagesResponse = ApiPlatformListResponse<ExperiencePage>
