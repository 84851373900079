import { PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ErrorMessage, Field, useFormikContext } from 'formik'
import type React from 'react'
import { useState } from 'react'

import ContextualButton from '@components/buttons/contextual-button'
import IconButton from '@components/buttons/icon-button'
import { type FormArrayFieldProps } from '@components/form-fields/form-array-field/form-array-field.interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const FormArrayField = ({ label, name, placeholder, value }: FormArrayFieldProps) => {
  const { setFieldValue } = useFormikContext<unknown>()

  const [stringList, setStringList] = useState(value ?? [])

  const handleAdd = () => {
    const newList = [...stringList, '']
    setStringList(newList)
    setFieldValue(name, newList).catch(captureException)
  }

  const handleRemove = (index: number) => {
    const newList = stringList.filter((_: string, idx: number) => idx !== index)
    setStringList(newList)
    setFieldValue(name, newList).catch(captureException)
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const newList = stringList.map((item: string, idx: number) => {
      if (idx === index) {
        return e.target.value
      }

      return item
    })
    setStringList(newList)
    setFieldValue(name, newList).catch(captureException)
  }

  return (
    <div className='flex flex-col w-full'>
      <label className='block font-medium text-gray-700 mb-2'>
        {label}
      </label>

      {stringList.map((string: string, index: number) => (
        <div className='flex items-center mb-2' key={index}>
          <Field
            className='appearance-none block w-full px-3 py-2 border border-gray-300 bg-gray-50 rounded-lg placeholder-gray-300 focus:outline-none mr-2'
            name={`${name}[${index}]`}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleChange(e, index)
            }}
            placeholder={placeholder}
            value={string}
          />

          {stringList.length > 0 && (
            <IconButton
              icon={TrashIcon}
              onClick={() => {
                handleRemove(index)
              }}
              size={4}
              style={'warning'}
            />
          )}
        </div>
      ))}

      <div>
        <ContextualButton
          icon={PlusCircleIcon}
          onClick={handleAdd}
        >
          Ajouter
        </ContextualButton>
      </div>

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormArrayField
