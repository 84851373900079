import { PencilSquareIcon, PlusCircleIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ContextualButton from '@components/buttons/contextual-button'
import { type ExperiencePlansProps } from '@components/experiences/experience-plans/experience-plans.interfaces'
import Modal from '@components/modals/modal'
import Table from '@components/table'
import TableEntry from '@components/table/table-entry/table-entry'
import { AttractionPlanType, type ExperiencePlan } from '@interfaces/api/experience/experience-plan'
import useApiResource from '@services/api/definition'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import { captureException } from '@services/exceptions/capture-exception'

const ExperiencePlans = ({ breadcrumbs, experience, id }: ExperiencePlansProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'plans' })
  const navigate = useNavigate()
  const collectionDefinition = useApiResource('plans')
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [plan, setPlan] = useState<ExperiencePlan>()
  const definition = useApiResource('plans/{uid}')
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const { mutateAsync: deletePlan } = useDeleteEntrySimple()

  const {
    data: {
      data: plans
    } = {},
    refetch
  } = useItemChildrenQuery<ExperiencePlan>({
    itemId: id,
    path: 'experiences/{uid}/plans'
  })

  const onClick = (plan) => {
    setPlan(plan)
    setOpenEdit(true)
  }

  const onDeleteClick = (plan) => {
    setOpenDeleteModal(true)
    setPlan(plan)
  }

  const onDeleteHandler = () => {
    if (plan) {
      deletePlan({ id: plan.uid, path: 'plans/{uid}' }).then(async () => await refetch()).catch(captureException)
    }
    setOpenDeleteModal(false)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
  }

  const onRowClick = (plan) => {
    navigate(`/experiences/${experience.type}/${experience.uid}/plan/${plan.uid}`, { state: { breadcrumbs } })
  }

  const onAddClick = () => {
    setOpen(true)
  }

  const onCreate = async () => {
    await refetch()
    setOpen(false)
  }
  const onEdit = async () => {
    await refetch()
    setOpenEdit(false)
  }

  const beforeSubmit = (data) => {
    data.experience = experience['@id']
    data.geoloc = null
    data.type = AttractionPlanType.FILE

    return data
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Plans</h3>
            </div>

            <div className='ml-4 mt-4 flex-shrink-0'>
              <ContextualButton onClick={onAddClick}>
                <span>Ajouter un plan</span>

                <PlusCircleIcon className='h-5 w-5 text-white' />
              </ContextualButton>
            </div>
          </div>
        </div>

        <Table
          headers={[]}
          isLoading={false}
          pageIndex={0}
          setPageIndex={() => {}}
          totalItems={3}
        >
          {
            plans?.map((experiencePlan, index) => (
              <TableEntry key={index}
                onClick={(e) => {
                  e.stopPropagation()
                  onRowClick(experiencePlan)
                }}
              >
                <td className='p-4 flex gap-3 items-center justify-between cursor-pointer'>
                  {experiencePlan.name}

                  <div className='flex space-x-2'>
                    <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={(e) => {
                      e.stopPropagation()
                      onClick(experiencePlan)
                    }}
                    >
                      <PencilSquareIcon className='w-5 h-5 mx-auto' />
                    </button>

                    <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={(e) => {
                      e.stopPropagation()
                      onDeleteClick(experiencePlan)
                    }}
                    >
                      <TrashIcon className='w-5 h-5 mx-auto' />
                    </button>
                  </div>
                </td>
              </TableEntry>
            ))
          }
        </Table>
      </div>

      <Modal center open={open} setOpen={setOpen} size='large' title={translateResource('create')}>
        {collectionDefinition && <ApiResourceCreate beforeSubmit={beforeSubmit} definition={collectionDefinition} fieldsToDisplay={['name', 'priority']} onEdit={onCreate} />}
      </Modal>

      {plan && (
        <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
          {definition && <ApiResourceEdit beforeEdit={beforeSubmit} definition={definition} fieldsToDisplay={['name', 'priority']} id={plan.uid} onEdit={onEdit} />}
        </Modal>
      )}

      <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

            <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ExperiencePlans
