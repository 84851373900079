import { TrashIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import ContextualButton from '@components/buttons/contextual-button'
import EmptyStateButton from '@components/empty-state-button/empty-state-button'
import GlobalSearch from '@components/global-search/global-search'
import ImageEmptyState from '@components/image-empty-state/image-empty-state'
import Modal from '@components/modals/modal'
import { type CourseTab, type CourseTabStep } from '@interfaces/api/course/course'
import { type Experience } from '@interfaces/api/experience'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'

interface ExperienceCourseTabStepsProps {
  courseTab: CourseTab
}

const ExperienceCourseTabSteps = ({ courseTab }: ExperienceCourseTabStepsProps) => {
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })

  const [open, setOpen] = useState(false)
  const [childrens, setChildrens] = useState<CourseTabStep[]>([])
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const [courseTabStep, setCourseTabStep] = useState<CourseTabStep>()

  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()
  const { mutateAsync: deleteResourceEntry } = useDeleteEntrySimple()
  const { mutateAsync: createResourceEntry } = useCreateEntry({ path: 'course-tab-steps' })

  const {
    data: {
      data: courseSteps
    } = {},
    refetch
  } = useItemChildrenQuery<CourseTabStep>({
    itemId: courseTab.uid,
    path: 'course-tabs/{uid}/steps'
  })

  useEffect(() => {
    if (courseSteps) {
      setChildrens(courseSteps)
    }
  }, [courseSteps])

  const onAddExperienceClick = () => {
    setOpen(true)
  }

  const onDeleteClick = (courseTabStep: CourseTabStep) => {
    setOpenDeleteModal(true)
    setCourseTabStep(courseTabStep)
  }

  const onDeleteHandler = () => {
    if (courseTabStep) {
      deleteResourceEntry({ id: courseTabStep.uid, path: 'course-tab-steps/{uid}' }).then(() => {
        refetch().catch(captureException)
        setOpenDeleteModal(false)
      }).catch(captureException)
    }
  }

  const onCancelDeleteClick = () => {
    setOpenDeleteModal(false)
  }

  const onExperienceSelected = (experience: Experience) => {
    createResourceEntry({
      courseTab: courseTab['@id'],
      experience: `/api/experiences/${experience.uid}`
    }).then(() => {
      refetch().catch(captureException)
      setOpen(false)
    }).catch(captureException)
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    const sourceIndex = result.source.index
    const destinationIndex = result.destination.index

    if (sourceIndex === destinationIndex) {
      return
    }

    const newPriority = childrens[destinationIndex].priority
    const newExperienceChildren = Array.from(childrens)
    const [removed] = newExperienceChildren.splice(sourceIndex, 1)

    newExperienceChildren.splice(destinationIndex, 0, removed)
    setChildrens(newExperienceChildren)

    patchResourceEntry({
      data: {
        priority: newPriority
      },
      id: result.draggableId,
      path: 'course-tab-steps/{uid}'
    }).then(() => {
      refetch().catch(captureException)
    }).catch(captureException)
  }

  return (
    <>
      <div className='px-2 mb-2'>
        <ContextualButton onClick={onAddExperienceClick}>
          {translateActions('addStep')}
        </ContextualButton>
      </div>
      {childrens?.length > 0
        ? (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable direction='vertical' droppableId='droppable'>
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  className='flow-root'
                  ref={provided.innerRef}
                >
                  <div className='w-screen sm:w-auto'>
                    <div className={'min-w-min w-full table-wrp block'}>
                      <table className='w-full divide-y'>
                        <thead className='bg-gray-50 text-gray-900 sticky top-0'>
                          <tr>
                            <th className='p-4 text-left'>
                              <div className='flex items-center text-base font-medium'>
                                Nom
                              </div>
                            </th>

                            <th />
                          </tr>
                        </thead>

                        <tbody className='bg-white min-h-[400px] mb-20'>

                          <>
                            {childrens.map((courseTabStep, index) => (
                              <Draggable draggableId={courseTabStep.uid} index={index} key={courseTabStep.uid}>
                                {(provided, snapshot) => (
                                  <tr
                                    className='flex items-center bg-white hover:bg-gray-100 cursor-pointer'
                                    onClick={(e) => {
                                      e.stopPropagation()
                                    }}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={courseTabStep.uid}
                                  >
                                    <td className='p-4'>
                                      <span className='h-8 w-8 rounded-full flex items-center justify-center bg-primary text-white'>
                                        {index + 1}
                                      </span>
                                    </td>

                                    {courseTabStep.experience &&
                                      (
                                        <>
                                          <td className='p-4'>
                                            {courseTabStep.experience?.cover?.url
                                              ? (
                                                <img alt='Artwork cover' className='w-10 rounded-md'
                                                  src={courseTabStep.experience.cover.url}
                                                />
                                              )
                                              : <ImageEmptyState classNames='w-10 h-12 rounded-md overflow-hidden bg-slate-400 flex items-center justify-center' iconClassNames='h-4 w-4 text-white' />}
                                          </td>

                                          <td className='p-4 flex-grow'>
                                            {courseTabStep.experience.name}
                                          </td>
                                        </>
                                      )}

                                    <td className='p-4 flex space-x-3 ml-auto'>
                                      <button
                                        className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white'
                                        onClick={(e) => {
                                          e.stopPropagation()
                                          onDeleteClick(courseTabStep)
                                        }}
                                      >
                                        <TrashIcon className='w-5 h-5 mx-auto' />
                                      </button>
                                    </td>
                                  </tr>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              )}
            </Droppable>
          </DragDropContext>
        )
        : (
          <div className='py-4 pb-8 px-4 w-full'>
            <EmptyStateButton onClick={onAddExperienceClick} translateKey={'course-tab-steps'} />
          </div>
        )}

      <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

            <ContextualButton onClick={onCancelDeleteClick}>{translateActions('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>

      <GlobalSearch indexes={['artwork']} isOpened={open} onSelectCallback={onExperienceSelected} setOpened={setOpen} />
    </>
  )
}

export default ExperienceCourseTabSteps
