import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import ExperienceTableRow from '@components/experiences-table/experience-table-row/experience-table-row'
import ExperiencesTableTabs from '@components/experiences-table/experiences-table-tabs'
import { type ExperiencesTableProps as Props } from '@components/experiences-table/experiences-table.interfaces'
import { type ApiPlatformEntity } from '@interfaces/api'
import { type Experience, ExperienceType } from '@interfaces/api/experience'

const ExperiencesTable = ({ customParams, hasMargin = false, onClick, path, type, ...props }: Props) => {
  const navigate = useNavigate()
  const onEntryClick = (entry: ApiPlatformEntity) => {
    const experience = entry as Experience
    if (onClick) {
      onClick(entry, type)
    } else {
      navigate(`/experiences/${type}/${experience.uid}`)
    }
  }

  return (
    <div>
      <ExperiencesTableTabs currentTab={type} hasMargin={hasMargin} path={path} />

      {type === ExperienceType.activity && (
        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['name', 'mapHidden', 'createdAt']}
          customFilters={['city', 'category', 'name']}
          customParams={customParams}
          entryComponent={ExperienceTableRow}
          hasMargin={hasMargin}
          name={'activities'}
          onClick={onEntryClick}
          withDeleteOption
          withEditOption={onEntryClick}
          {...props}
        />
      )}

      {type === ExperienceType.attraction && (
        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['name', 'mapHidden', 'createdAt']}
          customFilters={['city', 'category', 'artist', 'displayWatch', 'name']}
          customParams={customParams}
          entryComponent={ExperienceTableRow}
          hasMargin={hasMargin}
          name={'attractions'}
          onClick={onEntryClick}
          withDeleteOption
          withEditOption={onEntryClick}
          {...props}
        />
      )}

      {type === ExperienceType.course && (
        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['name', 'mapHidden', 'createdAt']}
          customFilters={['city', 'category', 'name', 'courseType']}
          customParams={customParams}
          entryComponent={ExperienceTableRow}
          hasMargin={hasMargin}
          name={'courses'}
          onClick={onEntryClick}
          withDeleteOption
          withEditOption={onEntryClick}
          {...props}
        />
      )}

      {type === ExperienceType.artwork && (
        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['name', 'mapHidden', 'watchDisplayed', 'videoFr', 'videoEn', 'videoEs', 'activity', 'externalUrl', 'createdAt']}
          customFilters={['city', 'category', 'artist', 'watchDisplayed']}
          customParams={customParams}
          entryComponent={ExperienceTableRow}
          hasMargin={hasMargin}
          name={'artworks'}
          onClick={onEntryClick}
          withDeleteOption
          withEditOption={onEntryClick}
          {...props}
        />
      )}

    </div>
  )
}

export default ExperiencesTable
