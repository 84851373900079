import { PlusIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'

import ContextualButton from '@components/buttons/contextual-button'
import { type EmptyStateButtonProps } from '@components/empty-state-button/empty-state-button.interfaces'

const EmptyStateButton = ({ onClick, translateKey }: EmptyStateButtonProps) => {
  const { t: translate } = useTranslation('apiResources', { keyPrefix: `${translateKey}.emptyState` })

  return (
    <div className='flex flex-col justify-center items-center text-center'>
      <svg
        aria-hidden='true'
        className='mx-auto h-12 w-12 text-gray-400'
        fill='none'
        stroke='currentColor'
        viewBox='0 0 24 24'
      >
        <path
          d='M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z'
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth={2}
          vectorEffect='non-scaling-stroke'
        />
      </svg>

      <h3 className='mt-2 text-sm font-semibold text-gray-900'>{translate('title')}</h3>

      <p className='mt-1 text-sm text-gray-500'>{translate('description')}</p>

      <div className='mt-6'>
        <ContextualButton icon={PlusIcon} onClick={onClick}>
          {translate('addButton')}
        </ContextualButton>
      </div>
    </div>
  )
}

export default EmptyStateButton
