import { PlusCircleIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import { type ApiResourceListProps } from '@components/api-resource/api-resource-list/index'
import ApiResourceListEntry from '@components/api-resource/api-resource-list-entry'
import ApiResourceListFilters from '@components/api-resource/api-resource-list-filters'
import Table from '@components/api-resource/api-resource-table'
import ContextualButton from '@components/buttons/contextual-button'
import { itemsPerPageOptions } from '@components/pagination/pagination-items-per-page'
import { type Field } from '@interfaces/api/definition/field'
import useApiResourceListQuery from '@services/api/resources/list-query'
import { captureException } from '@services/exceptions/capture-exception'

const ApiResourceList = ({ createButton: CreateButton, createdAt, customFieldsToDisplay, customFilters = [], customParams, definition, entryComponent: EntryComponent, hasMargin = true, hideFilters = false, identifierAttribute = 'uid', onEntryClick, registerAt, uid, withCloneOption = false, withCopyOption, withCreateOption, withDeleteOption = false, withEditOption }: ApiResourceListProps) => {
  const { t: translateResource } = useTranslation('apiResources')

  // Filtering
  const hiddenFilters = ['page', 'prev_cursor', 'next_cursor']
  const filterDefinitions = definition.methods.list.definition.parameters.filter(param =>
    !hiddenFilters.includes(param.name) &&
    !(param.name.includes('order') && param.name !== 'orderNumber') &&
    !param.name.includes('pagination') &&
    !param.name.includes('itemsPerPage') &&
    !param.name.includes('tag') &&
    !param.name.includes('[]') &&
    (customFilters?.length === 0 || customFilters?.includes(param.name))
  )

  // Ordering
  const orderingDefinitions = definition.methods.list.definition.parameters.filter(param => param.name.includes('order['))
  const orders = orderingDefinitions.map(order => {
    if (createdAt && order.name === 'order[createdAt]') {
      return {
        [order.name]: createdAt
      }
    } else if (registerAt && order.name === 'order[registerAt]') {
      return {
        [order.name]: registerAt
      }
    } else {
      return {
        [order.name]: ''
      }
    }
  })

  const [searchParams, setSearchParams] = useSearchParams({
    ...Object.assign(
      {
        itemsPerPage: itemsPerPageOptions[0].toString()
      },
      ...orders,
      ...(customParams ?? [])
    )
  })
  const [itemsPerPage, setItemsPerPage] = useState(parseInt(searchParams.get('itemsPerPage') ?? itemsPerPageOptions[0].toString()) ?? itemsPerPageOptions[0])
  const prevItemsPerPage = useRef(parseInt(searchParams.get('itemsPerPage') ?? '100') ?? itemsPerPageOptions[0])

  useEffect(() => {
    if (prevItemsPerPage.current !== itemsPerPage) {
      setSearchParams({
        ...Object.fromEntries([...searchParams]),
        itemsPerPage: itemsPerPage.toString(),
        page: '1'
      })
    }
    prevItemsPerPage.current = itemsPerPage
  }, [itemsPerPage])

  // Pagination
  const setPageIndex = (index: number) => {
    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      page: `${index}`
    })
  }

  const {
    data: {
      'hydra:member': resourceEntries = [],
      'hydra:totalItems': totalItems = 0,
      'hydra:view': pagination = undefined
    } = {},
    isLoading,
    refetch
  } = useApiResourceListQuery({
    definition,
    parameters: searchParams,
    uid
  })

  useEffect(() => {
    refetch().catch(captureException)
  }, [searchParams])

  const reload = () => {
    refetch().catch(captureException)
  }

  const onReload = () => {
    reload()
  }

  const pageIndex = Number(searchParams.get('page') ?? '1')
  let fieldsToDisplay: Field[] = definition.methods.list.getFieldsToDisplay()

  if (customFieldsToDisplay?.length) {
    fieldsToDisplay = fieldsToDisplay.filter(item => customFieldsToDisplay.includes(item.name))

    customFieldsToDisplay.forEach(fieldName => {
      if (!fieldsToDisplay.some(field => field.name === fieldName)) {
        const fieldToPush: Field = { name: fieldName, properties: {} }
        fieldsToDisplay.push(fieldToPush)
      }
    })

    fieldsToDisplay.sort((a, b) => {
      const indexA = customFieldsToDisplay.indexOf(a.name)
      const indexB = customFieldsToDisplay.indexOf(b.name)

      return indexA - indexB
    })
  }

  // eslint-disable-next-line @typescript-eslint/prefer-nullish-coalescing
  if (withCloneOption || withDeleteOption || withEditOption || withCopyOption) {
    fieldsToDisplay.push({
      name: 'actions',
      properties: {
        type: 'string'
      }
    })
  }

  const headers = fieldsToDisplay.map(field => field.name)

  const onCreateClick = () => {
    withCreateOption?.()
  }

  return (
    <>
      <div className={classNames('flex items-center justify-between', { 'mb-4 mx-4 sm:mx-6 md:mx-8': hasMargin })}>
        {!hideFilters && (
          <div className={!hasMargin ? 'mx-2 sm:mx-4 md:mx-6' : ''}>
            <ApiResourceListFilters definitionName={definition.name} filters={filterDefinitions} searchParams={searchParams} setSearchParams={setSearchParams} />
          </div>
        )}

        {withCreateOption && !CreateButton && (
          <ContextualButton icon={PlusCircleIcon} onClick={onCreateClick}>
            {translateResource(`${definition.name}.create`)}
          </ContextualButton>
        )}

        {CreateButton && (
          <CreateButton />
        )}
      </div>
      <Table
        definitionName={definition.name}
        hasMargin={hasMargin}
        headers={headers}
        isLoading={isLoading}
        itemsPerPage={itemsPerPage}
        ordering={orderingDefinitions}
        pageIndex={pageIndex}
        pagination={pagination}
        searchParams={searchParams}
        setItemsPerPage={setItemsPerPage}
        setPageIndex={setPageIndex}
        setSearchParams={setSearchParams}
        totalItems={totalItems}
      >
        {
          resourceEntries.map((entry, index) => {
            if (EntryComponent) {
              return (
                <EntryComponent
                  definition={definition}
                  entry={entry}
                  fieldsToDisplay={fieldsToDisplay}
                  identifierAttribute={identifierAttribute}
                  key={index}
                  onClick={onEntryClick}
                  onReload={onReload}
                  withCloneOption={withCloneOption}
                  withCopyOption={withCopyOption}
                  withDeleteOption={withDeleteOption}
                  withEditOption={withEditOption}
                />
              )
            } else {
              return (
                <ApiResourceListEntry
                  definition={definition}
                  entry={entry}
                  fieldsToDisplay={fieldsToDisplay}
                  identifierAttribute={identifierAttribute}
                  key={index}
                  onClick={onEntryClick}
                  onReload={onReload}
                  withCloneOption={withCloneOption}
                  withCopyOption={withCopyOption}
                  withDeleteOption={withDeleteOption}
                  withEditOption={withEditOption}
                />
              )
            }
          })
        }

        {!resourceEntries.length && (
          <tr className='text-center bg-gray-50'>
            <td className='p-6' colSpan={headers.length}>
              <div className='text-gray-900 text-sm'>{translateResource('empty.title')}</div>

              <div className='text-gray-500 text-xs'>{translateResource('empty.description')}</div>
            </td>
          </tr>
        )}
      </Table>
    </>
  )
}

export default ApiResourceList
