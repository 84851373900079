import { ShoppingBagIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import BookingListEntry from '@components/bookings/booking-list-entry/booking-list-entry'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { useMe } from '@services/api/auth/use-me'

const BookingsList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'bookings' })
  const navigate = useNavigate()
  const breadcrumbs = [
    { href: '/bookings', name: translateResource('title') }
  ]

  const { data: user } = useMe()

  const isSuperAdmin = () => {
    if (user) {
      return user.roles.includes('ROLE_SUPER_ADMIN')
    }

    return false
  }

  const onEntryClick = (entry) => {
    navigate(`/bookings/${entry.uid}`, { state: { breadcrumbs } })
  }

  const onCreateClick = () => {
    navigate('/booking/create')
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={ShoppingBagIcon} title={translateResource('title')} />

        <ApiResource
          createdAt={'desc'}
          customFieldsToDisplay={['orderNumber', 'customerLastName', 'customerLastName', 'creationMethod', 'state', 'shippingState', 'totalPrice', 'startTimeLocal', 'createdAt']}
          customFilters={['orderNumber', 'state', 'shippingState', 'user']}
          customParams={[{ 'state[]': ['cancelled', 'new', 'fulfilled', 'standby'] }]}
          entryComponent={BookingListEntry}
          name={'bookings'}
          onClick={onEntryClick}
          withCreateOption={isSuperAdmin() ? onCreateClick : undefined}
        />
      </Guard>
    </Layout>
  )
}

export default BookingsList
