import { Combobox, Dialog, Transition } from '@headlessui/react'
import {
  ArrowPathIcon,
  BuildingLibraryIcon,
  ChevronRightIcon, DocumentIcon,
  FolderIcon,
  MagnifyingGlassIcon, MapIcon,
  PhotoIcon, ShoppingCartIcon, StarIcon, TrophyIcon, UserIcon, VideoCameraIcon
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ContextualButton from '@components/buttons/contextual-button'
import { type GlobalSearchProps } from '@components/global-search/global-search.interfaces'
import useMeiliSearchMultiQuery from '@services/api/resources/meili-multi-query'

const GlobalSearchIcon = {
  activity: TrophyIcon,
  artist: UserIcon,
  artwork: PhotoIcon,
  attraction: StarIcon,
  city: BuildingLibraryIcon,
  course: MapIcon,
  page: DocumentIcon,
  product: ShoppingCartIcon,
  video: VideoCameraIcon
}

const GlobalSearch = ({ emptyStateButtonLabel, emptyStateOnClick, indexes, isOpened, onSelectCallback, setOpened }: GlobalSearchProps) => {
  const [open, setOpen] = useState(false)
  const [query, setQuery] = useState('')
  const { t: translateResource } = useTranslation('general', { keyPrefix: 'search' })
  const navigate = useNavigate()

  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault()
        if (!setOpened) {
          setOpen(true)
        }
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  const closeModal = () => {
    setOpen(false)
    if (setOpened) {
      setOpened(false)
    }
  }

  const environmentPrefix = process.env.NODE_ENV === 'production' ? 'app_' : 'app_dev_'
  const adjustedIndexes = indexes.map(index => environmentPrefix + index)

  const { data = [] } = useMeiliSearchMultiQuery({
    enabled: query !== '',
    queries: adjustedIndexes.map(index => ({ indexUid: index, limit: 30, q: query, showRankingScore: true }))
  })

  const mergeHitsWithType = (data) => {
    const mergedData = data.flatMap(item =>
      item.hits.map(hit => {
        const name = hit.name || hit.fullName || hit.label || hit.title || ''

        return {
          ...hit,
          name,
          type: item.indexUid.replace(environmentPrefix, '')
        }
      })
    )
    mergedData.sort((a, b) => b._rankingScore - a._rankingScore)

    return mergedData
  }

  const mergedHits = mergeHitsWithType(data)

  const onSelect = (hit) => {
    if (onSelectCallback) {
      onSelectCallback(hit)
      setOpen(false)

      if (setOpened) {
        setOpened(false)
      }

      return
    }
    let path
    switch (hit.type) {
      case 'city':
        path = `/cities/${hit.uid}`
        break
      case 'artist':
        path = `/artists/${hit.uid}`
        break
      case 'attraction':
        path = `/experiences/attraction/${hit.uid}`
        break
      case 'activity':
        path = `/experiences/activity/${hit.uid}`
        break
      case 'artwork':
        path = `/experiences/artwork/${hit.uid}`
        break
      case 'course':
        path = `/experiences/course/${hit.uid}`
        break
      case 'video':
        path = `/videos/${hit.uid}`
        break
      default:
        path = '/'
    }
    setOpen(false)
    navigate(path)
  }

  const renderRow = (active, entry) => {
    const Icon = GlobalSearchIcon[entry.type]
    const city = entry.cities && entry.cities.length > 0 ? entry.cities[0] : entry.city

    let label = entry.name

    if (city) {
      label = `${city.name} - ${entry.name}`
    }

    return (
      <>
        <Icon
          aria-hidden='true'
          className={classNames('h-6 w-6 flex-none', active ? 'text-white' : 'text-gray-500')}
        />
        <span className='ml-3 flex-auto truncate'>{label}</span>
        {active && <ChevronRightIcon className='ml-3 flex-none text-gray-400 h-6 w-6' />}
      </>
    )
  }

  return (
    <Transition.Root afterLeave={() => {
      setQuery('')
    }} appear as={Fragment} show={(open || isOpened === true)}
    >
      <Dialog as='div' className='relative z-10' onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 w-screen overflow-y-auto p-4 sm:p-6 md:p-20'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <Dialog.Panel className='mx-auto max-w-4xl transform divide-y divide-gray-500 divide-opacity-20 overflow-hidden rounded-xl bg-gray-900 shadow-2xl transition-all'>
              <Combobox onChange={onSelect}>
                <div className='relative'>
                  <MagnifyingGlassIcon
                    aria-hidden='true'
                    className='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-500'
                  />

                  <Combobox.Input
                    className='h-12 w-full bg-transparent pl-11 pr-4 text-white sm:text-sm border-transparent outline-none'
                    onChange={(event) => {
                      setQuery(event.target.value)
                    }}
                    placeholder='Rechercher...'
                  />
                </div>

                {(query === '' || mergedHits.length > 0) && (
                  <Combobox.Options
                    className='max-h-[calc(100vh-5rem)] sm:max-h-[calc(100vh-6rem)] md:max-h-[calc(100vh-13rem)] scroll-py-2 divide-y divide-gray-500 divide-opacity-20 overflow-y-auto'
                    static
                  >
                    <li className='p-2'>
                      {query === '' && (
                        <h2 className='mb-2 mt-4 px-3 text-xs font-semibold text-gray-200'>{translateResource('title')}</h2>
                      )}

                      <ul className='text-sm text-gray-400'>
                        {mergedHits && mergedHits.length > 0 && mergedHits.map((entry) => {
                          return (
                            <Combobox.Option
                              className={({ active }) =>
                                classNames(
                                  'flex select-none items-center rounded-md px-3 py-2 cursor-pointer',
                                  active && 'bg-gray-800 text-white'
                                )
                              }
                              key={entry.uid}
                              value={entry}
                            >
                              {({ active }) => renderRow(active, entry)}
                            </Combobox.Option>
                          )
                        })}
                      </ul>
                    </li>
                  </Combobox.Options>
                )}

                {query !== '' && mergedHits.length === 0 && (
                  <div className='px-6 py-14 text-center sm:px-14 flex flex-col items-center justify-center space-y-4'>
                    <FolderIcon aria-hidden='true' className='mx-auto h-6 w-6 text-gray-500' />

                    <p className='mt-4 text-sm text-gray-200'>
                      {translateResource('empty')}
                    </p>

                    {emptyStateOnClick && emptyStateButtonLabel && (
                      <ContextualButton icon={ArrowPathIcon} onClick={() => {
                        emptyStateOnClick(query)
                        setOpen(false)
                      }}
                      >
                        {emptyStateButtonLabel}
                      </ContextualButton>
                    )}
                  </div>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default GlobalSearch
