import { PencilSquareIcon, TrashIcon } from '@heroicons/react/24/outline'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import ContextualButton from '@components/buttons/contextual-button'
import DropdownButton from '@components/buttons/dropdown-button/dropdown-button'
import { type ExperiencePagesProps } from '@components/experiences/experience-pages/experience-pages.interfaces'
import Modal from '@components/modals/modal'
import Table from '@components/table'
import TableEntry from '@components/table/table-entry/table-entry'
import { AttractionPageType, type ExperiencePage } from '@interfaces/api/experience/experience-page'
import useApiResource from '@services/api/definition'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import { captureException } from '@services/exceptions/capture-exception'

const ExperiencePages = ({ breadcrumbs, experience, id }: ExperiencePagesProps) => {
  const { t: translateResources } = useTranslation('apiResources', { keyPrefix: 'pages' })
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [page, setPage] = useState<ExperiencePage>()
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false)
  const collectionDefinition = useApiResource('pages')
  const { mutateAsync: createExperiencePage } = useCreateEntry({ headers: { 'Content-Type': 'application/json' }, path: 'experience-pages' })
  const definition = useApiResource('pages/{uid}')
  const { mutateAsync: deletePage } = useDeleteEntrySimple()

  const {
    data: {
      data: pages
    } = {},
    refetch
  } = useItemChildrenQuery<ExperiencePage>({
    itemId: id,
    path: 'experiences/{uid}/pages'
  })
  const onRowClick = (experiencePage) => {
    navigate(`/pages/${experiencePage.page.uid}/${id}/${experiencePage.type ?? AttractionPageType.DEFAULT}`, { state: { breadcrumbs } })
  }

  const onEditClick = (experiencePage) => {
    setPage(experiencePage)
    setOpenEdit(true)
  }

  const onEdit = async () => {
    await refetch()
    setOpenEdit(false)
  }

  const onDeleteClick = (experiencePage) => {
    setOpenDeleteModal(true)
    setPage(experiencePage)
  }

  const onDeleteHandler = () => {
    if (page) {
      deletePage({ id: page.uid, path: 'experience-pages/{uid}' }).then(async () => await refetch()).catch(captureException)
    }
    setOpenDeleteModal(false)
  }

  const handleCancelClick = () => {
    setOpenDeleteModal(false)
  }

  const onPageAddClick = async (type) => {
    if (type as AttractionPageType === AttractionPageType.DEFAULT) {
      setOpen(true)
    } else {
      const experiencePageData = {
        experience: experience['@id'],
        type
      }

      await createExperiencePage(experiencePageData)
      await refetch()
    }
  }

  const onCreate = async () => {
    await refetch()
    setOpen(false)
  }

  const afterSubmit = async (data) => {
    const experiencePageData = {
      experience: experience['@id'],
      page: data['@id'],
      type: null
    }

    await createExperiencePage(experiencePageData)
    await refetch()
  }

  const getDropdownOptions = () => {
    if (pages && pages.length > 0) {
      const typesInExperiencePages = pages.map(page => page.type)

      return Object.values(AttractionPageType).filter(type =>
        type === AttractionPageType.DEFAULT || !typesInExperiencePages.includes(type)
      )
    }

    return Object.values(AttractionPageType)
  }

  return (
    <>
      <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10'>
        <div className='px-4 py-4'>
          <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
            <div className='ml-4 mt-4'>
              <h3 className='text-xl font-semibold leading-6 text-slate-700'>Pages</h3>
            </div>

            <div className='ml-4 mt-4 flex-shrink-0'>
              <DropdownButton onCreateClick={onPageAddClick} options={getDropdownOptions()} ressource='pages' />
            </div>
          </div>
        </div>

        <Table
          headers={[]}
          isLoading={false}
          noScroll
          pageIndex={0}
          setPageIndex={() => {}}
          totalItems={3}
        >
          {
            pages?.map((experiencePage, index) => (
              <TableEntry key={index}
                onClick={() => {
                  onRowClick(experiencePage)
                }}
              >
                <td className='p-4 flex gap-3 items-center justify-between cursor-pointer'>
                  {experiencePage.type === null
                    ? (
                      <>
                        {`${translateResources(`types.${experiencePage.type ? experiencePage.type : 'default'}`)} - ${experiencePage.page.title}`}
                      </>
                    )
                    : <>{translateResources(`types.${experiencePage.type ? experiencePage.type : 'default'}`)}</>
                  }

                  {experiencePage.type === null && (

                    <div className='flex space-x-2'>
                      <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={(e) => {
                        e.stopPropagation()
                        onEditClick(experiencePage)
                      }}
                      >
                        <PencilSquareIcon className='w-5 h-5 mx-auto' />
                      </button>

                      <button className='flex items-center gap-2 text-gray-700 fill-gray-700 group-hover:hover:fill-white py-1 px-2 group-hover:hover:bg-gray-800 group-hover:hover:text-white text-sm rounded-md cursor-pointer bg-gray-100 hover:bg-white hover:border-gray-900 border border-gray-100 group-hover:border-gray-900 group-hover:bg-white' onClick={(e) => {
                        e.stopPropagation()
                        onDeleteClick(experiencePage)
                      }}
                      >
                        <TrashIcon className='w-5 h-5 mx-auto' />
                      </button>
                    </div>

                  )}
                </td>
              </TableEntry>
            ))
          }
        </Table>
      </div>

      <Modal center open={open} setOpen={setOpen} size='large' title={translateResources('create')}>
        {collectionDefinition && <ApiResourceCreate afterSubmit={afterSubmit} definition={collectionDefinition} onEdit={onCreate} />}
      </Modal>

      {page && (
        <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResources('edit')}>
          {definition && <ApiResourceEdit definition={definition} id={page.page.uid} onEdit={onEdit} />}
        </Modal>
      )}

      <Modal center open={openDeleteModal} setOpen={setOpenDeleteModal} title={translateActions('deleteResource')}>
        <div className='flex flex-col items-center p-8'>
          <div className='text-center text-xl'>{translateActions('deleteConfirmation')}</div>

          <div className='flex mt-6 gap-8'>
            <ContextualButton onClick={onDeleteHandler} style='warning'>{translateActions('delete')}</ContextualButton>

            <ContextualButton onClick={handleCancelClick}>{translateActions('cancel')}</ContextualButton>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default ExperiencePages
