import {
  ArrowDownOnSquareIcon,
  ArrowTopRightOnSquareIcon,
  CheckCircleIcon
} from '@heroicons/react/24/outline'
import { ErrorMessage, useField, useFormikContext } from 'formik'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type FormFileFieldProps } from '@components/form-fields/form-file-field'
import { captureException } from '@root/services/exceptions/capture-exception'

const FormFileField = ({
  accept = '.csv',
  isSuccess,
  label,
  multiple = false,
  name,
  required = false,
  translateKey = 'CSV'
}: FormFileFieldProps) => {
  const inputRef = useRef<HTMLInputElement>(null)
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const [selectedFileNames, setSelectedFileNames] = useState<string[]>([])

  const [input] = useField(name)
  const { value } = input
  const { setFieldValue } = useFormikContext()

  const handleChange = (event) => {
    const files: File[] = event.target.files ? Array.from(event.target.files) : []
    if (multiple) {
      setFieldValue(name, files).catch(captureException)
    } else {
      setFieldValue(name, files[0] || null).catch(captureException)
    }
    setSelectedFileNames(files.map(file => file.name))
  }

  useEffect(() => {
    if (isSuccess === false) {
      setSelectedFileNames([])
    }
  }, [isSuccess])

  // Determine translation keys based on 'multiple' prop
  const typeKey = multiple ? 'multiple' : 'single'
  const descriptionKey = `${translateKey}.${typeKey}.description`
  const titleKey = `${translateKey}.${typeKey}.title`

  // Function to render file link when a file is present
  const renderFileLink = () => (
    <div className='flex items-center justify-center mt-2 text-xs'>
      <a
        className='flex items-center'
        href={value.url}
        rel='noopener noreferrer'
        target='_blank'
      >
        <ArrowTopRightOnSquareIcon className='w-4 h-4 mr-1' />

        <span>{value.originalName}</span>
      </a>
    </div>
  )

  // Function to render upload success message
  const renderUploadSuccess = () => (
    <div className='flex items-center justify-center text-green-500'>
      <CheckCircleIcon className='w-6 h-6 mr-1' />

      <span>{translateResource('upload.success')}</span>
    </div>
  )

  // Function to render selected file names
  const renderSelectedFiles = () => (
    <div className='text-sm font-light'>
      {selectedFileNames.map((fileName, index) => (
        <div key={index}>{fileName}</div>
      ))}
    </div>
  )

  // Function to render file input prompt
  const renderFileInputPrompt = () => (
    <>
      <ArrowDownOnSquareIcon className='w-6 h-6 m-auto' />
      <span className='font-medium'>{translateResource(`upload.${titleKey}`)}</span>
      <span className='text-gray-500 text-xs'>{translateResource(`upload.${descriptionKey}`)}</span>
    </>
  )

  return (
    <div className='flex flex-col order-1'>
      {label && (
        <label className='block font-medium text-gray-700 text-sm mb-2' htmlFor={name}>{label}</label>
      )}

      <div className='relative border-2 border-gray-100 rounded-lg placeholder-gray-400 focus:outline-none flex justify-between px-3 py-6 h-32 group'>
        <input
          accept={accept}
          className='absolute opacity-0 w-full h-full top-0 left-0 cursor-pointer'
          multiple={multiple}
          name={name}
          onChange={handleChange}
          ref={inputRef}
          required={required && !value}
          type='file'
        />

        <div className='w-full flex flex-col gap-1 items-center justify-center text-center'>
          {isSuccess
            ? renderUploadSuccess()
            : selectedFileNames.length > 0
              ? renderSelectedFiles()
              : renderFileInputPrompt()}
        </div>
      </div>

      {/* Render the file link below the input field */}

      {value?.url && renderFileLink()}

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormFileField
