import { ListBulletIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { type ApiPlatformEntity } from '@interfaces/api'

const CitiesList = () => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'cities' })

  const breadcrumbs = [
    { href: '', name: translateResource('title') }
  ]

  const navigate = useNavigate()

  const onEntryClick = (entry: ApiPlatformEntity) => {
    navigate(`/cities/${entry.uid}`)
  }

  const onCreateClick = () => {
    navigate('/cities/create')
  }

  const onEditClick = (entry) => {
    navigate(`/cities/${entry.uid}/edit`)
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={ListBulletIcon} title={translateResource('title')} />

        <ApiResource customFieldsToDisplay={['name', 'country']} name={'cities'} onClick={onEntryClick} withCreateOption={onCreateClick} withDeleteOption withEditOption={onEditClick} />
      </Guard>
    </Layout>
  )
}

export default CitiesList
