import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'

import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import ContextualButton from '@components/buttons/contextual-button'
import FormPhoneField from '@components/form-fields/form-phone-field'
import FormSelectField from '@components/form-fields/form-select-field'
import FormTextField from '@components/form-fields/form-text-field'
import FormToggleField from '@components/form-fields/form-toggle-field'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { NotificationType } from '@components/notification/notification.interfaces'
import type { Booking } from '@interfaces/api/booking/booking'
import { Roles } from '@interfaces/api/roles'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import usePatchEntry from '@services/api/resources/patch-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useNotificationStore } from '@services/stores/notification/notification'
import countriesJson from '@services/translations/fr/countries.json'

const countryOptions = Object.entries(countriesJson).map(([key, name]) => ({
  key,
  name
}))

const BookingPageEdit = () => {
  const { id = '' } = useParams()

  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'bookings' })
  const { t: translateActions } = useTranslation('apiResources', { keyPrefix: 'actions' })
  const { t } = useTranslation('apiResources')
  const { mutateAsync: updateBooking } = usePatchEntry({ id, path: 'manual-bookings/{uid}' })
  const {
    data: booking
  } = useApiResourceItemQuery<Booking>({ id, path: 'bookings/{uid}', refetchOnMount: 'always' })
  const navigate = useNavigate()
  const { displayNotification } = useNotificationStore()

  const breadcrumbs = [
    { href: '/bookings', name: translateResource('title') },
    { href: `/bookings/${booking?.uid}`, name: `Booking ${id}` }
  ]

  const initialValues = {
    bookingFields: booking?.bookingFields
      ? Object.fromEntries(
        booking.bookingFields.map((field) => [field.name, field.value ?? ''])
      )
      : {},
    customerCountry: booking?.customerCountry ?? 'FR',
    customerEmail: booking?.customerEmail ?? '',
    customerFirstName: booking?.customerFirstName ?? '',
    customerLastName: booking?.customerLastName ?? '',
    customerPhone: booking?.customerPhone ?? '',
    items: [{
      participantsFields: booking?.items[0].participantsFields
        ? booking?.items[0].participantsFields.map(group => {
          return group.reduce((acc, field) => {
            acc[field.name] = ''

            return acc
          }, {})
        })
        : []
    }]
  }

  const renderField = (field, key, fieldName) => {
    const { fieldType, name, requiredPerBooking } = field

    switch (fieldType) {
      case 'String':
        return <FormTextField key={key} label={name} name={fieldName} required={requiredPerBooking} />
      case 'Phone':
        return <FormPhoneField key={key} label={name} name={fieldName} required={requiredPerBooking} />
      case 'List':
        return <FormSelectField key={key} label={name} name={fieldName} options={countryOptions} />
      case 'Date':
        return <FormTextField key={key} label={name} name={fieldName} required={requiredPerBooking} type='date' />
      case 'Boolean':
        return <FormToggleField key={key} label={name} name={fieldName} required={requiredPerBooking} />
      default:
        return null
    }
  }

  const onSubmit = async (values, { setSubmitting }) => {
    await updateBooking(values).catch(captureException)

    setSubmitting(false)

    displayNotification(t('bookings.message.update.success.title'), t('bookings.message.update.success.description'), NotificationType.success)
    navigate(`/bookings/${id}`)
  }

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard acceptedRoles={[Roles.ROLE_SUPER_ADMIN]}>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>

          <div className='border-b border-gray-200 bg-white rounded-lg shadow my-10'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
                <div className='ml-4 mt-4'>
                  <h3 className='text-xl font-semibold leading-6 text-slate-700'>Mise à jour d'un booking</h3>
                </div>
              </div>
            </div>

            {booking && (
              <Formik initialValues={initialValues} onSubmit={onSubmit}>
                {({ isSubmitting, setFieldValue }) => {
                  return (
                    <Form className='flex flex-col gap-4 py-3 px-4 w-full max-h-full'>

                      <div className='grid grid-cols-2 gap-10'>
                        <FormTextField label={t('labels.customerLastName')} name='customerLastName' required />

                        <FormTextField label={t('labels.customerFirstName')} name='customerFirstName' required />

                        <FormTextField label={t('labels.customerEmail')} name='customerEmail' required />

                        <FormPhoneField label={t('labels.customerPhone')} name='customerPhone' required />

                        <FormSelectField defaultValue={'FR'} key={'customerCountry'} label={t('labels.customerCountry')} name={'customerCountry'} options={countryOptions} />

                        {booking.bookingFields?.map((field, index) => renderField(field, field.name, `bookingFields.${field.name}`))}
                      </div>

                      {booking?.items[0].participantsFields?.map((group, groupIndex) => (
                        <div key={groupIndex}>
                          <h5 className='text-md font-semibold leading-6 text-slate-700 mb-4'>{`Participant ${groupIndex + 1} :`}</h5>

                          <div className='grid grid-cols-2 gap-10'>
                            {group.map((field, fieldIndex) => renderField(field, fieldIndex, `items[0].participantsFields[${groupIndex}][${field.name}]`))}
                          </div>
                        </div>
                      )
                      )}

                      <div className='flex justify-end'>
                        <ContextualButton
                          disabled={isSubmitting}
                          icon={PlusCircleIcon}
                          isLoading={isSubmitting}
                          type='submit'
                        >
                          {translateActions('edit')}
                        </ContextualButton>
                      </div>
                    </Form>
                  )
                }}
              </Formik>
            )}
          </div>
        </div>
      </Guard>
    </Layout>
  )
}

export default BookingPageEdit
