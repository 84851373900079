import { QrCodeIcon } from '@heroicons/react/24/outline'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'

const CodesList = () => {
  const { t: translateCodes } = useTranslation('apiResources', { keyPrefix: 'codes' })

  const breadcrumbs = [
    { href: '/codes', name: translateCodes('title') }
  ]

  const navigate = useNavigate()
  const onCreateClick = () => {
    navigate('/codes/create')
  }

  const onCopyClick = async (entry) => {
    await navigator.clipboard.writeText(entry.code)
  }

  return (
    <Layout description={translateCodes('description')} title={translateCodes('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={QrCodeIcon} title={translateCodes('title')} />

        <ApiResource createdAt={'desc'} identifierAttribute='code' name={'codes'} withCopyOption={onCopyClick} withCreateOption={onCreateClick} withDeleteOption />
      </Guard>
    </Layout>
  )
}

export default CodesList
