import {
  CheckCircleIcon, XCircleIcon
} from '@heroicons/react/24/outline'
import React from 'react'
import ReactCountryFlag from 'react-country-flag'
import { useTranslation } from 'react-i18next'

import { type CityHeadProps } from '@components/cities/city-head/city-head.interfaces'
import ImageEmptyState from '@components/image-empty-state/image-empty-state'

const CityHead = ({ city }: CityHeadProps) => {
  const { t } = useTranslation('apiResources')
  const { t: translateCountry } = useTranslation('countries')

  return (
    <div className='lg:flex lg:items-center lg:justify-between gap-6'>
      <div>
        {city.cover
          ? <img alt='' className='w-40 h-24 object-cover rounded-lg' src={city?.cover.url} />

          : <ImageEmptyState />
        }
      </div>

      <div className='min-w-0 flex-1'>
        <h2 className='text-2xl font-bold leading-7 text-slate-700 sm:truncate sm:text-3xl sm:tracking-tight'>
          {city.name}
        </h2>

        <div className='mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6'>
          <div className='mt-2 flex items-center text-sm text-gray-500'>

            <ReactCountryFlag className='mr-2'
              countryCode={city.country}
              style={{
                borderRadius: '4px',
                height: '15px',
                objectFit: 'cover',
                width: '20px'
              }} svg
            />

            {translateCountry(city.country)}
          </div>

          <div className='mt-2 flex items-center text-sm text-gray-500'>
            {city.disabled
              ? <XCircleIcon aria-hidden='true' className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' />
              : <CheckCircleIcon aria-hidden='true' className='mr-1.5 h-5 w-5 flex-shrink-0 text-green-400 fill-white' /> }

            {city.disabled ? t('labels.disabled') : t('labels.enabled')}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CityHead
