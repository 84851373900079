import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResourceCreate from '@components/api-resource/api-resource-create'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { type CityEntryInput } from '@interfaces/api/city/city-entry'
import useApiResource from '@services/api/definition'

const CityPageCreate = () => {
  const city: CityEntryInput = {
    '@id': '',
    '@type': '',
    mapZoomLevel: 12,
    priority: null,
    uid: ''
  }

  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'cities' })

  const collectionDefinition = useApiResource('cities')
  const navigate = useNavigate()

  const breadcrumbs = [
    { href: '/cities', name: translateResource('title') }
  ]

  const onEdit = (uid: string) => {
    navigate(`/cities/${uid}`)
  }

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} - Création`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>

          <div className='border-b border-gray-200 bg-white rounded-lg shadow my-10'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
                <div className='ml-4 mt-4'>
                  <h3 className='text-xl font-semibold leading-6 text-slate-700'>Création d'une ville</h3>
                </div>
              </div>
            </div>

            {collectionDefinition && (
              <ApiResourceCreate data={city} definition={collectionDefinition} onEdit={onEdit} />
            )}
          </div>
        </div>
      </Guard>
    </Layout>
  )
}

export default CityPageCreate
