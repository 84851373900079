import { ExclamationTriangleIcon, PlusCircleIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ButtonLoader from '@components/button-loader/button-loader'
import FormFileField from '@components/form-fields/form-file-field/form-file-field'
import { type ProductCodesUploadProps } from '@components/products/product-codes-upload/product-codes-upload.interfaces'
import { type Product } from '@interfaces/api/product/product'
import { usePostFileQuery } from '@services/api/resources/post-entry-file'
import { captureException } from '@services/exceptions/capture-exception'

const ProductCodesUpload = ({ product: productItem }: ProductCodesUploadProps) => {
  const { t } = useTranslation('apiResources')
  const { mutateAsync: postFile } = usePostFileQuery('products/{uid}/codes/batch-import', productItem.uid)
  const [product, setProduct] = useState<Product>(productItem)
  const [isUploading, setIsUploading] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    setProduct(productItem)
  }, [productItem])

  const onSubmit = (values, { resetForm, setSubmitting }) => {
    const formData = new FormData()
    formData.append('file', values.file)

    setIsUploading(true)
    postFile(formData).then(() => {
      setIsUploading(false)
      setSubmitting(false)
      setIsSuccess(true)

      setTimeout(() => {
        setIsSuccess(false)
        resetForm()
      }, 2000)
    }).catch(captureException)
  }

  return (
    <div className='border-b border-gray-200 bg-white rounded-lg shadow mt-10 mb-10'>
      <div className='px-4 py-4'>
        <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
          <div className='ml-4 mt-4'>
            <h3 className='text-xl font-semibold leading-6 text-slate-700'>Gestion des codes</h3>
          </div>
        </div>
      </div>

      <div className='py-3 px-4 w-full max-h-full'>
        <Formik
          enableReinitialize={true}
          initialValues={{
            file: '',
            provideCodes: product.provideCodes
          }}
          onSubmit={onSubmit}
        >
          {({ isSubmitting, values }) => {
            return (
              <Form className='space-y-6'>
                <div className='flex flex-col'>
                  <div className='flex gap-4 items-center'>
                    <span>Nombre total de codes :</span>

                    <span className='bg-gray-100 text-gray-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-gray-300'>
                      {product.totalCodeCount ?? 0}
                    </span>
                  </div>

                  <div className='flex gap-4 items-center'>
                    <span>Nombre de codes disponibles :</span>

                    <span className={`bg-${product.remainingCodeCount < 50 ? 'red' : (product.remainingCodeCount < 100 ? 'orange' : 'green')}-100 text-${product.remainingCodeCount < 50 ? 'red' : (product.remainingCodeCount < 100 ? 'orange' : 'green')}-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-${product.remainingCodeCount < 50 ? 'red' : (product.remainingCodeCount < 100 ? 'orange' : 'green')}-900 dark:text-${product.remainingCodeCount < 50 ? 'red' : (product.remainingCodeCount < 100 ? 'orange' : 'green')}-300`}>
                      {product.remainingCodeCount ?? 0}
                    </span>
                  </div>
                </div>

                <FormFileField isSuccess={isSuccess} key='file' label={t('labels.file')} name={'file'} required={true} />

                <div className='rounded-md bg-yellow-50 p-4 w-1/2'>
                  <div className='flex'>
                    <div className='flex-shrink-0'>
                      <ExclamationTriangleIcon aria-hidden='true' className='h-5 w-5 text-yellow-400' />
                    </div>

                    <div className='ml-3'>
                      <h3 className='text-sm font-medium text-yellow-800'>Attention, Si vous importez des codes déjà présent en base ils ne seront pas importés</h3>
                    </div>
                  </div>
                </div>

                <div className='flex justify-end'>
                  <button
                    className={`flex gap-2 items-center justify-center rounded-lg px-4 py-2 text-lg font-semibold tracking-wide bg-primary text-white fill-white hover:bg-gray-600 hover:text-white ${isSubmitting || values.file === '' ? 'cursor-not-allowed bg-slate-400' : 'bg-slate-800 hover:bg-slate-700'}`}
                    disabled={isSubmitting || isUploading || values.file === ''}
                    type='submit'
                  >
                    {t('buttons.upload')}

                    {isSubmitting || isUploading ? <ButtonLoader /> : <PlusCircleIcon className='w-5 h-5' />}
                  </button>
                </div>
              </Form>
            )
          }}
        </Formik>

      </div>
    </div>
  )
}

export default ProductCodesUpload
