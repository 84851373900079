import { useQuery, type UseQueryResult } from '@tanstack/react-query'
import { type AxiosResponse } from 'axios'

import { type ResourceItemParameters } from '@interfaces/api/definition/resource'
import useAxios from '@services/api/axios'
import { getApiResourceItemQueryUrl } from '@services/api/resources/item-query'

const useApiResourceItemQueryDownload = <T>({ id, path, refetchOnMount = true, ...props }: ResourceItemParameters): UseQueryResult<T> => {
  const { get } = useAxios()
  const url = getApiResourceItemQueryUrl(path, id)

  const queryKey = [url]
  const queryFn = async (): Promise<AxiosResponse<T>> => {
    return await get(url)
  }
  const staleTime = 5 * 60 * 1000

  // TODO: Talal will fix it later
  // @ts-expect-error types not
  return useQuery({
    enabled: id !== '',
    queryFn,
    queryKey,
    refetchOnMount,
    staleTime,
    ...props
  })
}

export default useApiResourceItemQueryDownload
