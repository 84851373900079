import { Switch } from '@headlessui/react'
import { ErrorMessage, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'

import {
  type FormArrayToggleFieldProps
} from '@components/form-fields/form-array-toggle-field/form-array-toggle-field.interfaces'
import { captureException } from '@services/exceptions/capture-exception'

const FormArrayToggleField = ({ label, name, options, value }: FormArrayToggleFieldProps) => {
  const { setFieldValue } = useFormikContext()

  const [items, setItems] = useState(options.map(option => ({
    checked: value?.includes(option) ?? false,
    option
  })))

  useEffect(() => {
    const selectedItems = items.filter(item => item.checked).map(item => item.option)
    setFieldValue(name, selectedItems).catch(captureException)
  }, [items, name, setFieldValue])

  const handleToggle = (index, checked) => {
    const newList = items.map((item, idx) => idx === index ? { ...item, checked } : item)
    setItems(newList)
  }

  return (
    <div className='flex flex-col w-full items-start'>
      <label className='block text-sm font-medium text-gray-700 mb-2'>{label}</label>

      {items.map((item, index) => (
        <div className='flex items-center mb-2' key={index}>
          <Switch
            checked={item.checked}
            className={`${
              item.checked ? 'bg-primary' : 'bg-gray-200'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
            onChange={(checked) => {
              handleToggle(index, checked)
            }}
          >
            <span
              className={`${
                item.checked ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>

          <span className='ml-2 text-sm font-medium text-gray-500 capitalize'>{item.option}</span>
        </div>
      ))}

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormArrayToggleField
