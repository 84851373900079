import { PlusCircleIcon } from '@heroicons/react/24/outline'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'

import ApiResourceFormObserver from '@components/api-resource/api-resource-form/api-resource-form-observer'
import BookingPriceOptionsFields from '@components/bookings/booking-price-options-fields/booking-price-options-fields'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import ContextualButton from '@components/buttons/contextual-button'
import FormSearchField from '@components/form-fields/form-search-field'
import FormSelectField from '@components/form-fields/form-select-field'
import FormTextField from '@components/form-fields/form-text-field'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { type Booking } from '@interfaces/api/booking/booking'
import { type Product } from '@interfaces/api/product/product'
import { Roles } from '@interfaces/api/roles'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import { captureException } from '@services/exceptions/capture-exception'
import { LanguageCodes } from '@services/languages'
import { useFormStore } from '@services/stores/forms/forms'

const BookingPageCreate = () => {
  const [productId, setProductId] = useState('')

  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'bookings' })
  const { t } = useTranslation('apiResources')
  const { data: product } = useApiResourceItemQuery<Product>({ id: productId, path: 'products/{uid}', refetchOnMount: 'always' })

  const navigate = useNavigate()
  const formValues = useFormStore((state) => state.forms.bookings)
  const { mutateAsync: createBooking } = useCreateEntry({ path: 'manual-bookings' })

  useEffect(() => {
    if (formValues?.product) {
      const productUrlParts = formValues.product.split('/')
      setProductId(productUrlParts[productUrlParts.length - 1])
    }
  }, [formValues])

  const breadcrumbs = [
    { href: '/bookings', name: translateResource('title') }
  ]

  const initialValues = {
    lang: 'fr',
    product: '',
    selectedPriceOptions: {},
    startDateLocal: '',
    startTimeLocal: ''
  }

  const onSubmit = async (values) => {
    const startTimeLocal = `${values.startDateLocal}T${values.startTimeLocal}`

    const booking = await createBooking({ ...values, startTimeLocal }).catch(captureException) as Booking

    if (booking.uid) {
      navigate(`/bookings/edit/${booking.uid}`)
    }
  }

  const langOptions = [
    ...Object.values(LanguageCodes).map(unit => ({
      key: unit,
      name: t(`lang.${unit}`)
    }))
  ]

  return (
    <Layout description={translateResource('description')} title={translateResource('title')}>
      <Guard acceptedRoles={[Roles.ROLE_SUPER_ADMIN]}>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>

          <div className='border-b border-gray-200 bg-white rounded-lg shadow my-10'>
            <div className='px-4 py-4'>
              <div className='-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap'>
                <div className='ml-4 mt-4'>
                  <h3 className='text-xl font-semibold leading-6 text-slate-700'>Création d'un booking</h3>
                </div>
              </div>
            </div>

            <Formik initialValues={initialValues} onSubmit={onSubmit}>
              {({ isSubmitting, setFieldValue }) => {
                return (
                  <Form className='flex flex-col gap-4 py-3 px-4 w-full max-h-full' noValidate>
                    <ApiResourceFormObserver definitionName={'bookings'} />

                    <div className='grid grid-cols-2 gap-10'>
                      <div className='flex flex-col space-y-4'>
                        <FormTextField label={t('labels.bookings.startDate')} name='startDateLocal' required type={'date'} />

                        <FormTextField label={t('labels.bookings.startTime')} name='startTimeLocal' required type={'time'} />

                        <FormSelectField defaultValue={'fr'} label={t('labels.lang')} name='lang' options={langOptions} required />

                        <FormTextField label={t('labels.number')} name='orderNumber' required uppercase />

                        <FormTextField label={t('labels.reseller')} name='reseller' required />

                        <FormSearchField
                          emptyStateLabel={t(['emptyState.product}', 'emptyState.default'])}
                          key={'product'}
                          label={t('labels.product')}
                          name={'product'}
                          required
                        />

                        {product && (
                          <BookingPriceOptionsFields product={product} setFieldValue={setFieldValue} />
                        )}
                      </div>
                    </div>

                    <div className='flex justify-end'>
                      <ContextualButton disabled={isSubmitting} icon={PlusCircleIcon} isLoading={isSubmitting} type='submit'>
                        {translateResource('create')}
                      </ContextualButton>
                    </div>
                  </Form>
                )
              }}
            </Formik>
          </div>
        </div>
      </Guard>
    </Layout>
  )
}

export default BookingPageCreate
