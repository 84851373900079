import { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import Date from '@components/api-resource/resource-entry-fields/date'
import { type BookingListEntryProps } from '@components/bookings/booking-list-entry/booking-list-entry.interfaces'
import BookingStatusBadge from '@components/bookings/booking-status-badge/booking-status-badge'
import { type ApiPlatformEntity } from '@interfaces/api'

const BookingListEntry = <T extends ApiPlatformEntity>({ entry, fieldsToDisplay, onClick }: BookingListEntryProps<T>) => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'bookings' })

  const fields: ReactElement[] = []
  const onClickHandler = () => {
    if (onClick) {
      onClick(entry)
    }
  }

  fieldsToDisplay.forEach((field, index) => {
    if (entry[field.name] !== undefined) {
      if ('type' in field.properties) {
        if (field.properties.type === 'string') {
          if (field.name.toLowerCase().includes('state')) {
            fields.push(
              <BookingStatusBadge icon={false} status={entry[field.name]} type={field.name} />
            )
          } else if (field.name === 'creationMethod') {
            fields.push(
              <span
                className='py-1 px-2 text-xs font-medium'
              >
                {translateResource(`${field.name}.${entry[field.name]}`)}

                {'reseller' in entry && entry.reseller && (
                  <>
                    {` - ${entry.reseller}`}
                  </>
                )}
              </span>
            )
          } else if (field.properties.format === 'date-time' && entry[field.name]) {
            fields.push(<Date datetimeEnabled value={entry[field.name]} />)
          } else {
            fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
          }
        } else if (field.properties.type === 'integer' || field.properties.type === 'number') {
          fields.push(<div key={index}>{(entry[field.name] / 100).toFixed(2)}</div>)
        } else {
          fields.push(<div key={index}>{entry[field.name]}</div>)
        }
      } else {
        if (field.name === 'user') {
          fields.push(<div key={index}>{entry[field.name] ? entry[field.name].email : ''}</div>)
        } else {
          fields.push(<div key={index}>{entry[field.name] ?? '-'}</div>)
        }
      }
    }
  })

  return (
    <tr
      className='bg-white hover:bg-gray-100 cursor-pointer group'
      onClick={onClickHandler}
    >
      {fields.map((field, index) => (
        <td
          className='px-3 py-1.5 text-sm'
          key={index}
        >
          {field}
        </td>
      ))}
    </tr>
  )
}

export default BookingListEntry
