import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import CityHead from '@components/cities/city-head/city-head'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import { type CityEntry } from '@interfaces/api/city/city-entry'
import useApiResource from '@services/api/definition'
import useApiResourceItemQuery from '@services/api/resources/item-query'

const CityPageEdit = () => {
  const { id = '' } = useParams()

  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'cities' })

  const { data } = useApiResourceItemQuery<CityEntry>({ id, path: 'cities/{uid}', refetchOnMount: 'always' })
  const definition = useApiResource('cities/{uid}')

  const breadcrumbs = [
    { href: '/cities', name: translateResource('title') },
    { href: '', name: data?.name }
  ]

  return (
    <Layout description={translateResource('description')} title={`${translateResource('title')} - ${data?.name}`}>
      <Guard>

        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <div className='mb-4 mx-auto px-4 sm:px-6 md:px-8 w-full'>

          {data && (
            <>
              <CityHead city={data} />

              <div className='border-b border-gray-200 bg-white rounded-lg shadow my-10'>

                {definition && <ApiResourceEdit definition={definition} fieldsToHide={['worldwide']} id={data.uid} />}
              </div>
            </>
          )}
        </div>
      </Guard>
    </Layout>
  )
}

export default CityPageEdit
