import { ArrowDownOnSquareIcon, CameraIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as tus from 'tus-js-client'

import { type VideoUploaderProps } from '@components/video/video-uploader/video-uploader.interfaces'
import { type Video } from '@interfaces/api/video'
import useCreateEntry from '@services/api/resources/create-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useAuthToken } from '@services/hooks/auth-token'
import formatVideoName from '@services/tools/format-video-name'

/*
Process :
  - Upload file to POST /api/file-upload/tus-io/
  - Register that file using the file URL on POST /api/videos/
  - Return the video @id
*/

const apiRoot = process.env.REACT_APP_API_URL ?? 'http://localhost:8080'
const endpoint = apiRoot + '/api/file-upload/tus-io'

const VideoUploader = ({ name, onUpload, setIsUploading }: VideoUploaderProps) => {
  const { value: authorizationBearer } = useAuthToken()
  const [files, setFiles] = useState<FileList>()
  const [progress, setProgress] = useState<number>(0)
  const { mutateAsync: createVideo } = useCreateEntry({ path: 'videos' })
  const { t } = useTranslation('apiResources')

  const onFileChange = (event) => {
    setFiles(event.target.files)
  }

  const onFileUpload = (fileName: string) => {
    createVideo({
      fileName
    }).then((result) => {
      const id = (result as Video)['@id']
      onUpload(id)
    }).catch(captureException).finally(() => {
      setIsUploading(false)
    })
  }

  const upload = (file: File, token: string) => {
    // create a new file with a different name with timestamp
    const newName = formatVideoName(file.name)
    const newFile = new File([file], newName)

    const upload = new tus.Upload(newFile, {
      // When using nginx proxy, maximum size of a PATCH request body in bytes
      chunkSize: 2 * 1024 * 1024,

      // Endpoint is the upload creation URL from your tus server
      endpoint,

      // Auth
      headers: {
        authorization: `Bearer ${token}`
      },

      // Attach additional meta data about the file for the server
      metadata: {
        filename: newName,
        filetype: file.type
      },

      // Callback for errors which cannot be fixed using retries
      onError: (error) => {
        console.error(error)
      },

      // Callback for reporting upload progress
      onProgress: (bytesUploaded, bytesTotal) => {
        const percentage = (bytesUploaded / bytesTotal)
        setProgress(percentage)
      },

      // Callback for once the upload is completed
      onSuccess: () => {
        if ('name' in upload.file) {
          onFileUpload(upload.file.name)
        }
      },

      // Retry delays will enable tus-js-client to automatically retry on errors
      retryDelays: [0, 3000, 5000, 10000, 20000]
    })

    upload.findPreviousUploads().then((previousUploads) => {
      // Found previous uploads so we select the first one.
      if (previousUploads.length) {
        upload.resumeFromPreviousUpload(previousUploads[0])
      }
    }).finally(() => {
      // Start the upload
      setProgress(0.01)
      upload.start()
    }).catch(captureException)
  }

  useEffect(() => {
    if (files && files.length > 0 && authorizationBearer) {
      const file = files[0]
      setIsUploading(true)
      upload(file, authorizationBearer)
    }
  }, [files])

  const progressStyles = (progress: number) => {
    let styles = 'rounded-full px-2 py-0.5 bg-gray-500 text-white font-bold absolute top-2 right-2'
    if (progress === 1) {
      styles = styles + ' bg-green-500'
    }

    return styles
  }

  return (
    <div className='relative w-44 h-full border-2 border-gray-100 rounded-lg placeholder-gray-400 focus:outline-none flex justify-between px-3 py-6 group'>
      <input
        className='opacity-0 absolute cursor-pointer left-0 top-0 w-full h-full'
        id={name}
        onChange={onFileChange}
        type='file'
      />

      {files?.length
        ? (
          <>
            <div className='flex flex-col gap-2 items-center text-center'>
              <CameraIcon className='w-5 h-5 text-gray-900' />

              <span className='text-gray-700 line-clamp-2'>{files[0].name}</span>

              {progress > 0 && (
                <div className={progressStyles(progress)}>
                  {`${(progress * 100).toFixed(0)}%`}
                </div>
              )}
            </div>
          </>
        )
        : (
          <div className='w-full flex flex-col items-center justify-center text-center'>
            <ArrowDownOnSquareIcon className='w-6 h-6 m-auto' />

            <span className='font-medium mt-2'>{t('actions.uploadVideo')}</span>

            <span className='text-gray-500 text-xs'>{t('actions.uploadVideoDescription')}</span>
          </div>
        )}
    </div>
  )
}

export default VideoUploader
