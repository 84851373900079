import { ErrorMessage, Field } from 'formik'

import { type FormPhoneFieldProps } from '@components/form-fields/form-phone-field/form-phone-field.interfaces'
import PhoneInput from '@components/form-fields/form-phone-field/phone-input/phone-input'

const FormPhoneField = ({ label, name, placeholder, required = false }: FormPhoneFieldProps) => {
  return (
    <div className='flex flex-col w-full'>
      <label className='block font-medium text-gray-900 text-sm' htmlFor={name}>
        {label}

        {required && <sup className='text-gray-500 font-normal'>*</sup>}
      </label>

      <Field
        className='appearance-none block w-full mt-2 px-3 py-2 border border-gray-300 bg-gray-50 rounded-lg placeholder-gray-500 focus:outline-none'
        component={PhoneInput}
        name={name}
        placeholder={placeholder}
        required={required}
      />

      <ErrorMessage className='mt-2 text-xs text-red-600 font-medium' component='div' name={name} />
    </div>
  )
}

export default FormPhoneField
