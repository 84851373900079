import { PaintBrushIcon, PencilSquareIcon } from '@heroicons/react/24/outline'

import DeleteButton from '@components/buttons/delete-button'
import { type ArtistProps } from '@components/pages/artist/artist.interfaces'
import { type Image } from '@interfaces/api/image'

const Artist = ({ artist, handleDeleteClick, handleEditImageClick, large = false, sectionRow }: ArtistProps) => {
  const { fullName: name } = artist
  let cover: Image | null = null
  if (sectionRow?.cover) {
    cover = sectionRow.cover
  } else if (artist.cover) {
    cover = artist.cover
  }

  return (
    <div className={`group rounded-lg px-2 py-2 bg-white relative flex flex-col gap-2 ${large ? 'w-[400px]' : 'w-[200px]'}`}>
      <div className='absolute top-2 right-2 hidden group-hover:block'>
        <DeleteButton classNames='border-red-500 !bg-red-500 text-white' onClick={handleDeleteClick} />
      </div>

      <div className='flex items-center gap-2'>
        <PaintBrushIcon className='rounded-full h-4 w-4' />

        <div>{name}</div>
      </div>

      <div>
        {cover && (
          <div className='flex relative group max-w-[100px]'>
            <img alt={name} className='rounded-lg' src={cover.url} />

            {handleEditImageClick && (
              <div className='absolute top-2 right-2 hidden group-hover:block'>
                <button className='flex items-center bg-white rounded-md border hover:fill-white hover:text-white p-1 border-gray-200 hover:border-gray-900 hover:bg-gray-800' onClick={handleEditImageClick}>
                  <PencilSquareIcon className='w-3.5 h-3.5' />
                </button>
              </div>
            )}
          </div>
        )}
      </div>

    </div>
  )
}

export default Artist
