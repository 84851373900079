import { type TFunction } from 'i18next'
import type { OpenAPIV3_1 } from 'openapi-types'

import { type ApiResourceFormPropsMethod } from '@components/api-resource/api-resource-form'
import type { Field } from '@interfaces/api/definition/field'
import type { Resource } from '@services/api/definition/resource'
import { LanguageCodes } from '@services/languages'

interface FieldDetails {
  fieldName: string
  format?: string
  label: string
  name: string
  type: string
}

export type TranslatedDataFieldAttribute = Record<LanguageCodes, FieldDetails>

export interface FlatTranslatedData {
  name: string
  type: string
}

export const getTranslatedDataFieldAttributes = (requestFields: Field[], definition: Resource, method: ApiResourceFormPropsMethod, t: TFunction, fieldsToHide?: string[]): TranslatedDataFieldAttribute[] => {
  const requestFieldsWithTranslatedData = requestFields
    .filter(field => field.name.toLowerCase().includes('translateddata'))
    .filter(field => !fieldsToHide?.includes(field.name))
    .map((field) => field.name)

  return requestFieldsWithTranslatedData.flatMap((fieldName) => getTranslatedDataFieldAttributesProperties(fieldName, requestFields, definition, method)
    .map((field) => {
      const languageObject = {}

      Object.values(LanguageCodes).forEach((language) => {
        languageObject[language] = {
          fieldName,
          format: field.format,
          label: t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`]),
          name: field.name,
          type: field.type
        }
      })

      return languageObject
    }))
}

export const getFlatTranslatedData = (requestFields: Field[], definition: Resource, method: ApiResourceFormPropsMethod): FlatTranslatedData[] => {
  const requestFieldsWithTranslatedData = requestFields
    .filter(field => field.name.toLowerCase().includes('translateddata')).map((field) => field.name)

  return requestFieldsWithTranslatedData.flatMap((fieldName) => getTranslatedDataFieldAttributesProperties(fieldName, requestFields, definition, method)
    .map((name) => {
      return name
    }))
}

export const getTranslatedDataFieldAttributesProperties = (fieldName: string, requestFields: Field[], definition: Resource, method: ApiResourceFormPropsMethod) => {
  const getTranslatedDataProperties = (properties: OpenAPIV3_1.SchemaObject | OpenAPIV3_1.ReferenceObject | undefined) => {
    if (!properties) {
      return []
    }

    if ('$ref' in properties) {
      return getTranslatedDataProperties(
        definition
          .methods[method]
          .getSchema(properties.$ref)
          ?.properties
      )
    }

    if (properties.anyOf) {
      const refInAnyOf = properties.anyOf.find(anyOf => '$ref' in anyOf)

      if (refInAnyOf && '$ref' in refInAnyOf) {
        return getTranslatedDataProperties(
          definition
            .methods[method]
            .getSchema(refInAnyOf.$ref)
            ?.properties
        )
      }
    }

    // Filter properties like @context in some case like City POST
    const propertyKeys = Object.keys(properties).filter(key => !key.startsWith('@'))

    if (
      typeof properties[propertyKeys[0]] === 'object' &&
      'anyOf' in properties[propertyKeys[0]] &&
      properties[propertyKeys[0]].anyOf?.[0] &&
      '$ref' in properties[propertyKeys[0]].anyOf[0]
    ) {
      return getTranslatedDataProperties(
        definition
          .methods[method]
          .getSchema(properties[propertyKeys[0]].anyOf[0].$ref)
          ?.properties
      )
    }

    // Filter properties like @context in some case like City POST
    return propertyKeys.map((key) => {
      const property = properties[key]
      const type = 'type' in property ? property.type : undefined
      const format = 'format' in property ? property.format : undefined

      return { format, name: key, type }
    })
  }

  return getTranslatedDataProperties(
    requestFields
      .find((field) => field.name === fieldName)
      ?.properties
  )
}
