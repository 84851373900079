import { ArrowDownTrayIcon, QrCodeIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import slugify from 'slugify'

import ApiResource from '@components/api-resource'
import Breadcrumbs from '@components/breadcrumbs/breadcrumbs'
import ContextualButton from '@components/buttons/contextual-button'
import Guard from '@components/guard/guard'
import Layout from '@components/layout/layout'
import SectionTitle from '@components/section-title/section-title'
import { type CodeBulk } from '@interfaces/api/code/code-bulk'
import useApiResourceItemQuery from '@services/api/resources/item-query'
import useApiResourceItemQueryDownload from '@services/api/resources/item-query-download'

const CodesList = () => {
  const { t: translateCodes } = useTranslation('apiResources', { keyPrefix: 'codes' })
  const { id = '' } = useParams()
  const [downloadId, setDownloadId] = useState('')

  const { data } = useApiResourceItemQuery<CodeBulk>({ id, path: 'code-bulks/{uid}', refetchOnMount: 'always' })
  const { data: csvData, isFetching, isSuccess: isCsvSuccess } = useApiResourceItemQueryDownload<Blob>({ id: downloadId, path: 'code-bulks/{uid}/download-csv' })

  const breadcrumbs = [
    { href: '/codes', name: translateCodes('title') }
  ]

  if (id !== '') {
    breadcrumbs.push({ href: `/codes/${id}`, name: data?.label })
  }

  const navigate = useNavigate()
  const onCreateClick = () => {
    navigate('/code-groups/create')
  }

  const onCopyClick = async (entry) => {
    await navigator.clipboard.writeText(entry.code)
  }

  const onDownloadClick = () => {
    setDownloadId(id)
  }

  useEffect(() => {
    if (isCsvSuccess && csvData && data) {
      const totalCount = data.totalCount
      const partnerSegment = data.partner ? `${slugify(data.partner.companyName, { lower: true })}_` : ''
      const fileName = `codes_${partnerSegment}${slugify(data.experience.name, { lower: true })}_${totalCount}.csv`

      const blob = new Blob([csvData], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = fileName
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      setDownloadId('') // reset downloadId to avoid re-triggering the download
    }
  }, [csvData, isCsvSuccess, id, data])

  return (
    <Layout description={id === '' ? translateCodes('description') : ''} title={translateCodes('title')}>
      <Guard>
        <Breadcrumbs breadcrumbs={breadcrumbs} />

        <SectionTitle icon={QrCodeIcon}
          title={id === '' ? translateCodes('title') : `${translateCodes('title')} - ${data?.label}`}
        />

        <div className='flex gap-4 px-4 sm:px-6 md:px-8 mb-6 justify-between'>

          <div>
            <h3 className='font-semibold'>Expérience débloquée :</h3>

            <div className='flex flex-col items-start'>
              <a className='flex items-center space-x-2' href={`/experiences/${data?.experience.type}/${data?.experience.uid}`}
                key={data?.experience.uid} target={'_blank'}
              >

                <span>{data?.experience.name}</span>
              </a>
            </div>
          </div>

          <div>
            <ContextualButton icon={ArrowDownTrayIcon} isLoading={isFetching} onClick={onDownloadClick}>
              Télécharger le csv
            </ContextualButton>
          </div>
        </div>

        {id !== ''
          ? (
            <ApiResource createdAt={'desc'} customFieldsToDisplay={['code', 'usedAt']} hideFilters
              identifierAttribute='code' name={'code-bulks/{codeBulkUid}/codes'} uid={id}
              withCopyOption={onCopyClick}
            />
          )
          : (
            <ApiResource createdAt={'desc'} identifierAttribute='code' name={'codes'} withCopyOption={onCopyClick}
              withCreateOption={onCreateClick} withDeleteOption
            />
          )}
      </Guard>
    </Layout>
  )
}

export default CodesList
