import type { FieldProps } from 'formik'
import { PhoneInput as ReactIntlPhone } from 'react-international-phone'
import 'react-international-phone/style.css'

import { captureException } from '@services/exceptions/capture-exception'

interface PhoneProps extends FieldProps {

}

const PhoneInput = ({ field, form }: PhoneProps) => {
  return (
    <ReactIntlPhone
      className='bg-gray-50 rounded-lg mt-2 border border-gray-300'
      countrySelectorStyleProps={{
        buttonClassName: '!h-full !border-none !bg-transparent !px-2',
        dropdownStyleProps: {
          className: 'flex w-full focus:outline-none'
        }
      }}
      defaultCountry='fr'
      inputProps={{
        className: 'w-full px-3 py-2 bg-gray-50 rounded-lg focus:outline-none'
      }}
      onChange={value => {
        if (!form.touched[field.name]) {
          form.setFieldTouched(field.name).catch(captureException)
        }
        form.setFieldValue(field.name, value).catch(captureException)
      }}
      value={field.value}
    />
  )
}

export default PhoneInput
