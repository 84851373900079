import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline'
import { PDFViewer } from '@react-pdf/renderer'
import React from 'react'
import { Document, pdfjs } from 'react-pdf'

import type { File } from '@interfaces/api/file'
import { type PlanFileInterfaces } from '@pages/plans/plan-file'
import useApiResourceItemQuery from '@services/api/resources/item-query'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url
).toString()

const PlanFile = ({ planFile }: PlanFileInterfaces) => {
  const { data: file } = useApiResourceItemQuery<File>({ id: planFile?.uid ?? '', path: 'files/{uid}' })

  if (!file) {
    return null
  }

  return (
    <div className='flex items-start flex-col'>
      <div className='flex flex-col mt-2 mb-6'>
        <div className='font-bold'>Fichier PDF :</div>

        <a
          className='flex items-center'
          href={file.url}
          rel='noopener noreferrer'
          target='_blank'
        >
          <ArrowTopRightOnSquareIcon className='w-4 h-4 mr-1' />

          <span>{file.originalName}</span>
        </a>
      </div>

      <PDFViewer>
        <Document file={file.url} />
      </PDFViewer>
    </div>
  )
}

export default PlanFile
