import { type ApiPlatformEntity } from '@interfaces/api'
import { type Product } from '@interfaces/api/product/product'
import { type ProductPriceOption } from '@interfaces/api/product/product-price-option'
import { type User } from '@interfaces/api/user'

export enum BookingCreationMethod {
  CUSTOMER_INITIATED = 'customer_initiated',
  IMPORTED = 'imported',
  MANUAL = 'manual'
}

export enum BookingState {
  cancelled = 'cancelled',
  cart = 'cart',
  fulfilled = 'fulfilled',
  new = 'new'
}

export enum BookingShippingState {
  awaiting_cancellation_deadline = 'awaiting_cancellation_deadline',
  awaiting_manual_shipment = 'awaiting_manual_shipment',
  cart = 'cart',
  shipped = 'shipped'
}

export enum BookingCheckoutState {
  booking_fields_incomplete = 'booking_fields_incomplete',
  cart = 'cart',
  completed = 'completed',
  customer_fields_incomplete = 'customer_fields_incomplete',
  participants_fields_incomplete = 'participants_fields_incomplete',
  ready_to_pay = 'ready_to_pay',
  unavailable = 'unavailable'
}

export enum BookingPaymentState {
  authorized = 'authorized',
  awaiting_payment = 'awaiting_payment',
  cancelled = 'cancelled',
  capture_failed = 'capture_failed',
  cart = 'cart',
  paid = 'paid',
  refunded = 'refunded',
  unavailable = 'unavailable'
}

export interface Booking extends ApiPlatformEntity {
  aggregatedData: {
    allDay?: boolean
  }
  bookingFields: BookingFields[]
  checkoutCompletedAt?: string
  checkoutState: BookingCheckoutState
  createdAt: string
  creationMethod: BookingCreationMethod
  currency: string
  customerCountry?: string
  customerEmail?: string
  customerFirstName?: string
  customerLastName?: string
  customerPhone?: string
  files?: BookingFile[]
  fulfilledAt?: string
  items: BookingItem[]
  lang: string
  orderNumber: string
  paidAt: Date
  paymentState: BookingPaymentState
  reseller?: string
  shippingState: BookingShippingState
  startTimeLocal: string
  state: BookingState
  totalPrice: number
  updatedAt: string
  user?: User
}

export interface BookingFile extends ApiPlatformEntity {
  mime: string
  originalName: string
  size: number
  uid: string
  url: string
}

interface BookingItem {
  allDay: boolean
  endTime: string
  endTimeLocal: string
  participantCount: number
  participantsFields: BookingFields[][]
  priceOptions: BookingPriceOption[]
  product: Product
  seats: number
  startTime: string
  startTimeLocal: string
  subTotal: number
}

interface BookingFields {
  fieldType: 'String' | 'List' | 'Phone' | 'Date' | 'Boolean'
  name: string
  required: boolean
  value: string
}

interface BookingPriceOption extends ProductPriceOption {
  quantity: number
}
