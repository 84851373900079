import classNames from 'classnames'
import { useTranslation } from 'react-i18next'

import { type CityPagesTabsProps } from '@components/cities/city-pages/city-pages-tabs.interfaces'
import { CityPageType } from '@interfaces/api/city/city-page'

const CityPagesTabs = ({ currentTab, setCurrentTab }: CityPagesTabsProps) => {
  const { t } = useTranslation('apiResources', { keyPrefix: 'pages' })

  const tabs = [
    CityPageType.TABS,
    'static',
    CityPageType.SECTIONS
  ]

  const onTabSelect = (tab) => {
    setCurrentTab(tab)
  }

  return (
    <nav aria-label='Tabs' className='flex space-x-4 mb-4 mx-2'>
      {tabs.map((tab) => (
        <button
          className={classNames(
            tab === currentTab ? 'bg-gray-900 text-white' : 'text-gray-500 hover:text-gray-700',
            'rounded-md px-3 py-2 text-sm font-medium'
          )}
          key={tab}
          onClick={() => {
            onTabSelect(tab)
          }}
          type={'button'}
        >
          {t(`tabs.${tab}`)}
        </button>
      ))}
    </nav>
  )
}

export default CityPagesTabs
