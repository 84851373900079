/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { type ReactElement } from 'react'
import { useTranslation } from 'react-i18next'

import {
  type SelectFieldsInterfaces
} from '@components/api-resource/api-resource-form/form-fields/select-fields/select-fields.interfaces'
import { fieldIsHidden } from '@components/api-resource/api-resource-form/form-utils/fields'
import FormSelectField from '@components/form-fields/form-select-field'
import type { ApiPlatformEntity } from '@interfaces/api'
import { CourseType, ExperienceChildType } from '@interfaces/api/course/course'
import { CourseStepType, NavigationStepDirections } from '@interfaces/api/course/course-step'
import { AttractionType } from '@interfaces/api/experience'
import { AttractionPageType } from '@interfaces/api/experience/experience-page'
import { Lang } from '@interfaces/api/lang'
import { UserDisabledReasons } from '@interfaces/api/user/disabled-reasons'
import countriesJson from '@services/translations/fr/countries.json'

const SelectFields = <T extends ApiPlatformEntity>({
  data,
  definition,
  fieldsToDisplay,
  fieldsToHide,
  formValues,
  requestFields
}: SelectFieldsInterfaces<T>) => {
  const { t } = useTranslation('apiResources')

  const fields: ReactElement[] = []

  // Créez un objet pour associer les noms de champs aux composants correspondants
  const fieldComponents = {}

  requestFields.forEach((field, index) => {
    if ('type' in field.properties) {
      if (
        fieldIsHidden(field, fieldsToDisplay, fieldsToHide) ||
        field.name === 'durationUnit' ||
        field.name === 'distanceUnit' ||
        (field.name === 'icon' && (formValues.type === CourseStepType.ARTWORK || formValues.type === CourseStepType.IMAGE))
      ) {
        return
      }

      if (field.name === 'type' && definition.name === 'navigation-steps') {
        const options = [
          ...Object.values(CourseStepType).map(unit => ({
            key: unit,
            name: t(`navigation-steps.types.${unit}`)
          }))
        ]

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || CourseStepType.ICON} key={field.name} label={t('labels.navigationStepsType')} name={field.name} options={options} />
      } else if (field.name === 'type' && definition.name === 'experience-children') {
        const options = [
          ...Object.values(ExperienceChildType).map(unit => ({
            key: unit,
            name: t(`experience-children.types.${unit}`)
          }))
        ]

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || ExperienceChildType.experience} key={field.name} label={t('labels.type')} name={field.name} options={options} />
      } else if (field.name === 'lang' && definition.name === 'users') {
        const options = [
          ...Object.values(Lang).map(unit => ({
            key: unit,
            name: t(`lang.${unit}`)
          }))
        ]

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || Lang.en} key={field.name} label={t('labels.lang')} name={field.name} options={options} />
      } else if (field.name === 'disabledReason' && definition.name === 'users') {
        const options = [
          { key: '', name: t('users.disabled-reasons.default') },
          ...Object.values(UserDisabledReasons).map(unit => ({
            key: unit,
            name: t(`users.disabled-reasons.${unit}`)
          }))
        ]

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || ''} key={field.name} label={t('labels.disabledReason')} name={field.name} options={options} />
      } else if (field.name === 'icon' && definition.name === 'navigation-steps') {
        const options = Object.values(NavigationStepDirections).map(stepType => ({
          image: `/assets/navigation-steps/${NavigationStepDirections[stepType]}.png`,
          key: stepType,
          name: t(`navigation-steps.icons.${stepType}`)
        }))
        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || NavigationStepDirections.startingPoint} key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} options={options} />
      } else if (field.name === 'country' || field.name === 'nationality') {
        const sectionTypeOptions = Object.entries(countriesJson).map(([key, name]) => ({
          key,
          name
        }))
        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name]} key={field.name} label={t([`labels.${definition.name}.${field.name}`, `labels.${field.name}`])} name={field.name} options={sectionTypeOptions} />
      } else if (field.name === 'type' && definition.name === 'experience-pages') {
        const attractionPageTypeOptions = [
          { key: '', name: t('pages.types.default') },
          ...Object.values(AttractionPageType).map(unit => ({
            key: unit,
            name: t(`pages.types.${unit}`)
          }))
        ]

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || null} key={field.name} label={t('labels.pageType')} name={field.name} options={attractionPageTypeOptions} />
      } else if (field.name === 'attractionType') {
        const attractionTypeOptions = [
          { key: '', name: t('labels.attractionType.values.attraction') },
          ...Object.values(AttractionType).map(unit => ({
            key: unit,
            name: t(`labels.attractionType.values.${unit}`)
          }))
        ]

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || null} key={field.name} label={t(`labels.${field.name}.label`)} name={field.name} options={attractionTypeOptions} />
      } else if (field.name === 'courseType') {
        const courseTypeOptions = Object.values(CourseType).map(unit => ({
          key: unit,
          name: t(`labels.courseType.values.${unit}`)
        }))

        fieldComponents[field.name] = <FormSelectField defaultValue={data?.[field.name] || CourseType.map} key={field.name} label={t(`labels.${field.name}.label`)} name={field.name} options={courseTypeOptions} />
      }
    }
  })

  // Parcourez fieldsToDisplay si défini, sinon ajoutez les champs dans l'ordre d'origine
  if (fieldsToDisplay) {
    fieldsToDisplay.forEach((fieldName) => {
      if (fieldComponents[fieldName]) {
        fields.push(fieldComponents[fieldName])
      }
    })
  } else {
    Object.keys(fieldComponents).forEach((fieldName) => {
      fields.push(fieldComponents[fieldName])
    })
  }

  return (
    <>
      {fields}
    </>
  )
}

export default SelectFields
