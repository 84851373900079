import { ArrowDownOnSquareIcon, CheckCircleIcon, VideoCameraIcon } from '@heroicons/react/24/outline'
import { useQueryClient } from '@tanstack/react-query'
import Uppy from '@uppy/core'
import Tus from '@uppy/tus'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { type ApiReponseError } from '@interfaces/api/api'
import { type FileUpload } from '@interfaces/api/video'
import { type FileEntry } from '@interfaces/api/video/file-entry'
import useCreateEntry from '@services/api/resources/create-entry-query'
import { captureException } from '@services/exceptions/capture-exception'
import { useAuthToken } from '@services/hooks/auth-token'
import getEndpointURL from '@services/tools/get-endpoint'

const endpoint = getEndpointURL('file-upload/tus')

const VideoUploader = () => {
  const { value: authorizationBearer } = useAuthToken()
  const [files, setFiles] = useState<FileEntry[]>()
  const [areAllFilesRegistered, setAreAllFilesRegistered] = useState<boolean>(false)
  const { mutateAsync: createVideo } = useCreateEntry({ path: 'videos' })
  const { mutateAsync: createFileUpload } = useCreateEntry({ path: 'file-uploads' })
  const { t: translateResource } = useTranslation('apiResources')
  const queryClient = useQueryClient()

  // Uppy Event handlers
  const uppy = new Uppy().use(Tus, {
    chunkSize: 10 * 1024 * 1024, // When using nginx proxy, maximum size of a PATCH request body in bytes
    endpoint,
    headers: {
      authorization: `Bearer ${authorizationBearer ?? ''}`
    },
    limit: 1,
    retryDelays: [0, 3000, 5000, 10000, 20000]
  })

  uppy.on('upload-progress', (file, progress) => {
    if (file) {
      setFiles(currentState => {
        const newState = currentState?.map(fileEntry => {
          if (fileEntry.id === file.id) {
            return { ...fileEntry, progress: progress.bytesUploaded / progress.bytesTotal }
          } else {
            return { ...fileEntry }
          }
        })

        return newState
      })
    }
  })

  uppy.on('upload-success', (file) => {
    if (file?.name) {
      const fileNameSplitted = file.name.split('.')
      createVideo({
        fileUpload: `/api/file-uploads/${fileNameSplitted.slice(0, -1).join('.')}`
      }).then(() => {
        setFiles(currentState => {
          const newState = currentState?.map(fileEntry => {
            if (fileEntry.id === file.id) {
              return { ...fileEntry, isRegistered: true }
            } else {
              return { ...fileEntry }
            }
          })

          return newState
        })
        queryClient.refetchQueries({ queryKey: ['videos'] }).catch(captureException)
      }).catch((error: ApiReponseError) => {
        captureException({
          message: error['hydra:description'],
          name: error['hydra:title']
        })
        setFiles(currentState => {
          const newState = currentState?.map(fileEntry => {
            if (fileEntry.id === file.id) {
              return { ...fileEntry, hasError: error['hydra:description'] }
            } else {
              return { ...fileEntry }
            }
          })

          return newState
        })
      })
    }
  })

  // Input file handlers
  const setFileList = (fileList: FileUpload[]) => {
    const files: FileEntry[] = []
    for (const file of fileList) {
      if (file.data) {
        const fileId = uppy.addFile({
          data: file.data,
          name: file.name,
          type: file.mime
        })
        files.push({
          id: fileId,
          isRegistered: false,
          name: file.name,
          progress: 0
        })
      }
    }
    setFiles(files)
  }

  const onFileChange = async (event) => {
    const files = Array.from(event.target.files)

    const uploadPromises: Array<Promise<FileUpload>> = files.map(async (originalFile) => {
      const file = originalFile as File

      try {
        const result = await createFileUpload({
          mime: file.type,
          originalName: file.name
        })

        const { '@id': id, completed, expired, internalId, mime, name, originalName, size, uid, uploadOffset } = result as FileUpload

        return {
          '@id': id,
          completed,
          data: file,
          expired,
          internalId,
          mime,
          name,
          originalName,
          size,
          uid,
          uploadOffset
        } satisfies FileUpload
      } catch (error) {
        captureException({
          message: (error as ApiReponseError)['hydra:description'],
          name: (error as ApiReponseError)['hydra:title']
        })

        throw error
      }
    })

    try {
      const res: FileUpload[] = await Promise.all(uploadPromises)
      setFileList(res)
      await uppy.upload()
    } catch (error) {
      console.error(error)
    }
  }

  // Styles
  const completedStyles = (completed: boolean) => {
    const common = 'absolute -top-[4.5rem] right-0 h-12 flex gap-2 items-center justify-center bg-green-500 border border-green-700 rounded-lg shadow text-white text-sm font-medium px-4 transition'

    return `${common} ${completed ? 'opacity-100 scale-100' : 'opacity-0 scale-0'}`
  }

  const progressStyles = (progress: number) => {
    let styles = 'rounded-full px-2 py-0.5 bg-gray-500 text-white text-sm font-medium'
    if (progress === 1) {
      styles = styles + ' bg-green-500'
    }

    return styles
  }

  // Reset style after 5sec
  useEffect(() => {
    if (files) {
      setAreAllFilesRegistered(!files.find(file => !file.isRegistered))
      if (areAllFilesRegistered) {
        setTimeout(() => {
          setFiles(undefined)
          setAreAllFilesRegistered(false)
        }, 5000)
      }
    }
  }, [files, areAllFilesRegistered])

  return (
    <div className='relative m-8'>
      <div className={completedStyles(areAllFilesRegistered)}>
        <CheckCircleIcon className='w-5 h-5' />

        <span className='text'>{translateResource('notifications.uploadComplete')}</span>
      </div>

      {files && (
        <div>
          <div className='flex flex-col bg-white border-2 border-gray-100 rounded-lg'>
            {files?.map(file => (
              <div className='flex items-center px-3 py-2 border-t border-gray-300 first:border-0' key={file.id}>
                <VideoCameraIcon className='w-4 h-4 mr-2 text-gray-900' />

                <div className='text-gray-700 text-sm'>
                  {file.name}
                </div>

                <div className='ml-auto'>
                  {file.hasError && (
                    <div className='ml-auto px-2 py-0.5 text-red-900 text-sm'>
                      {file.name}
                    </div>
                  )}

                  <div className={progressStyles(file.progress)}>
                    {file.progress < 1 ? `${Math.floor(file.progress * 100)} %` : translateResource('treatment')}
                  </div>
                </div>
              </div>
            ))}
          </div>

          {!areAllFilesRegistered && (
            <div className='mt-4 flex flex-col items-center gap-1 text-gray-500 text-center'>
              <div className='relative'>
                <ArrowDownOnSquareIcon className='relative z-10 w-6 h-6 fill-gray-900 m-auto group-hover:text-gray-700' />

                <div className='absolute inset-0 w-full h-full bg-gray-300 rounded-full animate-ping' />
              </div>

              <span className='text-sm'>{translateResource('loading')}</span>
            </div>
          )}
        </div>
      )}

      {!files && (
        <div className='relative w-full flex flex-col gap-1 items-center justify-center bg-white border-1 border-gray-50 shadow shadow-gray-200 rounded-lg placeholder-gray-300 focus:outline-none sm:text-sm px-3 py-6 group'>
          <input
            className='opacity-0 absolute inset-0 cursor-pointer'
            id={'inputid'}
            multiple
            onChange={onFileChange}
            type='file'
          />

          <ArrowDownOnSquareIcon className='w-6 h-6 m-auto group-hover:text-gray-700' />

          <span className='leading-6 font-medium text-base'>{translateResource('actions.uploadVideo')}</span>

          <span className='text-gray-500 text-xs'>{translateResource('actions.uploadDescription')}</span>
        </div>
      )}
    </div>
  )
}

export default VideoUploader
